import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import UserInfo from "./components/UserInfo";
import AreaCode from "./components/Address";
import Socials from "../../../layouts/pages/users/new-user/components/Socials";
import Profile from "../../../layouts/pages/users/new-user/components/Profile";
import validations from "../../../layouts/pages/users/new-user/schemas/validations";
import form from "../../../layouts/pages/users/new-user/schemas/form";
import initialValues from "../../../layouts/pages/users/new-user/schemas/initialValues";
import axios from "axios";
import BookingCard from "../../../examples/Cards/BookingCard";
import thumbnail from "../../../assets/images/thumbnail.jpg";

function getSteps() {
  return ["To Compose", "Filters", "TAGs(Categories)", "Review"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} />;
    case 1:
      return <AreaCode formData={formData} />;
    case 2:
      return <Socials formData={formData} />;
    case 3:
      return <Profile formData={formData} />;
    default:
      return null;
  }
}

function NewUser1() {
  const [activeStep, setActiveStep] = useState(1);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    await sleep(1000);

    // alert(JSON.stringify(values, null, 2));

    // actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      // actions.setSubmitting(false);
    }
  };

  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://crmstation.net/laravel/public/api/api_get_videos?page=create_new_campaigns"
      )
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
    }
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <MDBox mt={0}>
          <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description} // Assuming the description is part of the API response
                      price={video.price} // Assuming the price is part of the API response
                      location={video.location} // Assuming the location is part of the API response
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {/* <MDBox textAlign="center" mt={3}>
                  <h3>Video not found</h3>
                </MDBox> */}
              </Grid>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox py={3} mb={20}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              // onSubmit={handleSubmit}
              validateOnChange={false} // Added this line
              validateOnBlur={false} // Added this line
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        {/* <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="black1"
                              onClick={handleBack}
                              style={{ color: "black" }}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            // disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                            onClick={!isLastStep ? handleNext : handleSubmit}
                          >
                            {isLastStep ? "send" : "next"}
                          </MDButton>
                        </MDBox> */}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser1;
