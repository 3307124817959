import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import { Card, Grid, Button } from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import axios from "axios";
import { toast } from "react-toastify";
import API_ROUTES from "../../../config/apiConfig";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";
import BookingCard from "../../../examples/Cards/BookingCard";

// Images
import thumbnail from "../../../assets/images/thumbnail.jpg";

const OurApis = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [buttonText, setButtonText] = useState(""); 

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
      setButtonText(loadedTranslations.our_api?.get_token || "GERAR");
    };

    fetchTranslations();
  }, [language]);

  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchInitialToken = async () => {
      try {
        const groupId = localStorage.getItem("group_id");
        const response = await axios.post(API_ROUTES.OUR_API_GENERIC, {group_id: groupId});   
        const tokenFromAPI = response.data.groupData.token;
        setToken(tokenFromAPI);
        if (tokenFromAPI) {
          setButtonText("Copy Token");
        }
      } catch (error) {
        console.error("Error fetching initial token:", error);
      }
    };

    fetchInitialToken();
  }, []);

  const handleGetToken = async () => {
    setButtonText("Generating...");
    const groupId = localStorage.getItem("group_id");
    try {
      const response = await axios.post(API_ROUTES.OUR_API_GENERATE_TOKEN, {
        group_id: groupId,
      });
      const generatedToken = response.data.token;
      setToken(generatedToken);
      setButtonText("Copy Token");
    } catch (error) {
      console.error("Error generating token:", error);
      setButtonText(translations.our_api?.get_token || "Get Token");
    }
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(token);
    toast.success("Token copied to clipboard");
  };

  const handleCopyURL = () => {
    navigator.clipboard.writeText(
      "https://321dbase.com/api/integration.php"
    );
    toast.success("URL copied to clipboard");
  };

  const handleCopyURL1 = () => {
    navigator.clipboard.writeText("https://321dbase.com/api/automatize.php");
    toast.success("URL copied to clipboard");
  };

  
 const [videoData, setVideoData] = useState([]);

 useEffect(() => {
   axios
     .get(
       "https://crmstation.net/laravel/public/api/api_get_videos?page=our_apis"
     )
     .then((response) => {
       setVideoData(response.data);
     })
     .catch((error) => {
       console.error("There was an error fetching the video data!", error);
     });
 }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
        <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description} // Assuming the description is part of the API response
                      price={video.price} // Assuming the price is part of the API response
                      location={video.location} // Assuming the location is part of the API response
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {/* <MDBox textAlign="center" mt={3}>
                  <h3>Video not found</h3>
                </MDBox> */}
              </Grid>
            )}
          </Grid>
        </MDBox>


        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{ width: "100%", cursor: "pointer" }}>
                <MDBox p={2}>
                  <MDTypography variant="h6" mb={2}>
                    {translations.our_api?.generate_token}
                  </MDTypography>
                  <MDBox mb={3}>
                    <MDTypography variant="caption">Token</MDTypography>
                    <MDInput
                      variant="outlined"
                      value={token}
                      readOnly
                      fullWidth
                    />
                    {token ? (
                      <MDButton
                        variant="gradient"
                        color="black1"
                        style={{ color: "black" }}
                        onClick={handleCopyToken}
                        sx={{ marginTop: "1rem" }}
                      >
                       {translations.our_api?.copy_token} 
                      </MDButton>
                    ) : (
                      <MDButton
                        variant="gradient"
                        color="black1"
                        onClick={handleGetToken}
                        sx={{ marginTop: "1rem" }}
                        style={{ color: "black" }}
                      >
                        {buttonText}
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </MDBox>

        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox mt={3}>
                <Card sx={{ width: "100%", cursor: "pointer" }}>
                  <MDBox p={2}>
                    <MDTypography variant="h6" mb={2}>
                      {translations.our_api?.save_profile_api}
                    </MDTypography>
                    <MDBox mb={3}>
                      <MDTypography variant="caption">
                        {translations.our_api?.api_url}
                      </MDTypography>
                      <MDInput
                        variant="outlined"
                        value="https://321dbase.com/api/integrations.php"
                        readOnly
                        fullWidth
                      />
                      <MDButton
                        variant="gradient"
                        color="black1"
                        style={{ color: "black" }}
                        onClick={handleCopyURL}
                        sx={{ marginTop: "1rem" }}
                      >
                        {translations.our_api?.copy_url}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox
                mt={3}
                sx={{
                  overflowX: "auto",
                  backgroundColor: "#fff",
                  padding: "1rem",
                  borderRadius: "5px",
                  maxHeight: "17rem",
                }}
              >
                <pre style={{ color: "#000", fontSize: "11px" }}>
                  {`{
  "fname": "campo_do_nome",
  "lname": "campo_do_sobrenome",
  "dob_day": "dd",
  "dob_month": "mm",
  "dob_year": "aaaa",
  "groupid": "o_id_do_seu_grupo_no_notifire",
  "email": "campo do email",
  "mobile": "campo_do_celular_com_13_digitos",
  "message": "campo_da_mensagem_a_ser_enviada_via_Whatsapp",
  "image_link": "campo_com_a_url_completa_da_imagem_a_anexar",
  "extra_type": "nome_do_campo_extra",
  "extra": "campo_do_conteúdo_do_campo_extra",
  "token": "cole_aqui_o_seu_token"
}`}
                </pre>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox mt={3}>
                <Card sx={{ width: "100%", cursor: "pointer" }}>
                  <MDBox p={2}>
                    <MDTypography variant="h6" mb={2}>
                      {translations.our_api?.actions_api}
                    </MDTypography>
                    <MDBox mb={3}>
                      <MDTypography variant="caption">
                        {translations.our_api?.api_url}
                      </MDTypography>
                      <MDInput
                        variant="outlined"
                        value="https://321dbase.com/api/automatize.php"
                        readOnly
                        fullWidth
                      />
                      <MDButton
                        variant="gradient"
                        color="black1"
                        style={{ color: "black" }}
                        onClick={handleCopyURL1}
                        sx={{ marginTop: "1rem" }}
                      >
                        {translations.our_api?.copy_url}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox
                mt={3}
                sx={{
                  overflowX: "auto",
                  backgroundColor: "#fff",
                  padding: "1rem",
                  borderRadius: "5px",
                  maxHeight: "15rem",
                }}
              >
                <pre style={{ color: "#000", fontSize: "11px" }}>
                  {`{
"api_key": "your_api_key_here",
"instance": "36_cdsol",
"number": "5521992983857@c.us",
"keyword": "teste",
"name": "MFFB"
}`}
                </pre>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default OurApis;
