import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import UserInfo from "./components/UserInfo";
import AreaCode from "./components/Address";
import Socials from "./components/Socials";
import Profile from "./components/Profile";
import axios from "axios";
import BookingCard from "../../../examples/Cards/BookingCard";
import thumbnail from "../../../assets/images/thumbnail.jpg";
import { useNavigate } from "react-router-dom";
import { useGlobalData } from "../../../context/GlobalDataContext"; // Adjust the import path as needed
import { toast } from "react-toastify"; // Import the toast library
import API_ROUTES from "../../../config/apiConfig";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress for loading spinner

function getSteps() {
  return ["To Compose", "Filters", "TAGs(Categories)", "Review"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 2:
      return <Socials />;
    case 3:
      return <Profile />;
    default:
      return null;
  }
}

function NewUser2() {
  const [activeStep, setActiveStep] = useState(2);
  const [loading, setLoading] = useState(false); // Add loading state
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const navigate = useNavigate();
  const { state } = useGlobalData(); // Use context to get the data

  const handleBack = () => {
    if (activeStep === 2) {
      navigate("/filters");
    } else {
      setActiveStep(activeStep - 1);
    }
  };
  const group_id = localStorage.getItem("group_id");

  const handleSubmit = () => {
    setLoading(true); // Set loading to true when submission starts

    const dataToSend = {
      step_one_data: state.step_one_data,
      step_two_data: state.step_two_data,
      total_tags: state.total_tags,
      group_id: group_id,
    };

    axios
      .post(API_ROUTES.DATA_SEND_CREATE_ALL_STEP, dataToSend)
      .then((response) => {
        toast.success("Data submitted successfully!");
        navigate("/campaigns_reports")
      })
      .catch((error) => {
        toast.error("Failed to submit data!");
        console.error("There was an error submitting the data!", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after submission is complete
      });
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "http://crmstation.net/laravel/public/api/api_get_videos?page=create_new_campaigns"
      )
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <MDBox mt={0}>
          <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description} // Assuming the description is part of the API response
                      price={video.price} // Assuming the price is part of the API response
                      location={video.location} // Assuming the location is part of the API response
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <MDBox textAlign="center" mt={3}>
                  <h3>Video not found</h3>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox py={3} mb={20}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Card sx={{ height: "100%" }}>
              <MDBox mx={2} mt={-3}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={3}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="end"
                  >
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant="gradient"
                        color="black1"
                        onClick={handleBack}
                        style={{ color: "black" }}
                      >
                        back
                      </MDButton>
                    )}
                    
                      {loading ? (
                        <CircularProgress size={24} color="inherit" /> // Show spinner when loading
                      ) : isLastStep ? (
                        <MDButton
                      type="button"
                      variant="gradient"
                      color="info" // Change color to "info"
                      onClick={isLastStep ? handleSubmit : handleNext}
                      disabled={loading} // Disable button when loading
                      style={{ marginLeft: "8px" }}
                    >
                        send
                        </MDButton>
                      ) : (
                        <MDButton
                      type="button"
                      variant="gradient"
                      color="dark" // Change color to "info"
                      onClick={isLastStep ? handleSubmit : handleNext}
                      disabled={loading} // Disable button when loading
                      style={{ marginLeft: "8px" }}
                    >
                        next
                        </MDButton>
                      )}
                   
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser2;
