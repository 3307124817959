import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import axios from "axios";
import API_ROUTES from "../../../../config/apiConfig";
import { useLanguageState } from "../../../../context/LanguageContext";
import loadTranslations from "../../../../utils/loadTranslations";

const SecondCard = () => {
  const [queue, setQueue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const group_id = localStorage.getItem("group_id");

      try {
        const response = await axios.post(
          API_ROUTES.BOTS_REPORTS_GET_DATA,
          { group_id }
        );

        setQueue(response.data.sent_count.queue || "0");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Set limit to an error message or default value
        setQueue("Error");
      }
    };

    fetchData();
  }, []);


  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  
  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);


  
  return (
    <Card sx={{ width: "100%", cursor: "pointer" }}>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12} md={7} lg={12}>
            <MDTypography variant="h2" sx={{ mt: 2, mb: 1, ml: 2 }}>
              {queue}
            </MDTypography>
          </Grid>
          <MDTypography variant="h6" sx={{ mb: 1, ml: 2 }}>
          {translations.bots_reports?.in_queue}

          </MDTypography>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default SecondCard;
