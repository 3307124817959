import React, { createContext, useReducer, useContext } from "react";

const initialState = {
  step_one_data: {},
  step_two_data: [], // Initialize as an array
  total_tags: {},
};

const GlobalDataContext = createContext();

const globalDataReducer = (state, action) => {
  switch (action.type) {
    case "SET_STEP_ONE_DATA":
      return { ...state, step_one_data: action.payload };
    case "SET_STEP_TWO_DATA":
      return { ...state, step_two_data: action.payload };
    case "SET_TOTAL_TAGS":
      return { ...state, total_tags: action.payload };
    case "DELETE_USER":
      return {
        ...state,
        step_two_data: state.step_two_data.filter(
          (user) => user.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const GlobalDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalDataReducer, initialState);

  return (
    <GlobalDataContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = () => useContext(GlobalDataContext);
