import React, { useState } from "react";
import Papa from "papaparse"; // You can use a CSV parsing library like 'papaparse'
import DashboardLayout from "../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";
import MDBox from "../components/MDBox";
import { Card, Grid } from "@mui/material";


const CsvUploader = () => {
  const [events, setEvents] = useState([]);
  const [progress, setProgress] = useState(0);

  const parseCsvRow = (csvRow) => {
    // Assuming a structure similar to what you were using before
    return {
      index: events.length,
      name: csvRow[0],
      mobile: csvRow[1],
      event_date: csvRow[2],
      event_hour: csvRow[3],
    };
  };

  const parseCsvFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      // Using PapaParse to convert CSV to array
      Papa.parse(data, {
        complete: (result) => {
          const csvRows = result.data;
          if (csvRows.length > 1) {
            const newEvents = [];
            for (let i = 1; i < csvRows.length; i++) {
              const row = parseCsvRow(csvRows[i]);
              newEvents.push(row);
            }
            setEvents((prevEvents) => [...prevEvents, ...newEvents]);
            setProgress(100);
          }
        },
      });
    };
    reader.onerror = (ex) => {
      console.log("parseCsvFile error", ex);
    };
    reader.readAsText(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProgress(0);
      parseCsvFile(file);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container>
            <Card sx={{ width: "100%" }}>
              <h3>Upload CSV File</h3>
              <input type="file" onChange={handleFileChange} accept=".csv" />
              <div>
                <table id="tbl-event-list" border="1">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Date</th>
                      <th>Hour</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((event, index) => (
                      <tr
                        key={index}
                        className="tr-event-row"
                        data-id={event.index}
                      >
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{event.name}</td>
                        <td style={{ textAlign: "center" }}>{event.mobile}</td>
                        <td style={{ textAlign: "center" }}>
                          {event.event_date}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {event.event_hour}
                        </td>
                        <td>
                          <a className="td-event-delete">
                            <img src="/images/Close.png" alt="Delete" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </Card>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </div>
  );
};

export default CsvUploader;
