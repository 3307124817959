const portuguese = {
  main_menu: {
    home: "Iniciar",
    subscribers: "Seu Público",
    leads: "Leads e Vips",
    automatize: "Automatizar",
    flowbuilder: "Construtor",
    your_data: "Seus Dados",
    ai_assistant: "Assistente IA",
    settings: "Configurações",
    upload_image: "Enviar Imagem",
    messages_bots: "Bots de Mensagens",
    bot_reports: "Relatórios de Bots",
    bot_birthday: "Bot de Aniversário",
    bot_welcome: "Bot de Boas-Vindas",
    bot_appointment: "Bot de Agendamento",
    bot_firstpurchase: "Bot de Primeira Compra",
    bot_expiring: "Bot de Vencimento",
    campaigns: "Campanhas",
    campaigns_reports: "Relatórios de Campanhas",
    create_new: "Criar Novo",
    integrations: "Integrações",
    our_api: "Nossa API",
    hotmart: "Hotmart",
    google_sheets: "Google Sheets",
    woo_commerce: "Woo Commerce",
    instagram_bot: "Bot do Instagram",
    cupons: "Cupons",
    new_promotion: "Nova Promoção",
    cupons_list: "Lista de Cupons",
    alert_messages: "Mensagens de Alerta",
    whatsapp_cloudapi: "API Cloud do WhatsApp",
    metrics: "Métricas",
    others: "Outros",
    staff: "Equipe",
    lottery: "Sorteio",
  },
  buttons_and_common_words: {
    save: "Salvar",
    delete: "Apagar",
    edit: "Editar",
    upload: "Upload",
    upload_images: "Selecionar Imagens",
    download: "Baixar",
    select: "Selecionar",
    select_file: "Selecionar Arquivo",
    logout: "Sair",
    login: "Entrar",
    signin: "Registrar",
    view: "Visualizar",
    details: "Detalhes",
    export: "Exportar",
    import: "Importar",
    settings: "Configurações",
    send: "Enviar",
    message: "Mensagem",
    image: "Imagem",
    tag: "Tag",
    column: "Column",
    tags: "Criar Etiquetas",
    actions: "Ações",
    back: "Voltar",
    enteries_per_page: "linhas por página",
    showing: "Mostrando",
    to: "a",
    entries: "linhas",
    saving: "Salvando...",
    are_you_sure: "Tem certeza?",
    ok: "OK",
    cancel: "Cancelar",
    export: "Exportar",
    import: "Importar",
    upload_file: "Selecionar Arquivo",
    delete_file: "Apagar Todos Pré-Importados",
    import_now: "Importar Agora",
    delete_file_message: "em certeza que quer apagar este arquivo?",
  },
  page_home: {
    total_users: "Total de cadastros",
    than_lastweek: "que semana passada",
    birthday_thismonth: "Aniversários este mês",
    than_lastmonth: "que mês passado",
    than_yesterday: "que ontem",
    automation_is: "Automação está",
    aiassistant_is: "Assistente IA está",
    plans_and_invoices: "Planos e Boletos",
    connect_to_whatsapp: "Conectar ao WhatsApp",
    connected_to_whatsapp: "Conectado",
    update_connection_status: "Atualizar Conexão",
    logoff_true: "Desconectar / Limpar",
    send_test_message: "Enviar Mensagem Teste",
    send_test_message_reply: "Isto é um teste",
    new_qrcode: "Gerar Novo QRCode",
    automation_restart_timer: "Tempo de reinício da automação",
    timer: "Intervalo (h)",
    your_logo: "Sua Logo e Imagens",
    google_pixel: "Google Pixel",
    meta_pixel: "Meta Pixel",
    terms_of_use: "Termos de Uso",
    upload_logo_images: "Logos e Banners",
    birthday_today: "Aniversarios hoje",
    read_qr: "Ler QRCode",
    whatsapp_connection: "Conectar Whatsapp a Plataforma",
    automation_status: "Status da Automação",
    automation_multichanel_details: "Detalhes da Automação e Multiatendimetno",
    multichanel_server: "Servidor",
    multichanel_token: "Token de Multiatendimento",
  },
  page_subscribers: {
    subscribers_table: "Tabela de assinantes",
    your_public: "Seu Público",
    name: "Nome",
    mobile: "Celular",
    mobile_format: "Celular (formato 5521992983857)",
    dob: "Aniversário",
    company: "Empresa",
    lead: "Lead",
    created_at: "Criado",
    updated_at: "Atualizado",
    actions: "Ações",
    click_up_image: "Selecionar imagem",
    search_bytag: "Selecionar por Etiqueta",
    select_tag: "Selecionar Etiqueta",
    filter_bybirthday: "Filtrar por Aniversário",
    meta_custom_public: "Meta - Público Customizado",
    add_new_subscriber: "Adicionar Assinante",
  },
  edit_subscribers: {
    fullname: "Nome Completo",
    firstname: "Primeiro Nome",
    email: "Email",
    address: "Endereço",
    address_number: "Número",
    address_complement: "Complemento",
    district: "Bairro",
    city: "Cidade",
    state: "Estado",
    country: "País",
    zip: "CEP",
    tags: "Etiqueta",
    column: "Column",
    add_tag: "Adicionar Etiqueta",
    responsable: "Responsável",
    product: "Produto",
    value: "Valor",
    total_visits: "Número de Visitas",
    extra1: "Extra1",
    extra2: "Extra2",
    last_purchase_date: "Data Última Compra",
    expiring_date: "Vencimento",
    legal_id: "CPF",
    gender: "Gender",
  },
  additional_information_about_subscribers: {
    responsable: "Responsável",
    product: "Produto",
    value: "Valor",
    total_visits: "Total de Visitas",
    extra1: "Extra1",
    extra2: "Extra2",
    last_purchase_date: "Data Última Compra",
    expiring_date: "Data de Vencimento",
  },
  page_leads_and_vips: {
    latest_subscribers: "Últimos Assinantes",
  },
  your_data: {
    question: "Question",
    content: "Reply",
  },
  flowbuilder: {
    open_flowbuilder: "Abrir Construtor de Fluxo (Flowbuilder)",
  },
  tags: {
    select: "Selecionar Etiqueta",
    move_users: "Mover Assinantes",
  },
  bots_reports: {
    generate_message_date: "GERAR MANUALMENTE",
    ready_tosend: "Gerar Mais Mensagens",
    in_queue: "Na fila para os próximos dias",
    date: "Data",
    name: "Nome",
    message: "Mensagem",
    image: "Imagem",
    tags: "Tags",
    bot_reports: "Relatórios de Bots",
    birthday: "Aniversário",
    type: "Tipo",
    welcome: "Boas-Vindas",
    appointment: "Agendamento",
    event_date: "Data do evento",
    imported_event: "Ver Eventos Importados",
    import_sample_file: "Baixar Arquivo Modelo  ",
    import_excel_file: "Importar Arquivo Excel",
    import_now: "Importar Agora",
    file_imported_success: "Arquivo Importado Com Sucesso",
    delete_imported_events: "Apagar Eventos Importados",
    table_date: "Data da Importação",
    table_name: "Nome",
    table_mobile: "Celular",
    table_event: "Data Evento",
    event_hour: "Hora Evento",
  },
  settings: {
    set_instructions: "Configurar Nome e Instruções da Assistente IA",
    assistant_name: "Nome da Assistente",
    assistant_instructions: "Instruções (Personalidade e Comportamento)",
    copy_default_prompt: "Copiar modelo de Prompt",
    send_training_file: "Enviar Arquivo de Treinamento",
    number_uploaded_files: "Número de Arquivos Enviados",
    click_to_upload_txt_file: "Clique para Enviar Arquivo TXT",
    list_delete_files: "Listar e Apagar Arquivos TXT",
    test_ai_using_file: "Testar a Assistente Usando Arquivo TXT",
    greetings_message: "Mensagem de Saudação",
  },
  birthday_bot: {
    generate_message: "Mensagens geradas com sucesso!",
    data_save: "Dados atualizados com sucesso!",
    birthday_bot: "Bot de Aniversário",
    antecipation_in_days: "Quantos dias ANTES quer enviar a mensagem",
    type_message_choose_image: "Digite uma mensagem e anexe uma imagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
    success_msg: "Data saved successfully",
    activated_success:"Bot ativado com sucesso!",
    upload_img:"Clique para selecionar imagem"
  },
  welcome_bot: {
    welcome_bot: "Bot de Boas-Vindas (novo cliente)",
    delay_in_days: "Quantos dias DEPOIS quer enviar a mensagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  firstpurchase_bot: {
    firstpurchase_bot: "Bot de Primeira Compra",
    delay_in_days: "Quantos dias DEPOIS quer enviar a mensagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  expiring_bot: {
    expiring_bot: "Bot de Vencimento",
    antecipation_in_days: "Quantos dias ANTES quer enviar a mensagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  appointment_bot: {
    appointment_bot: "Bot de Confirmação de Agendamento",
    antecipation_in_days: "Quantos dias ANTES quer enviar a mensagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  our_api: {
    generate_token: "Gerar Token",
    get_token: "Gerar",
    save_profile_api: "API de Salvar Perfil",
    api_url: "Url Da Api",
    copy_url: "Copiar URL",
    actions_api: "API de Ações (Automação)",
    copy_token: "Copiar",
  },
  campaign_reports: {
    ready_tosend: "Prontas para Enviar",
    in_queue: "Na Fila para Próximos Dias",
    daily_limit: "Limite Diário",
    web_delivery: "Entrega pela Web",
    web_integrator_message:
      "Ative para enviar as campanhas pela automação WEB. Para mais de 100 envios por dia, use nosso programa disparador para Windows.",
    enter_limit: "Digite o Limite Diário",
    campaign_report_detail: "Detalhes da Campanha",
    loading: "Carregando...",
    mesages30: "+ 30 mensagens",
    ready: "Pronta",
    sent: "Enviada",
    in_queue: "Na fila",
  },
  bots_report: {
    ready_tosend: "Prontas para enviar",
    in_queue: "Na fila para próximos dias",
    date: "Data",
    name: "Nome",
    message: "Mensagem",
    imagem: "Imagem",
    tags: "Tags",
  },
  create_tag: {
    create_tag: " Criar Etiqueta",
    tag_name: "Nome da Etiqueta",
  },
  create_column: {
    create_column: "Nova Etapa",
    tag_name: "Nome da Column",
    stage: "Etapa",
  },
  your_public_import: {
    name: "nome",
    mobile: "celular",
    email: "email",
    dob: "aniversario",
    tagid: "tagid",
    company: "empresa",
    address: "endereco",
    number: "numero",
    complement: "complemento",
    district: "bairro",
    city: "cidade",
    state: "estado",
    cep: "cep",
    cpf: "taxid",
    datepurchase: "datacompra",
    dateexpiring: "datavencimento",
    responsable: "responsavel",
    product: "produto",
    value: "valor",
    extra1: "extra1",
    extra2: "extra2",
    subscriber_success_msg: "Dados adicionados com sucesso!",
    data_saved: "Dados editados com sucesso!",
  },
  compose_message: {
    compose_message: "Compor Mensagem",
    name_campaign: "Nome da Mensagem",
    enter_text: "Digite um texto",
    message_saved: "Nova mensagem para Campanha salva com sucesso",
    message_name: "Nome da Mensagem",
    edit_message: "Editar Mensagem",
    message_name: "Nome da Mensagem",
    message_body: "Mensagem",
    message: "Status do Lead atualizado com sucesso",
  },
  tag: {
    total_subs: "Total de Assinantes",
  },
  signin: {
    name_password_signin: "Entre com seu usuário e senha para logar",
    user: "Usuário",
    password: "Senha",
    remember_me: "Lembre de mim",
    signin: "ENTRAR",
  },
  forms_survey: {
    forms_survey: "Formulários e Pesquisa",
  },
  generate_message: {
    generate_message: "Gerar Campanha",
    select_message: "Selecionar Mensagem",
    area_code: "Código de Área",
    area_code_all: "Todos Códigos",
    select_area_code: "Selecionar Códigos",
    generate_campaign_now: "GERAR CAMPANHA AGORA",
    all_tags: "Todas Etiquetas",
    limit_message: "Limite diário alterado",
    above_10k:
      "The total limit per campaign is 10.000 destinations.  Please select another TAG or break current one in pieces",
  },
  multiChanel: {
    multi_channel: "Multiatendimento",
  },
  tags11: {
    destination_tag: "Select a destination TAG",
    migrate: "Migrar",
    edit: "Editar",
    delete: "Apagar",
    delete_text:
      "Não é possível apagar Etiquetas com assinantes. Mova-os para outra etiqueta primeiro.",
  },
  column11: {
    destination_tag: "Select a destination TAG",
    migrate: "Migrar",
    edit: "Editar",
    delete: "Apagar",
    delete_text:
      "Não é possível apagar Etiquetas com assinantes. Mova-os para outra etiqueta primeiro.",
  },
  leads: {
    filter_tag: "Filtrar por Etiqueta",
    filter_operator: "Filtrar por Responsável",
    search: "Pesquisar",
    manage_stages: "Gerenciar Etapa",
    manage_messages: "Gerenciar Mensagens",
    first_column: "Entrada",
    responsable: "Selecionar responsável",
    start_chat: "Conversar",
    edit_profile: "Editar perfil",
    add_entrance: "Adicionar Assinante à Entrada",
    add: "Adicionar",
    remove: "Remover dos Leads",
    download_file_top_phrase: "Clique aqui para baixar modelo do arquivo xls",
    can_be_imported: "Nomes de colunas permitidos",
    not_welcome: "Não gere mensagens de novo cliente",
  },
  edit_subs: {
    edit_subs: "Editar Assinante",
  },
  whatsapp: {
    alert:
      "ANTES de clicar em NOVO QRCODE, é importante que primeiro pegue o celular com o número que será conectado, vá no aplicativo do Whatsapp, e em configurações no canto superior direito.  Em seguida vá em Dispositivos Conectados e clique no botão CONECTAR DISPOSITIVO para abrir sua câmera.  Apenas depois disso clique em NOVO QRCODE e aguarde ele aparecer para ler com sua câmera.",
    read_qr_1: "Ler QRCode no Servidor 1",
    read_qr_2: "Ler QRCode no Servidor 2",
    read_qr_4: "Ler QRCode no Servidor 4",
    read_qr_counter: "Aguarde contagem regressiva  para gerar novo qrcode",
  },
  login: {
    login: "Login com sucesso",
  },
  multichanel: {
    chanel_admin: "Admin do Multiatendimento",
  },
  signin1: {
    remember_me: "Lembrar senha",
    signing_in: "Acessando...",
  },
  open_cards: {
    open_cards: "VER CARDS",
    previous: "Anterior",
    next: "Seguinte",
    company: "Empresa",
    get_profile_pic: "Captar Imagem",
    delete_pic: "Apagar Imagem",
  },
  setting_AI: {
    saved_successfully: "Dados da assistente IA salvos com sucesso!",
    file_uploaded: "Arquivo enviado com sucesso ao chatGPT!",
    data_sent: "Dados enviados com sucesso ao chatGPT!",
    delete_file: " Arquivo apagado com sucesso do chatGPT.",
    send_successfully: "Instruções enviadas com sucesso",
  },
  confirmationModel: {
    import_all:
      "Os dados importados serão integrados ao seu banco de dados em segundo plano.",
  },
  leads12: {
    column_removed: "Lead removido",
    data_saved: "Dados salvos com sucesso",
    avatar_delete: "Avatar apagado com sucesso",
  },
};

export default portuguese;
