import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import BookingCard from "../../examples/Cards/BookingCard";
import thumbnail from "../../assets/images/thumbnail.jpg";
import Card from "@mui/material/Card";
import API_ROUTES, { FLOW_BUILDER_URL } from "../../config/apiConfig";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";

const Automatizer = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const [loading, setLoading] = useState(false);

  const handleOpenFlowBuilder = async () => {
    setLoading(true);
    const group_id = localStorage.getItem("group_id");

    if (!group_id) {
      toast.error("Group id is missing from localStorage");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(API_ROUTES.FLOW_BUILDER_API, {
        group_id,
      });

      if (response.data) {
        const token = response.data;
        window.open(
          `${FLOW_BUILDER_URL}/connect_flowbuilder?token=${token}`,
          "_blank"
        );
      } else {
        throw new Error("Invalid response from API");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://crmstation.net/laravel/public/api/api_get_videos?page=flowbuilder"
      )
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={6}>
        <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description} // Assuming the description is part of the API response
                      price={video.price} // Assuming the price is part of the API response
                      location={video.location} // Assuming the location is part of the API response
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {/* <MDBox textAlign="center" mt={3}>
                  <h3>Video not found</h3>
                </MDBox> */}
              </Grid>
            )}
          </Grid>
        </MDBox>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <Card>
                  <MDBox p={3}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h6" color="textSecondary">
                        {translations.main_menu?.flowbuilder}
                      </MDTypography>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={handleOpenFlowBuilder}
                      disabled={loading}
                      style={{ marginTop: "1.5rem" }}
                    >
                      {loading
                        ? "Loading..."
                        : translations.flowbuilder?.open_flowbuilder}
                    </MDButton>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      {/* <ToastContainer /> */}
    </DashboardLayout>
  );
};

export default Automatizer;
