import React, { createContext, useState, useEffect, useContext } from "react";
import { useGlobalData } from "./GlobalDataContext";

const TagsContext = createContext();

export const TagsProvider = ({ children }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [userData, setUserData] = useState([]);
  const [allTagsSelected, setAllTagsSelected] = useState(false);
  const { state, dispatch } = useGlobalData();

  useEffect(() => {
    // Update the global context with user data
    dispatch({ type: "SET_STEP_TWO_DATA", payload: userData });
  }, [userData, dispatch]);

  const deleteUser = (id) => {
    setUserData((prevData) => prevData.filter((user) => user.user_id || user.id !== id));
    dispatch({ type: "DELETE_USER", payload: id });
  };

  return (
    <TagsContext.Provider
      value={{
        selectedTags,
        setSelectedTags,
        userData,
        setUserData,
        deleteUser,
        allTagsSelected,
        setAllTagsSelected,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

export default TagsContext;
