import PropTypes from "prop-types";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

// @mui material components
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import API_ROUTES from "../../../../../config/apiConfig";

function UserInfo() {
  const [campaignName, setCampaignName] = useState("");
  const [smsBody, setSmsBody] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };

  const group_id = localStorage.getItem("group_id");

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("campaign_name", campaignName);
      formData.append("sms_body", smsBody);
      formData.append("group_id", group_id);
      if (imageFile) {
        formData.append("image", imageFile);
      }

      const response = await axios.post(API_ROUTES.DATA_SEND_CREATE_NEW_STEP_1, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Form submitted successfully!');
      console.log('API Response:', response.data);
    } catch (error) {
      toast.error('Error submitting form data');
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">To compose</MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={12}>
            <TextField
              type="text"
              label="Name the campaign"
              name="campaign_name"
              placeholder="Name the campaign"
              fullWidth
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={12}>
            <TextField
              type="text"
              label="Enter text"
              name="sms_body"
              placeholder="Enter text"
              fullWidth
              multiline
              rows={8}
              value={smsBody}
              onChange={(e) => setSmsBody(e.target.value)}
            />
            <MDTypography variant="h6">Use %opt-out% to add opt-out link</MDTypography> 
            <MDTypography variant="h6">Use %name% or %nome% to customize the Subscriber's name</MDTypography> 
            <MDTypography variant="h6">Use %update% to include update link.</MDTypography> 
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                mt: 2,
                p: 2,
                border: '1px dashed grey',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f0f0f0',
                cursor: 'pointer',
                height: '150px',
              }}
              onClick={() => document.getElementById('imageUpload').click()}
            >
              {selectedImage ? (
                <img src={selectedImage} alt="Uploaded" style={{ maxHeight: '100%', maxWidth: '100%' }} />
              ) : (
                <MDTypography variant="body1" color="textSecondary">
                  Click on the box to upload image
                </MDTypography>
              )}
            </Box>
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
          </Grid>
        </Grid>
        <MDBox mt={2}>
          <button onClick={handleSubmit}>Submit</button>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

UserInfo.propTypes = {
  // Removed formData and updateFormData props
};

export default UserInfo;
