// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

function Breadcrumbs({ icon, title, route, light }) {
  const getBreadcrumbTitle = (routeSegment) => {
    const language = localStorage.getItem("language") || "portuguese";

    const titles = {
      english: {
        home: "Home",
        yourPublic: "Your Public",
        leads: "Leads and Vips",
        automatize: "Automatize",
        flowbuilder: "Flowbuilder",
        your_data: "Your Data",
        ai_assistant: "AI Assistant",
        settings: "Settings",
        upload_image: "Upload Image",
        messages_bots: "Message Bots",
        bot_reports: "Bot Reports",
        bot_birthday: "Bot Birthday",
        bot_welcome: "Bot Welcome",
        bot_appointment: "Bot Appointment",
        bot_firstpurchase: "Bot 1st Purchase",
        bot_expiring: "Bot Expiring",
        campaigns: "Campaigns",
        campaigns_reports: "Campaign Reports",
        create_new: "Create New",
        integrations: "Integrations",
        our_api: "Our API",
        hotmart: "Hotmart",
        google_sheets: "Google Sheets",
        woo_commerce: "WooCommerce",
        instagram_bot: "Instagram Bot",
        cupons: "Coupons",
        new_promotion: "New Promotion",
        cupons_list: "Coupons List",
        alert_messages: "Alert Messages",
        whatsapp_cloudapi: "WhatsApp Cloud API",
        metrics: "Metrics",
        others: "Others",
        staff: "Staff",
        lottery: "Lottery",
        linktudo: "Link-tudo",
        ecommerce: "Ecommerce",
        whatsapp: "Official WhatsApp API",
        compose_message: "Compose Message",
        generate_message: "Generate Campaign",
        campaign_report_detail: "Campaign Report Detail",
        tags: "Tags",
        bots_report_detail: "Bots Report Detail",
        multichannel: "Multichannel",
        edit_tag: "Entiquetas",
        edit_subs: "Edit Subscribers",
        appointment_imported_events: "View imported events",
        create_column:"Create Column",
      },
      portuguese: {
        home: "Iniciar",
        yourPublic: "Seu Público",
        leads: "Leads e Vips",
        automatize: "Automatizar",
        flowbuilder: "Flowbuilder",
        your_data: "Seus Dados",
        ai_assistant: "Assistente IA",
        settings: "Configurações",
        upload_image: "Enviar Imagem",
        messages_bots: "Bots de Mensagens",
        bot_reports: "Relatórios de Bots",
        bot_birthday: "Bot de Aniversário",
        bot_welcome: "Bot de Boas-Vindas",
        bot_appointment: "Bot de Agendamento",
        bot_firstpurchase: "Bot de Primeira Compra",
        bot_expiring: "Bot de Vencimento",
        campaigns: "Campanhas",
        campaigns_reports: "Relatórios de Campanhas",
        create_new: "Criar Novo",
        integrations: "Integrações",
        our_api: "Nossa API",
        hotmart: "Hotmart",
        google_sheets: "Google Sheets",
        woo_commerce: "WooCommerce",
        instagram_bot: "Bot do Instagram",
        cupons: "Cupons",
        new_promotion: "Nova Promoção",
        cupons_list: "Lista de Cupons",
        alert_messages: "Mensagens de Alerta",
        whatsapp_cloudapi: "API Cloud do WhatsApp",
        metrics: "Métricas",
        others: "Outros",
        staff: "Equipe",
        lottery: "Sorteio",
        linktudo: "Link-tudo",
        ecommerce: "Ecommerce",
        whatsapp: "WhatsApp Oficial",
        compose_message: "Compor Mensagem",
        generate_message: "Gerar Campanha",
        campaign_report_detail: "Detalhes da Campanha",
        tags: "Etiquetas",
        bots_report_detail: "Relatórios de Bots",
        multichannel: "Multiatendimento",
        edit_tag: "Entiquetas",
        edit_subs: "Editar Assinante",
        appointment_imported_events: "Ver eventos importados",
        create_column:"Nova Etapa"
      },
    };

    const langTitles = titles[language] || titles.english;

    return langTitles[routeSegment] || routeSegment;
  };

  const routes = route.slice(0, -1).map((routeSegment) => {
    // Keep only the base route (without ID or extra params)
    const baseSegment = routeSegment.split("/")[0];
    return getBreadcrumbTitle(baseSegment);
  });

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) =>
              light ? white.main : grey[600],
          },
        }}
      >
        <Link to="/home">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {el}
            </MDTypography>
          </Link>
        ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </MDTypography>
      </MuiBreadcrumbs>
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </MDTypography>
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
