import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import loadTranslations from "../../../../utils/loadTranslations";
import { useLanguageState } from "../../../../context/LanguageContext";

const MetaPixel = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);
  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          {translations.page_home?.meta_pixel}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12} md={7} lg={3} style={{ height: "13.7rem" }}></Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default MetaPixel;