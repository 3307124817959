import React, { createContext, useContext, useReducer } from "react";

const CampaignContext = createContext();

const initialState = {
  group_id: "",
  campaign_id: "",
  area_code: "",
  total_tags: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_GROUP_ID":
      return { ...state, group_id: action.payload };
    case "SET_CAMPAIGN_ID":
      return { ...state, campaign_id: action.payload };
    case "SET_AREA_CODE":
      return { ...state, area_code: action.payload };
    case "SET_TOTAL_TAGS":
      return { ...state, total_tags: action.payload };
    default:
      return state;
  }
};

export const CampaignProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CampaignContext.Provider value={{ state, dispatch }}>
      {children}
    </CampaignContext.Provider>
  );
};

export const useCampaignContext = () => useContext(CampaignContext);
