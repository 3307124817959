import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box"; // Import Box component
import MDBox from "../../../components/MDBox";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import MDButton from "../../../components/MDButton";
import API_ROUTES from "../../../config/apiConfig"; // Ensure API_ROUTES is defined in your apiConfig
import { toast } from "react-toastify";
import loadTranslations from "../../../utils/loadTranslations";
import { useLanguageState } from "../../../context/LanguageContext";

const CreateTag = () => {
  const [tagName, setTagName] = useState("");
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaving(true);
    const group_id = localStorage.getItem("group_id");

    try {
      await axios.post(API_ROUTES.DATA_SEND_TAG, {
        group_id: group_id,
        name: tagName,
      });

      toast.success("Tag created successfully!");
      navigate("/tags"); // Navigate back to tags page after successful creation
    } catch (error) {
      console.error("Error creating tag:", error);
      toast.error("Error creating tag. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      //console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} style={{ height: "80vh" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title={translations.create_tag?.create_tag} />
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label={translations.create_tag?.tag_name}
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                  />
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <MDButton
                      variant="gradient"
                      color="black1"
                      onClick={() => navigate("/tags")}
                      style={{color:"black"}}
                    >
                      {translations.buttons_and_common_words?.back}
                    </MDButton>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      disabled={saving}
                    >
                      {saving
                        ? "Saving..."
                        : translations.buttons_and_common_words?.save}
                    </MDButton>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CreateTag;
