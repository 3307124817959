import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import BookingCard from "../../../examples/Cards/BookingCard";
import thumbnail from "../../../assets/images/thumbnail.jpg";
import FourthCard from "./FourthCard";
import Typography from "@mui/material/Typography";
import MDTypography from "../../../components/MDTypography";
// import ThirdCard from "./ThirdCard";
import SecondCard from "./SecondCard";
import FirstCard from "./FirstCard";
import DataTable from "../../../examples/Tables/DataTable";
import { Card } from "@mui/material";
import API_ROUTES from "../../../config/apiConfig";
import PolicyIcon from "@mui/icons-material/Policy";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import MDButton from "../../../components/MDButton";


const BotsReports = () => {
  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
  });
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({}); // Initially null

  const [videoData, setVideoData] = useState([]);
 
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleDelete = (userId) => {
    setUserIdToDelete(userId);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setUserIdToDelete(null);
  };
  const group_id = localStorage.getItem("group_id");
  const [reloadData, setReloadData] = useState(false); // Add this state
  const handleConfirmDelete = async () => {
    try {
      const response = await axios.post(`${API_ROUTES.DELETE_REPORT_BOT}`, {
        group_id,
        id: userIdToDelete,
      });
  
      if (response) {
        toast.success("Bot Report Deleted Successfully");
  
        setTableData((prevState) => ({
          ...prevState,
          rows: prevState.rows.filter((row) => {
            // Compare based on user ID or any unique identifier instead of the function
            const rowUserId = row.userId; // Assuming row.userId holds the user ID of the row
            return rowUserId !== userIdToDelete;
          })
        }));
  
        setReloadData(!reloadData); // Toggle reloadData to trigger re-fetch
      }
    } catch (error) {
      toast.error("Error deleting user.");
      console.error("Error deleting user:", error);
    } finally {
      handleCloseDeleteModal();
    }
  };
  




  useEffect(() => {
    axios
      .get(
        "https://crmstation.net/laravel/public/api/api_get_videos?page=message_reports"
      )
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log("Loaded Translations: ", loadedTranslations); // Add this line
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  useEffect(() => {
    if (translations) {
      setTableData((prevState) => ({
        ...prevState,
        columns: [ 
          {
            Header: translations.bots_reports?.date || "Data",
            accessor: "date",
            width: "13%",
          },
          {
            Header: translations.bots_reports?.name || "Nome",
            accessor: "name",
            width: "15%",
          },
          {
            Header: translations.bots_reports?.message || "Mensagem",
            accessor: "message",
            width: "25%",
          },
          {
            Header: translations.bots_reports?.imagem || "Imagem",
            accessor: "image",
            width: "15%",
          },
          {
            Header: translations.bots_reports?.event_date || "Data do evento",
            accessor: "event_date",
            width: "17%",
          },
          {
            Header: translations.bots_reports?.tags || "Tags",
            accessor: "tags",
            width: "17%",
          },
          {
            Header: translations.bots_reports?.actions || "Ações",
            accessor: "actions",
            width: "15%",
          },
        ],
      }));
    }
  }, [translations]);

  useEffect(() => {
    const fetchData = async () => {
      const group_id = localStorage.getItem("group_id");

      try {
        const response = await axios.post(API_ROUTES.BOTS_REPORTS_GET_DATA, {
          group_id,
        });

        const fetchedData = response.data.data.map((item) => {
          const formattedDate = formatDate(item.created_at); // Format date to dd-mm-yyyy
          const formattedDate1 = formatDate1(item.event_date);
          
          const message =
            item.body.length > 100
              ? `${item.body.substring(0, 100)}...`
              : item.body; // Truncate message

          return {
            date: formattedDate,
            name: item.campaign_name,
            message: message,
            image: (
              <img
                src={item.image}
                alt={item.campaign_name}
                style={{ width: "100px", height: "auto" }}
              />
            ),
            tags: item.tags,
            event_date:formattedDate1,
            actions: (
              <div style={{ display: "flex", gap: "10px" }}>
                <Link to={`/bots_report_detail/${item.id}`}>
                  <IconButton aria-label="edit" style={{ color: "#4cc9f0" }}>
                    <FormatListBulletedIcon />
                  </IconButton>
                </Link>
                <IconButton
                  color="secondary"
                  aria-label="delete"
                  style={{ color: "#f72585" }}
                  onClick={() => handleDelete(item.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ),
          };
        });

        console.log("Fetched Data: ", fetchedData); // Add this line

        setTableData((prevState) => ({ ...prevState, rows: fetchedData }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (translations) {
      fetchData();
    }
  }, [translations]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const formatDate1 = (dateString) => {
    const dateParts = dateString.split('-'); // Split the date string by '-'
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; // Rearrange the parts to dd-mm-yyyy
    return formattedDate;
  };

  if (!translations) {
    return <div>Loading...</div>; // Show a loading indicator while translations are being fetched
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description} // Assuming the description is part of the API response
                      price={video.price} // Assuming the price is part of the API response
                      location={video.location} // Assuming the location is part of the API response
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {/* <MDBox textAlign="center" mt={3}>
                  <h3>Video not found</h3>
                </MDBox> */}
              </Grid>
            )}
          </Grid>
        </MDBox>

        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <FirstCard />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <SecondCard />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <ThirdCard />
              </MDBox>
            </Grid> */}
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>{/* <FourthCard /> */}</MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={6}>
          <Card>
            <DataTable table={tableData} canSearch />
          </Card>
        </MDBox>
      </MDBox>

      <Footer />
       {/* Modal deletion */}
       <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            id="delete-confirmation-modal-title"
            variant="h6"
            component="h2"
          >
            {translations.buttons_and_common_words?.are_you_sure}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <MDButton
              onClick={handleCloseDeleteModal}
              color="black1"
              variant="gradient"
              style={{ color: "black" }}
            >
              {translations.buttons_and_common_words?.cancel}
            </MDButton>
            <MDButton
              onClick={handleConfirmDelete}
              color="error"
              variant="gradient"
              style={{ marginLeft: "5px" }}
            >
              {translations.buttons_and_common_words?.ok}
            </MDButton>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default BotsReports;
