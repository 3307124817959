import React, { useEffect, useState } from "react";
import { ImportExport as ImportIcon } from "@mui/icons-material";
import MDButton from "../../components/MDButton";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";

const UserExportButton = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const [loading, setLoading] = useState(false);
  const group_id = localStorage.getItem("group_id");

  const downloadUsers = () => {
    setLoading(true); // Set loading state to true when the button is clicked

    fetch(`https://crmstation.net/laravel/public/api/export/${group_id}`, {
      method: "GET",
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `users_group_${group_id}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setLoading(false); // Reset loading state after download is complete
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
        setLoading(false); // Reset loading state if there's an error
        // Optionally show a user-friendly error message
      });
  };

  return (
    <MDButton
      variant="gradient"
      style={{
        backgroundColor: "#60d3ca",
        color: "white",
        marginLeft: "8px",
      }}
      startIcon={<ImportIcon />}
      onClick={() => downloadUsers()}
      disabled={loading} // Disable the button while loading
    >
      {loading ? "Exporting..." : translations.buttons_and_common_words?.export}{" "}
      {/* Show "Exporting..." when loading */}
    </MDButton>
  );
};

export default UserExportButton;
