import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import { useLanguageState } from "../../../../context/LanguageContext";
import loadTranslations from "../../../../utils/loadTranslations";

const GooglePixel = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
        Google TAG
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Paste Google Tag Manager Script"
              variant="outlined"
              fullWidth
              multiline
              rows={7} // Set the number of rows for the text field
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ textAlign: "right" }}>
            <MDButton variant="gradient" color="dark">
              {translations.buttons_and_common_words?.save}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default GooglePixel;
