import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// react-table components
import { useTable, useGlobalFilter, useAsyncDebounce } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "../../../examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "../../../examples/Tables/DataTable/DataTableBodyCell";
import MDPagination from "../../../components/MDPagination";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  columns,
  apiEndpoint,
  formatData,
  isSorted,
  noEndBorder,
  tag,
  total, // total number of entries
  reloadData,
  dob,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "25", "50", "100", "500", "1000"];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(defaultValue); // State to track current page size
  const [inputValue, setInputValue] = useState(offset);
  const group_id = localStorage.getItem("group_id");
  const [search, setSearch] = useState(""); // Add search state

  const fetchData = async (pageSize, pageOffset, tagId, searchTerm) => { // Add searchTerm as a parameter
    setLoading(true);
    try {
      let url = `${apiEndpoint}?group_id=${group_id}&limit=${pageSize}&offset=${pageOffset * pageSize}&dob=${dob}`;
      if (tagId !== 0) {
        url += `&tag_id=${tagId}`;
      }
      if (searchTerm) {
        url += `&search=${encodeURIComponent(searchTerm)}`; // Append search parameter to the URL
      }
      const response = await axios.get(url);
      if (response.data.status === "success") {
        setData(formatData(response.data.data));
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(pageSize, offset, tag, search); // Pass search state when fetching data
    setInputValue(offset); // Synchronize inputValue with offset
  }, [pageSize, offset, tag, reloadData, search]); // Add search as a dependency

  useEffect(() => {
    fetchData(pageSize, 0, tag, search); // Include search in initial fetch
  }, [pageSize]);

  const columnsMemo = useMemo(() => columns, [columns]);
  const dataMemo = useMemo(() => data, [data]);

  const tableInstance = useTable(
    { columns: columnsMemo, data: dataMemo },
    useGlobalFilter
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { globalFilter },
  } = tableInstance;

  // Calculate the start and end entries
  const startEntry = offset * pageSize + 1;
  const endEntry = Math.min((offset + 1) * pageSize, total);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setPageSize(value); // Update the page size state
    setOffset(0);
    fetchData(value, 0, tag, search); // Pass search when fetching data
  };

  // Debounced search input change
  const onSearchChange = useAsyncDebounce((value) => {
    setSearch(value); // Update search state
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asc";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Navigate to the next page
  const nextPage = () => {
    setOffset((prevOffset) => prevOffset + 1);
  };

  // Navigate to the previous page
  const previousPage = () => {
    if (offset > 0) {
      setOffset((prevOffset) => prevOffset - 1);
    }
  };

  // Handle input change
  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      setInputValue(value);
      setOffset(value);
    }
  };

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()} // Use pageSize for the selected value
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;
                {translations.buttons_and_common_words?.enteries_per_page}
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search} // Bind input to search state
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(currentTarget.value); // Update search state
                  onSearchChange(currentTarget.value); // Debounced API call
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  key={column.id}
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                  noEndBorder={noEndBorder}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {loading ? (
            <TableRow>
              <DataTableBodyCell colSpan={columns.length} align="center">
                {translations.campaign_reports?.loading}
              </DataTableBodyCell>
            </TableRow>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      key={cell.column.id}
                      noBorder={noEndBorder}
                      align={cell.column.align ? cell.column.align : "left"}
                    >
                      {cell.render("Cell") || ""}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDTypography variant="button" color="secondary">
          {`${translations.buttons_and_common_words?.showing} ${startEntry} ${translations.buttons_and_common_words?.to} ${endEntry} of ${total} ${translations.buttons_and_common_words?.entries}`}
          </MDTypography>

        <MDBox display="flex" alignItems="center">
          {offset > 0 && (
            <MDPagination item onClick={previousPage}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
          )}
          <MDTypography variant="button" color="secondary" mx={2}>
            <MDBox width="5rem" mx={1}>
              <MDInput
                inputProps={{
                  type: "number",
                  min: 0,
                }}
                value={inputValue}
                onChange={handleInputChange}
              />
            </MDBox>
          </MDTypography>
          <MDPagination item onClick={nextPage}>
            <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
          </MDPagination>
        </MDBox>
      </MDBox>
    </TableContainer>
  );
}

// Setting default value for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["10", "25", "50", "100", "500", "1000"] },
  canSearch: false,
  showTotalEntries: true,
  isSorted: false,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.string),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  apiEndpoint: PropTypes.string.isRequired,
  formatData: PropTypes.func.isRequired,
  tag: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired, // Ensure total is required
};

export default DataTable;
