import PropTypes from "prop-types";
import { useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import MDButton from "../../../../../components/MDButton";
import { useNavigate } from "react-router-dom";
import API_ROUTES from "../../../../../config/apiConfig";
import AreaCodeContext from "../../../../../context/AreaCodeContext";

function AreaCode() {
  const [selectedOption, setSelectedOption] = useState("option1");
  const [areaCode, setAreaCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // New loading state
  const navigate = useNavigate();
  const group_id = localStorage.getItem("group_id");
  const { setAreaCodes } = useContext(AreaCodeContext);

  const handleSubmit = async () => {
    let payload;
    if (selectedOption === "option1") {
      payload = {
        area_code: "all_area_code",
        group_id: group_id,
      };
      setAreaCodes({ all: true, selected: [] });
    } else {
      payload = {
        area_code: areaCode,
        group_id: group_id,
      };
      const selectedCodes = areaCode.split(",").map((code) => code.trim());
      setAreaCodes({ all: false, selected: selectedCodes });
    }

    setIsSubmitting(true); // Set loading state to true

    try {
      const response = await axios.post(
        API_ROUTES.DATA_SEND_CREATE_NEW_STEP_2,
        payload
      );

      if (response.data) {
        navigate("/tag-categories");
      } else {
        toast.error("Error submitting form data");
        console.error("Error response:", response.data);
      }
    } catch (error) {
      toast.error("Error submitting form data");
      console.error("Error submitting form data:", error);
    } finally {
      setIsSubmitting(false); // Set loading state to false
    }
  };

  const NextPage = () => navigate("/to-compose");

  return (
    <>
      <MDBox>
        <MDTypography variant="h5" fontWeight="bold" textAlign="center">
          Area Code
        </MDTypography>
        <MDBox mt={1.625}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={6} md={3} lg={12}>
              <RadioGroup
                row
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <FormControlLabel
                  value="option1"
                  control={<Radio />}
                  label="Send to all"
                />
                <FormControlLabel
                  value="option2"
                  control={<Radio />}
                  label="Send to Selected"
                />
              </RadioGroup>
            </Grid>
            {selectedOption === "option2" && (
              <Grid item xs={12} sm={6} md={3} lg={12}>
                <MDTypography variant="h6" fontWeight="bold">
                  Enter the area codes in the format 5511,5521,5531... (with commas between them)
                </MDTypography>
                <MDInput
                  type="text"
                  label="Enter Area Code"
                  fullWidth
                  value={areaCode}
                  onChange={(e) => setAreaCode(e.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox mt={2} width="100%" display="flex" justifyContent="flex-end">
        <MDButton
          variant="gradient"
          color="black1"
          style={{ color: "black" }}
          onClick={NextPage}
        >
          Back
        </MDButton>
        <MDButton
          type="button" // Changed to button to avoid form submission
          variant="gradient"
          color="dark"
          onClick={handleSubmit}
          style={{ marginLeft: "8px" }}
          disabled={isSubmitting} // Disable button if submitting
        >
          {isSubmitting ? "Sending..." : "Next"} {/* Show loading text */}
        </MDButton>
      </MDBox>
    </>
  );
}

// Typechecking props for AreaCode
AreaCode.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AreaCode;
