import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import { Grid, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "./DataTable";
import API_ROUTES from "../../config/apiConfig";
import loadTranslations from "../../utils/loadTranslations";
import { useLanguageState } from "../../context/LanguageContext";

const ImportedData = () => { 
  const { id } = useParams();
  const [importedData, setImportedData] = useState([]);
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);
  // Fetch translations and data based on the file_id from the params
  useEffect(() => {
    console.log(id);
    const group_id = localStorage.getItem("group_id");

    const fetchData = async () => {
      try {
        const response = await axios.post(API_ROUTES.API_IMPORTED_CSV_DATA, {
          file_id: id,
          group_id: group_id
        });
        setImportedData(response.data.data); // Assuming the API returns the data array

        const loadedTranslations = await loadTranslations("en"); // Replace with your language fetching logic
        setTranslations(loadedTranslations);
      } catch (error) {
        toast.error("Failed to fetch data.");
      }
    };

    fetchData();
  }, [id]);

  // Update a specific cell in the array
  const handleInputChange = (index, key, value) => {
    const updatedData = [...importedData];
    updatedData[index][key] = value;
    setImportedData(updatedData);
  };

  // Delete a row from the array
  const handleDeleteRow = (index) => {
    const updatedData = importedData.filter((_, i) => i !== index);
    setImportedData(updatedData);
  };

  // Function to generate the columns and rows for the table
  const getTableData = () => {
    if (importedData.length === 0) {
      return { columns: [], rows: [] }; // Return empty columns and rows when no data is available
    }

    const columnsMapping = {
      name: translations.your_public_import?.name || "Name",
      mobile: translations.your_public_import?.mobile || "Mobile",
      email: translations.your_public_import?.email || "Email",
      dob: translations.your_public_import?.dob || "DOB",
      tagid: translations.your_public_import?.tagid || "Tag ID",
      company: translations.your_public_import?.company || "Company",
      address: translations.your_public_import?.address || "Address",
      number: translations.your_public_import?.number || "Number",
      complement: translations.your_public_import?.complement || "Complement",
      district: translations.your_public_import?.district || "District",
      city: translations.your_public_import?.city || "City",
      state: translations.your_public_import?.state || "State",
      zipcode: translations.your_public_import?.zipcode || "ZIP Code",
      cpf: translations.your_public_import?.cpf || "CPF",
      firstorder_date:
        translations.your_public_import?.datepurchase || "First Order Date",
      expiring_date:
        translations.your_public_import?.dateexpiring || "Expiring Date",
      operator: translations.your_public_import?.responsable || "Operator",
      product: translations.your_public_import?.product || "Product",
      value: translations.your_public_import?.value || "Value",
      extra1: translations.your_public_import?.extra1 || "Extra 1",
      extra2: translations.your_public_import?.extra2 || "Extra 2",
      delete: "Delete",
    };

    const columns = Object.keys(columnsMapping).map((key) => ({
      Header: columnsMapping[key],
      accessor: key,
    }));

    const rows = importedData.map((row, index) =>
      Object.keys(columnsMapping).reduce((acc, key) => {
        if (key === "delete") {
          acc[key] = (
            <IconButton color="error" onClick={() => handleDeleteRow(index)}>
              <ClearIcon />
            </IconButton>
          );
        } else {
          acc[key] = (
            <input
              type="text"
              value={row[key] || ""}
              onChange={(e) => handleInputChange(index, key, e.target.value)}
              style={{
                padding: "5px",
                border: "1px solid #00000017",
                borderRadius: "5px",
                width:"100%"
              }}
            />
          );
        }
        return acc;
      }, {})
    );

    return { columns, rows };
  };

  const { columns, rows } = getTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* Add a check for when columns or rows are empty */}
              {columns.length > 0 && rows.length > 0 ? (
                <DataTable
                  table={getTableData()}
                  canSearch
                />
              ) : (
                <p>No data available</p>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default ImportedData;
