// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";

const TicketsQr = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <iframe
            src="https://crmstation.net/ticketqr"
            title="Embedded Page"
            width="100%"
            height="600px"
            style={{ border: "none" }}
            allowFullScreen
          />
        </Grid>
      </MDBox>
      {/* <div style={{ height: "100%" }}></div> */}
      <Footer />
    </DashboardLayout>
  );
};

export default TicketsQr;
