import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { toast } from "react-toastify";
import { useLogo } from "../../../../context/LogoContext"; // Import useLogo hook
import API_ROUTES, { BASE_URL } from "../../../../config/apiConfig";
import loadTranslations from "../../../../utils/loadTranslations";
import { useLanguageState } from "../../../../context/LanguageContext";

function UploadForm() {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const [logo, setLogo] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [banner1, setBanner1] = useState(null);
  const [banner1URL, setBanner1URL] = useState(null);
  const [banner2, setBanner2] = useState(null);
  const [banner2URL, setBanner2URL] = useState(null);
  const [background, setBackground] = useState(null);
  const [backgroundURL, setBackgroundURL] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const { updateLogo } = useLogo(); // Access updateLogo function
  const groupId = localStorage.getItem("group_id"); // Get group_id from localStorage

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.post(API_ROUTES.HOME_API_UPLOAD_FORM_GET, {
          group_id: groupId,
        });

        if (response.data.success) {
          const settings = response.data.data.user_group_setting[0];
          if (settings.logo) setLogoURL(`${BASE_URL}/${settings.logo}`);
          if (settings.Banner_1)
            setBanner1URL(`${BASE_URL}/${settings.Banner_1}`);
          if (settings.Banner_2)
            setBanner2URL(`${BASE_URL}/${settings.Banner_2}`);
          if (settings.background)
            setBackgroundURL(`${BASE_URL}/${settings.background}`);
        }
      } catch (error) {
        console.error("Failed to fetch images:", error);
      }
    };

    fetchImages();
  }, [groupId]);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogo(file);
    setLogoURL(URL.createObjectURL(file));
  };

  const handleBanner1Change = (event) => {
    const file = event.target.files[0];
    setBanner1(file);
    setBanner1URL(URL.createObjectURL(file));
  };

  const handleBanner2Change = (event) => {
    const file = event.target.files[0];
    setBanner2(file);
    setBanner2URL(URL.createObjectURL(file));
  };

  const handleBackgroundChange = (event) => {
    const file = event.target.files[0];
    setBackground(file);
    setBackgroundURL(URL.createObjectURL(file));
  };

  const handleSubmit = async () => {
    setLoading(true); // Set loading to true when submitting
    const formData = new FormData();
    formData.append("group_id", groupId);
    if (logo) formData.append("image", logo);
    if (banner1) formData.append("Banner_1", banner1);
    if (banner2) formData.append("Banner_2", banner2);
    if (background) formData.append("Bimage", background);

    try {
      const response = await axios.post(
        API_ROUTES.HOME_API_UPLOAD_FORM,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const logoUri = `${BASE_URL}/${response.data.data.logo}`;

      if (response.data.success) {
        updateLogo(logoUri); // Update logo in context
        toast.success("Images uploaded successfully");
      } else {
        toast.error("Failed to upload images");
      }
    } catch (error) {
      toast.error("Error uploading images:", error);
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };

  return (
    <Card sx={{ width: "100%", padding: 2, mt: 6 }}>
      <MDBox>
        <MDTypography variant="h6" mb={2}>
          {translations.page_home?.upload_logo_images}
        </MDTypography>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: banner2URL ? "auto" : "10rem",
            }}
          >
            <MDTypography variant="body1" mb={1}>
              Logo
            </MDTypography>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="logo-upload"
              type="file"
              onChange={handleLogoChange}
            />
            <label
              htmlFor="logo-upload"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#f0f0f0",
                cursor: "pointer",
                height: banner2URL ? "auto" : "10rem",
              }}
            >
              {logoURL ? (
                <img
                  src={logoURL}
                  alt="Logo"
                  style={{ width: "100%" }}
                />
              ) : (
                <MDTypography variant="body1" color="textSecondary">
                  Click to upload Logo
                </MDTypography>
              )}
            </label>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MDTypography variant="body1" mb={1}>
              Banner 1
            </MDTypography>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="banner1-upload"
              type="file"
              onChange={handleBanner1Change}
            />
            <label
              htmlFor="banner1-upload"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#f0f0f0",
                borderRadius: "10px",
                cursor: "pointer",
                height: banner2URL ? "auto" : "10rem",
              }}
            >
              {banner1URL ? (
                <img
                  src={banner1URL}
                  alt="Banner 1"
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                   
                  }}
                />
              ) : (
                <MDTypography variant="body1" color="textSecondary">
                  Click to upload Banner 1
                </MDTypography>
              )}
            </label>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MDTypography variant="body1" mb={1}>
              Banner 2
            </MDTypography>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="banner2-upload"
              type="file"
              onChange={handleBanner2Change}
            />
            <label
              htmlFor="banner2-upload"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#f0f0f0",
                borderRadius: "10px",
                cursor: "pointer",
              
              }}
            >
              {banner2URL ? (
                <img
                  src={banner2URL}
                  alt="Banner 2"
                  style={{
                    width: "100%",
                    height: "16rem",
                    borderRadius: "20px",
                  }}
                />
              ) : (
                <MDTypography variant="body1" color="textSecondary">
                  Click to upload Banner 2
                </MDTypography>
              )}
            </label>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MDTypography variant="body1" mb={1}>
              Background Image
            </MDTypography>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="background-upload"
              type="file"
              onChange={handleBackgroundChange}
            />
            <label
              htmlFor="background-upload"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#f0f0f0",
                borderRadius: "10px",
                cursor: "pointer",
                
              }}
            >
              {backgroundURL ? (
                <img
                  src={backgroundURL}
                  alt="Background"
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                  }}
                />
              ) : (
                <MDTypography variant="body1" color="textSecondary">
                  Click to upload Background
                </MDTypography>
              )}
            </label>
          </Grid>
        </Grid>
        <MDBox mt={2} display="flex" justifyContent="flex-end">
          {loading ? (
            <MDButton variant="gradient" color="dark" onClick={handleSubmit}>
              <CircularProgress />
            </MDButton>
          ) : (
            <MDButton variant="gradient" color="dark" onClick={handleSubmit}>
              {translations.buttons_and_common_words?.save}
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default UploadForm;
