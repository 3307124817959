import { Modal, TextField, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MDButton from "../../components/MDButton";
import axios from 'axios';
import { toast } from "react-toastify";

const EditModal = ({ open, onClose, user }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => { 
    if (user) {
      setFormData({
        id: user.id || "",
        name: user.name || "",
        mobile: user.mobile || "",
        email: user.email || "",
        dob: user.dob || "",
        tagid: user.tagid || "",
        company: user.company || "",
        address: user.address || "",
        city: user.city || "",
        state: user.state || "",
        zipcode: user.zipcode || "",
        cpf: user.cpf || "",
        firstorder_date: user.firstorder_date || "",
        expiring_date: user.expiring_date || "",
        operator: user.operator || "",
        product: user.product || "",
        value: user.value || "",
        extra1: user.extra1 || "",
        extra2: user.extra2 || "",
        extra3: user.extra3 || "",
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.mobile) {
      formErrors.mobile = "Mobile is required";
    }
    return formErrors;
  };

  const handleSave = async () => {   
    const formErrors = validateForm(); 
    if (Object.keys(formErrors).length === 0) {  
      console.log("Form Data:", formData);
      // Perform save action (e.g., API call)
      try {
        const response = await axios.post('https://crmstation.net/laravel/public/api/api_update_user_temp', formData);
        console.log("API Response:", response.data);
        toast.success("User data updated successfully.");
      
      } catch (error) {
        console.error("Failed to save data:", error);
        // Handle error (e.g., show error message)
      }


    } else {
      setErrors(formErrors);
    }
  };   

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          width: "90%",
          maxWidth: "600px",
          maxHeight: "90vh", // Ensure the modal takes up a max height
          overflowY: "auto", // Enable scroll for the modal content if it overflows
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Edit User
        </Typography>
        <form>
          <TextField
            type="hidden"
            name="id"
            value={formData.id}
            onChange={handleInputChange}
          />
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleInputChange}
            fullWidth
            required
            error={!!errors.mobile}
            helperText={errors.mobile || ""}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="DOB"
            name="dob"
            value={formData.dob}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Tag ID"
            name="tagid"
            value={formData.tagid}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Company"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="City"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="State"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="ZIP Code"
            name="zipcode"
            value={formData.zipcode}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="CPF"
            name="cpf"
            value={formData.cpf}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="First Order Date"
            name="firstorder_date"
            value={formData.firstorder_date}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Expiring Date"
            name="expiring_date"
            value={formData.expiring_date}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Operator"
            name="operator"
            value={formData.operator}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Product"
            name="product"
            value={formData.product}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Value"
            name="value"
            value={formData.value}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Extra 1"
            name="extra1"
            value={formData.extra1}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Extra 2"
            name="extra2"
            value={formData.extra2}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Extra 3"
            name="extra3"
            value={formData.extra3}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />

          <MDButton
            variant="contained"
            color="primary"
            onClick={handleSave}
            fullWidth
            sx={{ mt: 2 }}
          >
            Save
          </MDButton>
        </form>
      </Box>
    </Modal>
  );
};

export default EditModal;
