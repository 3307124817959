import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import { useLanguageState } from "../../../../context/LanguageContext";
import axios from "axios"; // Assuming you are using axios
import API_ROUTES from "../../../../config/apiConfig"; // Assuming your API routes are defined here
import loadTranslations from "../../../../utils/loadTranslations";

function TermsOfUse() {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [termsOfUse, setTermsOfUse] = useState("");
  const [isExpanded, setIsExpanded] = useState(false); // Track if full text is shown

  const CHARACTER_LIMIT = 500; // Set your desired limit for truncation

  useEffect(() => {
    // Function to fetch translations
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    // Function to fetch terms of use from API
    const fetchTermsOfUse = async () => {
      try {
        const response = await axios.get(API_ROUTES.API_TERMS_AND_USE);
        setTermsOfUse(response.data.data.msg_body); // Assuming the API returns the message in `data.data.msg_body`
      } catch (error) {
        console.error("Error fetching terms of use:", error);
        setTermsOfUse("Failed to load Terms of Use."); // Show a fallback message
      }
    };

    fetchTranslations();
    fetchTermsOfUse();
  }, [language]);

  // Function to handle "Read More" or "Show Less" toggle
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // Get the truncated text if termsOfUse is longer than CHARACTER_LIMIT
  const truncatedText = termsOfUse.length > CHARACTER_LIMIT
    ? termsOfUse.slice(0, CHARACTER_LIMIT) + "..."
    : termsOfUse;

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDTypography variant="h6" sx={{ mt: 2, ml: 2 }}>
          {translations.page_home?.terms_of_use}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container>
          <Grid item xs={12} md={7} lg={12}>
            <MDBox
              sx={{
                maxHeight: "10rem", // Set fixed height
                overflowY: "auto",  // Enable vertical scrolling
                whiteSpace: "pre-wrap", // Keep line breaks as in the original text
                wordBreak: "break-word", // Break long words to fit within the box
              }}
            >
              <MDTypography variant="body1" style={{ fontSize: "15px" }}>
                {isExpanded ? termsOfUse : truncatedText}
                {termsOfUse.length > CHARACTER_LIMIT && (
                  <MDButton
                    variant="text"
                    color="info"
                    onClick={handleToggle}
                  >
                    {isExpanded ? "Show Less" : "Read More"}
                  </MDButton>
                )}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default TermsOfUse;
