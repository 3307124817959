// translations.js
export const translations = {
  english: {
    main_menu: {
      home: "Home",
      subscribers: "Your Public",
      leads: "Leads and Vips",
      automatize: "Automatize",
      flowbuilder: "Flowbuilder",
      your_data: "Your Data",
      ai_assistant: "AI Assistant",
      settings: "Settings",
      upload_image: "Upload Image",
      messages_bots: "Message Bots",
      bot_reports: "Reports",
      bot_birthday: "Birthday",
      bot_welcome: "Welcome",
      bot_appointment: "Appointment",
      bot_firstpurchase: "1st Purchase",
      bot_expiring: "Expiring",
      campaigns: "Campaigns",
      campaigns_reports: "Campaigns Reports",
      create_new: "Create New",
      integrations: "Integrations",
      our_api: "Our API",
      hotmart: "Hotmart",
      google_sheets: "Google Sheets",
      woo_commerce: "Woo Commerce",
      instagram_bot: "Instagram Bot",
      cupons: "Cupons",
      new_promotion: "New Promotion",
      cupons_list: "Cupons List",
      alert_messages: "Alert Messages",
      whatsapp_cloudapi: "Whatsapp Cloud API",
      metrics: "Metrics",
      others: "Others",
      staff: "Staff",
      lottery: "Lottery",
      cupons: "Coupons",
      linktudo: "Link-tudo",
      ecommerce: "Ecommerce",
      whatsapp: "Official Whatsapp API",
      compose_message: "Compose Message",
      generate_message: "Generate Campaign",
      campaign_report_detail: "Detalhes da Campanha",
      forms_survey: "Forms and Survey",
      bots_report_detail: "Bots Report Detail",
      multichannel:"Multichannel",
      open_cards:"CARDS VIEW",
      import: "Import",
    },
  },
  portuguese: {
    main_menu: {
      home: "Iniciar",
      subscribers: "Seu Público",
      leads: "Leads e Vips",
      automatize: "Automatizar",
      flowbuilder: "Construtor",
      your_data: "Seus Dados",
      ai_assistant: "Assistente IA",
      settings: "Configurações",
      upload_image: "Enviar Imagem",
      messages_bots: "Bots de Mensagens",
      bot_reports: "Relatórios de Bots",
      bot_birthday: "Aniversário",
      bot_welcome: "Boas-Vindas",
      bot_appointment: "Agendamento",
      bot_firstpurchase: "Primeira Compra",
      bot_expiring: "Vencimento",
      campaigns: "Campanhas",
      campaigns_reports: "Relatórios de Campanhas",
      create_new: "Criar Novo",
      integrations: "Integrações",
      our_api: "Nossa API",
      hotmart: "Hotmart",
      google_sheets: "Google Sheets",
      woo_commerce: "Woo Commerce",
      instagram_bot: "Bot do Instagram",
      // cupons: "Cupons",
      new_promotion: "Nova Promoção",
      cupons_list: "Lista de Cupons",
      alert_messages: "Mensagens de Alerta",
      whatsapp_cloudapi: "API Cloud do WhatsApp",
      metrics: "Métricas",
      others: "Outros",
      staff: "Equipe",
      lottery: "Sorteio",
      cupons: "Cupons",
      linktudo: "Link-tudo",
      ecommerce: "Ecommerce",
      whatsapp: "Whatsapp Oficial",
      compose_message: "Compor Mensagem",
      generate_message: "Gerar Campanha",
      campaign_report_detail: "Campaign Report Detail",
      forms_survey: "Formulários e Pesquisa",
      bots_report_detail: "Relatórios de Bots",
      multichannel:"Multiatendimento",
      open_cards:"VER CARDS",
      import: "Importar",
    },
  },
};
