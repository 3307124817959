import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import API_ROUTES, { BASE_URL } from "../config/apiConfig";

const LogoContext = createContext();

export const LogoProvider = ({ children }) => {
  const [logoURL, setLogoURL] = useState(null);
  const [groupId, setGroupId] = useState(localStorage.getItem("group_id"));

  // Fetch logo from the API whenever groupId changes
  useEffect(() => {
    const fetchLogo = async () => {
      console.log(groupId);
      
      if (!groupId) return;
  
      try {
        const response = await axios.post(
          API_ROUTES.HOME_API_UPLOAD_FORM_GET,
          { group_id: groupId }
        );
        console.log("API Response:", response); // Add this line
        if (response.data.success) {
          const settings = response.data.data.user_group_setting[0];
          if (settings.logo) {
            setLogoURL(`${BASE_URL}/${settings.logo}`);
          } else {
            setLogoURL(null);
          }
        }
      } catch (error) {
        console.error("Failed to fetch logo:", error);
        setLogoURL(null);
      }
    };
  
    fetchLogo();
  }, [groupId]);
  
  // Watch for changes in localStorage and update groupId accordingly
  useEffect(() => {
    const handleStorageChange = () => {
      const newGroupId = localStorage.getItem("group_id");
      if (newGroupId !== groupId) {
        setGroupId(newGroupId);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [groupId]);

  const updateLogo = (newURL) => {
    setLogoURL(newURL);
  };

  return (
    <LogoContext.Provider value={{ logoURL, updateLogo }}>
      {children}
    </LogoContext.Provider>
  );
};

export const useLogo = () => useContext(LogoContext);
