// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
// // Anaytics dashboard components
import SalesByCountry from "../dashboards/analytics/components/SalesByCountry";

const Metrics = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} style={{ height: "80vh" }}>
        <Grid container>
          <SalesByCountry />
        </Grid>
      </MDBox>
      <div style={{ height: "100%" }}></div>
      <Footer />
    </DashboardLayout>
  );
};

export default Metrics;
