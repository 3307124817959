import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Modal,
  IconButton,
  Button,
} from "@mui/material";
import MDButton from "../../../components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_ROUTES from "../../../config/apiConfig";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";

const Card2 = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      //console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const [fileIds, setFileIds] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [loadingSend, setLoadingSend] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const userID = localStorage.getItem("group_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_ROUTES.SETTING_AI_GET_DATA_API_1ST, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ group_id: userID }),
        });
        const result = await response.json();
        if (result.success && result.data.fileIds) {
          console.log(result.data.fileIds.length);

          setFileIds(result.data.fileIds);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFileName(file.name);
    }
  };

  const handleSend = async () => {
    const group_id = localStorage.getItem("group_id");

    if (!group_id) {
      toast.error("Group ID not found in localStorage");
      return;
    }

    const fileInput = document.getElementById("file-upload");
    if (!fileInput.files.length) {
      toast.error("No file selected");
      return;
    }

    const file = fileInput.files[0];
    if (file.type !== "text/plain") {
      toast.error("Please upload a text file.");
      return;
    }

    setLoadingSend(true);

    try {
      const formData = new FormData();
      formData.append("group_id", group_id);
      formData.append("file", file);

      const response = await fetch(API_ROUTES.SETTING_AI_SEND_DATA_API_2ND, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      const { fileIds } = result;
      const fileIdList = fileIds.join(", ");
      const fileCount = fileIds.length;
      if (result.success) {
        toast.success(translations.settings_AI?.file_uploaded);
        console.log(translations.settings_AI?.file_uploaded);
        // toast.success(`File IDs: ${fileIdList}. Total files: ${fileCount}`);
        setFileIds(fileIds);
      } else {
        toast.error("Failed to send file.");
      }
    } catch (error) {
      toast.error("Error submitting file.");
    } finally {
      setLoadingSend(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = (file_id) => {
    setFileToDelete(file_id);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await fetch(API_ROUTES.SETTING_AI_DELETE_DATA_API_2ND, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ group_id, file_id: fileToDelete }),
      });

      const result = await response.json();
      if (result.success) {
        toast.success(
          translations.setting_AI?.delete_file ||
            " Arquivo apagado com sucesso do chatGPT."
        );
        setFileIds((prevFileIds) =>
          prevFileIds.filter((id) => id !== fileToDelete)
        );
      } else {
        toast.error("Failed to delete file.");
      }
    } catch (error) {
      toast.error("Error deleting file.");
    } finally {
      setDeleteModalOpen(false);
      setFileToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setFileToDelete(null);
  };

  return (
    <>
      <Typography
        variant="body1"
        sx={{ marginBottom: 2 }}
        style={{ fontSize: "14px" }}
      >
        {translations.settings?.number_uploaded_files}: {fileIds.length}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <input
          accept="text/*"
          style={{ display: "none" }}
          id="file-upload"
          type="file"
          onChange={handleLogoChange}
        />
        <label
          htmlFor="file-upload"
          style={{
            width: "100%",
            height: "10rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#f0f0f0",
            cursor: "pointer",
          }}
        >
          <Typography variant="body1" color="textSecondary">
            {translations.settings?.click_to_upload_txt_file}
          </Typography>
        </label>
        {uploadedFileName && (
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            {uploadedFileName}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
          flexDirection: { xs: "column", md: "row" }, // Responsive direction
          gap: 2, // Add space between buttons when in column layout
        }}
      >
        <MDButton
          type="button"
          variant="gradient"
          color="black1"
          style={{ color: "black" }}
          onClick={handleOpen}
        >
          {translations.settings?.list_delete_files}
        </MDButton>
        <MDButton
          type="button"
          variant="gradient"
          color="info"
          onClick={handleSend}
          disabled={loadingSend}
        >
          {loadingSend ? (
            <CircularProgress size={24} color="info" />
          ) : (
            translations.buttons_and_common_words?.send
          )}
        </MDButton>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%", // Set maximum height
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: "auto", // Enable scrolling
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Manage Assistant Files
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Total files are: {fileIds.length}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 4fr 1fr",
              gap: 2,
              mb: 2,
              p: 2,
              bgcolor: "background.default",
              borderRadius: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              #
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              File ID
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold" }}
              style={{ textAlign: "center" }}
            >
              Actions
            </Typography>

            {fileIds.map((fileObj, index) => (
              <React.Fragment key={fileObj.id}>
                <Typography variant="body2">{index + 1}</Typography>
                <Typography variant="body2">{fileObj.id}</Typography>
                <IconButton
                  color="error"
                  onClick={() => handleDelete(fileObj.id)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <DeleteIcon />
                </IconButton>
              </React.Fragment>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <MDButton
              variant="gradient"
              color="black1"
              style={{ color: "black" }}
              onClick={handleClose}
            >
              Close
            </MDButton>
          </Box>
        </Box>
      </Modal>

      <Modal open={deleteModalOpen} onClose={handleCancelDelete}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          {/* <Typography variant="h6" color="error" sx={{ mb: 2 }}>
            Confirm Deletion
          </Typography>     */}
          <Typography variant="body1" sx={{ mb: 2 }}> 
            {translations.buttons_and_common_words?.delete_file_message}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              color="dark"
              onClick={handleCancelDelete}
            >
              {translations.buttons_and_common_words?.cancel} 
            </Button>
            <Button variant="contained" color="error" onClick={confirmDelete}>
             {translations.buttons_and_common_words?.ok} 
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Card2;
