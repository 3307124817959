import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import axios from "axios";
import API_ROUTES from "../../../config/apiConfig";
import { toast } from "react-toastify";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";
import Papa from "papaparse";
import DataTable from "../../../examples/Tables/DataTable";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { Modal, Box, Fade } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Appointment = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      //console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const [followsMe1, setFollowsMe1] = useState(false);
  const [anticipate, setAnticipate] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingMessage, setIsSavingMessage] = useState(false);
  const [noRecords, setNoRecords] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const group_id = parseInt(localStorage.getItem("group_id"), 10);
      try {
        const response = await axios.post(API_ROUTES.APPOINTMENT_API_GET_DATA, {
          group_id,
          table: "bots_appointment",
        });
        const data = response.data;
        // console.log(data.anticipate);
        setIsActive(data.is_active);
        setFollowsMe1(data.is_active === 1);
        setAnticipate(data.anticipate);
        setBody(data.body);
        setImageUrl(data.image);
        setImage(data.image); // Set initial image
        console.log(anticipate);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setNoRecords(true);
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file); // Store the file object
      setImageUrl(URL.createObjectURL(file)); // Optionally, still display the image preview
    }
  };
  const handleImportSample = () => {
    window.open("/import_event_sample.csv", "_blank");
  };
  const handleGenerateMessages = async () => {
    const group_id = parseInt(localStorage.getItem("group_id"), 10);

    if (!selectedDate) {
      toast.error("Please select a date");
      return;
    }

    try {
      await axios.post(
        "https://crmstation.net/laravel/public/api/api_generate_appointment_messages",
        {
          specificDay: selectedDate,
          group_id,
        }
      );
      toast.success(translations.birthday_bot?.generate_message);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error generating messages:", error);
      toast.error("Failed to generate messages");
    }
  };

  const handleViewImportedEvents = () => {
    navigate("/appointment_imported_events");
  };

  const handleSave = async () => {
    setIsSaving(true);
    const group_id = parseInt(localStorage.getItem("group_id"), 10);
    const anticipateInt = parseInt(anticipate, 10);

    const postData = {
      table: "bots_appointment",
      group_id,
      is_active: followsMe1 ? 1 : 0,
      anticipate: anticipateInt,
    };

    try {
      await axios.post(API_ROUTES.DATA_SEND_BIRTHDAY, postData);
      toast.success("Data saved successfully");
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data");
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveMessage = async () => {
    setIsSavingMessage(true);
    const group_id = parseInt(localStorage.getItem("group_id"), 10);
    const formData = new FormData();

    formData.append("table", "bots_appointment");
    formData.append("group_id", group_id);
    formData.append("body", body);

    if (image) {
      formData.append("image", image); // Append the actual file
    }

    try {
      await axios.post(API_ROUTES.DATA_SEND_BIRTHDAY_IMG_BODY, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Message saved successfully");
    } catch (error) {
      console.error("Error saving message:", error);
      toast.error("Failed to save message");
    } finally {
      setIsSavingMessage(false);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [events, setEvents] = useState([]); // Store CSV data
  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
  });
  const [isTableVisible, setIsTableVisible] = useState(false); // Control for table visibility
  const fileInputRef = useRef(null); // Reference for file input

  // Fetch translations on language change
  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };
    fetchTranslations();
  }, [language]);

  // Parse CSV rows into event data
  const parseCsvRow = (csvRow, index) => {
    return {
      index: index,
      name: csvRow[0],
      mobile: csvRow[1],
      event_date: csvRow[2],
      event_hour: csvRow[3],
    };
  };

  // Parse the CSV file
  const parseCsvFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      Papa.parse(data, {
        complete: (result) => {
          const csvRows = result.data;
          if (csvRows.length > 1) {
            const newEvents = [];
            for (let i = 1; i < csvRows.length; i++) {
              const row = parseCsvRow(csvRows[i], i - 1); // Populate with index
              newEvents.push(row);
            }
            setEvents(newEvents); // Set the events in the state
            setIsTableVisible(true); // Show the table
          }
        },
      });
    };
    reader.onerror = (ex) => {
      console.log("parseCsvFile error", ex);
    };
    reader.readAsText(file);
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      parseCsvFile(file);
    }
  };

  // Handle click on the "Import" button
  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger file input click
    }
  };

  // Handle delete button click to remove row
  const handleDeleteClick = (index) => {
    const updatedEvents = events.filter((event, i) => i !== index);
    setEvents(updatedEvents); // Update the state with the remaining events
  };

  // Set up columns and rows dynamically when events data changes
  useEffect(() => {
    if (events.length > 0) {
      setTableData({
        columns: [
          { Header: "Name", accessor: "name", width: "20%" },
          { Header: "Mobile", accessor: "mobile", width: "20%" },
          { Header: "Event Date", accessor: "event_date", width: "20%" },
          { Header: "Event Hour", accessor: "event_hour", width: "20%" },
          {
            Header: "Actions",
            accessor: "actions",
            width: "20%",
            Cell: ({ row }) => (
              <IconButton
                color="error"
                onClick={() => handleDeleteClick(row.index)}
              >
                <ClearIcon />
              </IconButton>
            ),
          },
        ],
        rows: events.map((event, index) => ({
          ...event,
          actions: (
            <IconButton color="error" onClick={() => handleDeleteClick(index)}>
              <ClearIcon />
            </IconButton>
          ),
        })),
      });
    }
  }, [events]);

  const saveTableData = async () => {
    const group_id = localStorage.getItem("group_id");
    const data = tableData.rows;

    try {
      const response = await axios.post(API_ROUTES.API_IMPORT_CSV_DATA, {
        group_id,
        data,
      });

      if (response.data.data === "success") {
        toast.success(translations.bots_reports?.file_imported_success);
        setIsTableVisible(false);
      } else {
        toast.error("Failed to import data.");
      }
    } catch (error) {
      toast.error("An error occurred while importing data.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {noRecords && (
          <Grid item xs={12} sx={{ marginBottom: "1.5rem" }}>
            <Card>
              <MDBox p={2} display="flex" justifyContent="center">
                <MDTypography variant="body1" color="textSecondary">
                  No records found.
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        )}

        {/* Import button will be shown only when there is no data */}
        {!isTableVisible && (
          <MDBox display="flex" justifyContent="flex-end" p={2} mr={2}>
            <MDButton
              variant="gradient"
              color="black1"
              onClick={() => setIsModalOpen(true)}
              sx={{ ml: 1 }}
              style={{ color: "black" }}
            >
              {translations.bots_reports?.generate_message_date ||
                "GERAR MANUALMENTE"}
            </MDButton>
            <MDButton
              onClick={handleViewImportedEvents}
              sx={{ ml: 1 }}
              style={{ backgroundColor: "#197802", color: "#fff" }}
            >
              {translations.bots_reports?.imported_event}
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={handleImportSample}
              sx={{ ml: 1 }}
            >
              {translations.bots_reports?.import_sample_file}
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleImportClick}
              sx={{ ml: 1 }}
            >
              {translations.bots_reports?.import_excel_file}
            </MDButton>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".csv"
              onChange={handleFileChange}
            />
          </MDBox>
        )}
        {/* Modal for Generate Messages */}
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          closeAfterTransition
        >
          <Fade in={isModalOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: "12px", // Border radius for rounded corners
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow
                p: 4,
                outline: "none", // Removes the default modal outline
                transition: "all 0.3s ease", // Smooth transition
              }}
            >
              <MDBox mb={2}>
                <input
                  type="date"
                  id="date-picker"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "8px", // Rounded input
                    border: "1px solid #ccc", // Light border
                    fontSize: "16px",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Small shadow
                  }}
                />
              </MDBox>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleGenerateMessages}
                  sx={{
                    borderRadius: "8px", // Rounded button
                    padding: "8px 16px",
                    transition: "background-color 0.3s ease", // Smooth button hover transition
                  }}
                >
                  {translations.bots_reports?.generate_message_date ||
                    "GERAR MANUALMENTE"}
                </MDButton>
              </MDBox>
            </Box>
          </Fade>
        </Modal>

        {/* DataTable will be shown when tableData is available */}
        {isTableVisible && (
          <>
            <MDBox style={{ display: "flex", justifyContent: "end" }}>
              <MDButton
                variant="gradient"
                color="black1"
                style={{ marginBottom: "1rem", color:"black" }}
                onClick={saveTableData}
              >
                {translations.bots_reports?.import_now}
              </MDButton>
            </MDBox>
            <DataTable table={tableData} canSearch />
          </>
        )}

        {!isTableVisible && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MDBox
                  p={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MDTypography variant="h6">
                    {translations.appointment_bot?.appoitment_bot}{" "}
                    {followsMe1 ? "ON" : "OFF"}
                  </MDTypography>
                  <Switch
                    checked={followsMe1}
                    onChange={() => setFollowsMe1(!followsMe1)}
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card style={{ height: "100%" }}>
                <MDBox
                  p={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <MDTypography variant="h6">
                    {translations.appointment_bot?.antecipation_in_days}
                  </MDTypography>
                  <TextField
                    type="number"
                    variant="outlined"
                    value={anticipate}
                    onChange={(e) => setAnticipate(e.target.value)}
                    sx={{ width: "5rem", marginLeft: "1rem" }}
                  />
                  {!noRecords && (
                    <MDBox display="flex" justifyContent="flex-end" p={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={handleSave}
                        disabled={isSaving}
                      >
                        {isSaving
                          ? "Saving..."
                          : translations.buttons_and_common_words?.save}
                      </MDButton>
                    </MDBox>
                  )}
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <MDBox p={2}>
                  <MDTypography
                    variant="h6"
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontSize: isSmallScreen ? "1rem" : "1.25rem",
                    }}
                  >
                    {translations.birthday_bot?.type_message_choose_image}
                  </MDTypography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={15}
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        sx={{ width: "100%", overflow: "auto" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="image-upload"
                        type="file"
                        onChange={handleImageUpload}
                      />
                      <label
                        htmlFor="image-upload"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#f0f0f0",
                          cursor: "pointer",
                          height: image || imageUrl ? "auto" : "10rem",
                          border: "1px dashed gray",
                        }}
                      >
                        {image || imageUrl ? (
                          <img
                            src={imageUrl || URL.createObjectURL(image)}
                            alt="Uploaded"
                            style={{ maxHeight: "17rem", maxWidth: "100%" }}
                          />
                        ) : (
                          <MDTypography variant="body1" color="textSecondary">
                                                   {translations.birthday_bot?.upload_img}

                          </MDTypography>
                        )}
                      </label>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDTypography variant="body2">
                    {translations.appointment_bot?.placeholders}
                  </MDTypography>
                </MDBox>
                {!noRecords && (
                  <MDBox display="flex" justifyContent="flex-end" p={2}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={handleSaveMessage}
                      disabled={isSavingMessage}
                    >
                      {isSavingMessage
                        ? "Saving..."
                        : translations.buttons_and_common_words?.save}
                    </MDButton>
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Appointment;
