// In AreaCodeContext.js
import { createContext, useState } from 'react';

const AreaCodeContext = createContext();

export function AreaCodeProvider({ children }) {
  const [areaCodes, setAreaCodes] = useState({ all: false, selected: [] });
  const [allTagsSelected, setAllTagsSelected] = useState(false);

  return (
    <AreaCodeContext.Provider value={{ areaCodes, setAreaCodes, allTagsSelected, setAllTagsSelected }}>
      {children}
    </AreaCodeContext.Provider>
  );
}

export default AreaCodeContext;
