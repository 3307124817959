import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import { useEffect, useContext, useState } from "react";
import Select from "react-select";
import API_ROUTES from "../../../../../config/apiConfig";
import DataTable from "../../../../../examples/Tables/DataTable";
import TagsContext from "../../../../../context/TagsContext";
import AreaCodeContext from "../../../../../context/AreaCodeContext";
import CircularProgress from "@mui/material/CircularProgress";
import { useGlobalData } from "../../../../../context/GlobalDataContext"; // Import GlobalDataContext

function Socials() {
  const {
    selectedTags,
    setSelectedTags,
    setAllTagsSelected,
    allTagsSelected,
    userData,
    setUserData,
  } = useContext(TagsContext);
  const { areaCodes } = useContext(AreaCodeContext);
  const { dispatch } = useGlobalData(); // Use GlobalDataContext

  const [tags, setTags] = useState([{ value: 0, label: "All Tags" }]);
  const [total, setTotal] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const group_id = localStorage.getItem("group_id");

  const fetchTagsAndUsers = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `http://crmstation.net/laravel/public/api/api_users_by_group?group_id=${group_id}`
      );
      const data = await response.json();

      if (data.status === "success") {
        const fetchedTags = data.tags.map((tag) => ({
          value: tag.id,
          label: tag.tag,
        }));
        setTags((prevTags) => [...prevTags, ...fetchedTags]);
        setTotal(data.total);

        if (areaCodes.all || allTagsSelected) {
          const allTagsOption = { value: 0, label: "All Tags" };
          setSelectedTags([allTagsOption]);
          setAllTagsSelected(true);
          updateGlobalTags([allTagsOption]); // Update global context
        }
      }
    } catch (error) {
      console.error("Error fetching tags and users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTagsAndUsers();
  }, [group_id]);

  const handleTagChange = (selectedOptions) => {
    const allTagsOption = selectedOptions.find((option) => option.value === 0);
    if (allTagsOption) {
      setSelectedTags([allTagsOption]);
      setAllTagsSelected(true);
      updateGlobalTags([allTagsOption]); // Update global context
    } else {
      setSelectedTags(selectedOptions);
      setAllTagsSelected(false);
      updateGlobalTags(selectedOptions); // Update global context
    }
  };

  const sendTagIds = async (tagsToSend = selectedTags) => {
    const tagIds = tagsToSend.some((tag) => tag.value === 0)
      ? JSON.stringify([0])
      : JSON.stringify(tagsToSend.map((tag) => tag.value));

    const payload = {
      group_id: group_id,
      tag_ids: tagIds,
    };

    try {
      const response = await fetch(API_ROUTES.DATA_SEND_CREATE_NEW_STEP_3, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      const transformedUserData = result.users.map((user) => ({
        id: user.user_id,
        name: user.name,
        mobile: user.mobile,
        dob: new Date(
          `${user.dob_year}-${user.dob_month}-${user.dob_day}`
        ).toLocaleDateString("en-GB"),
        company: user.company,
        lead: user.lead,
        created_at: new Date(user.created_at).toLocaleDateString("en-GB"),
        updated_at: new Date(user.updated_at).toLocaleDateString("en-GB"),
      }));

      setUserData(transformedUserData);
    } catch (error) {
      console.error("Error sending tag IDs:", error);
    }
  };

  useEffect(() => {
    if (selectedTags.length > 0) {
      sendTagIds();
    }
  }, [selectedTags]);

  const updateGlobalTags = (tags) => {
    const tagIds = tags.some((tag) => tag.value === 0)
      ? [0]
      : tags.map((tag) => tag.value);
    dispatch({ type: "SET_TOTAL_TAGS", payload: tagIds });
  };

  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Mobile", accessor: "mobile" },
    { Header: "DOB", accessor: "dob" },
    { Header: "Company", accessor: "company" },
    { Header: "Lead", accessor: "lead" },
    { Header: "Created at", accessor: "created_at" },
    { Header: "Updated at", accessor: "updated_at" },
  ];

  const tableData = { columns, rows: userData };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        TAGs (Categories)
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid item xs={12}>
          <Select
            isMulti
            options={tags}
            value={selectedTags}
            onChange={handleTagChange}
            placeholder="Select Tags"
          />
        </Grid>
        {isLoading ? (
          <MDBox display="flex" justifyContent="center" mt={3}>
            <CircularProgress />
          </MDBox>
        ) : (
          <MDBox mt={3}>
            <DataTable
              entriesPerPage={{
                defaultValue: 10,
                entries: ["5", "10", "15", "20", "25"],
              }}
              canSearch
              showTotalEntries
              table={tableData}
              pagination={{ variant: "gradient", color: "info" }}
              isSorted
              noEndBorder
            />
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

Socials.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Socials;
