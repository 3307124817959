import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../../../context/authContext"; // Adjust the import path according to your project structure
import loadTranslations from "../../../../utils/loadTranslations";
import { useLanguageState } from "../../../../context/LanguageContext";
import logo from "../../../../assets/images/logo-ct - Copy.png";
import MDInput from "../../../../components/MDInput";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import { toast } from "react-toastify";

function Basic() {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { login, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("group_id") && localStorage.getItem("name")) {
      window.location.href = "/home";
    }
  }, [navigate]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async (event) => {
    event.preventDefault();
    const result = await login(name, password);

    if (result.success) {
      toast.success(translations.login?.login || "Login com sucesso");
      window.location.href = "/home"; // Redirect after login
    } else if (result.error) {
      toast.error(result.error);
    }
  };

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const [content, setContent] = useState("");

  useEffect(() => {
    fetch("https://crmstation.net/laravel/public/api/api_body_content")
      .then((response) => response.text())
      .then((data) => {
        setContent(data);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
      });
  }, []);


  return (
    <>
      {/* Navbar with form */}
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#701bb2",
          color: "white",
        }}
      >
        <Toolbar
          style={{
            backgroundColor: "#701bb2",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          {/* Left side: Logo */}
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {/* Replace this with your actual logo */}
            <img src={logo} alt="Logo" style={{ height: "40px" }} />
          </Typography>

          {/* Right side: Sign-in form */}
          <Box
            component="form"
            onSubmit={handleLogin}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end", // Align items to the right side
              gap: 2, // Adjust spacing between the elements
              flexWrap: "nowrap", // Prevent elements from wrapping onto a new line
              minWidth: "fit-content", // Ensure the form has enough space to display everything
            }}
          >
            {/* Name input field */}
            <MDInput
              type="text"
              fullWidth={false}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={translations.signin?.user || "Usuário"} // Placeholder for the user
              sx={{
                marginRight: 1,
                color: "white",
                "& .MuiInputLabel-root": { color: "white" }, // Label color
                "& .MuiInputBase-input::placeholder": { color: "white" }, // Placeholder color
              }}
              InputLabelProps={{
                style: { color: "white" }, // Label color
              }}
              inputProps={{
                style: { color: "white" }, // Input text color
              }}
            />

            {/* Password input field */}
            <MDInput
              type="password"
              fullWidth={false}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={translations.signin?.password || "Senha"} // Placeholder for the password
              sx={{
                marginRight: 1,
                color: "white",
                "& .MuiInputLabel-root": { color: "white" }, // Label color
                "& .MuiInputBase-input::placeholder": { color: "white" }, // Placeholder color
              }}
              InputLabelProps={{
                style: { color: "white" }, // Label color
              }}
              inputProps={{
                style: { color: "white" }, // Input text color
              }}
            />

            {/* Remember me switch and label */}
            <MDBox display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  ml: 1,
                  color: "white",
                }}
                style={{ color: "white" }}
              >
                {translations.signin1?.remember_me}
              </MDTypography>
            </MDBox>

            {/* Sign in button */}
            <MDButton
              variant="gradient"
              color="info"
              size="large"
              type="submit"
              disabled={loading}
            >
              {loading ? translations.signin1?.signing_in || "Acessando..." : translations.signin?.signin}
            </MDButton>
          </Box>
        </Toolbar>
      </AppBar>

      <div id="contentContainer">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </>
  );
}

export default Basic;
