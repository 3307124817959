import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import axios from "axios";
import API_ROUTES from "../../../config/apiConfig";
import { toast } from "react-toastify";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";
import { Modal, Box, Fade } from "@mui/material";

const BirthDay = () => {
  const [followsMe1, setFollowsMe1] = useState(false);
  const [anticipate, setAnticipate] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingMessage, setIsSavingMessage] = useState(false);
  const [noRecords, setNoRecords] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const group_id = parseInt(localStorage.getItem("group_id"), 10);
      try {
        const response = await axios.post(API_ROUTES.APPOINTMENT_API_GET_DATA, {
          group_id,
          table: "bots_birthday",
        });
        const data = response.data;
        setIsActive(data.is_active);
        setFollowsMe1(data.is_active === 1);
        setAnticipate(data.anticipate);
        setBody(data.body);
        setImageUrl(data.image);
        setImage(data.image); // Set initial image
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setNoRecords(true);
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file); // Store the file object
      setImageUrl(URL.createObjectURL(file)); // Optionally, still display the image preview
    }
  };

  const handleGenerateMessages = async () => {
    const group_id = parseInt(localStorage.getItem("group_id"), 10);

    if (!selectedDate) {
      toast.error("Please select a date");
      return;
    }

    try {
      const response = await axios.post(
        "https://crmstation.net/laravel/public/api/api_generate_birthday_messages",
        {
          specificDay: selectedDate,
          group_id,
        }
      );
      if (response.data.status == true) {
        toast.success(translations.birthday_bot?.generate_message);
      } else {
        toast.error(response.data.message);
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error generating messages:", error);
      toast.error("Failed to generate messages");
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    const group_id = parseInt(localStorage.getItem("group_id"), 10);
    const anticipateInt = parseInt(anticipate, 10);

    const postData = {
      table: "bots_birthday",
      group_id,
      // is_active: followsMe1 ? 1 : 0,
      anticipate: anticipateInt,
    };

    try {
      await axios.post(API_ROUTES.DATA_SEND_BIRTHDAY, postData);
      toast.success(translations.birthday_bot?.success_msg);
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data");
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveMessage = async () => {
    setIsSavingMessage(true);
    const group_id = parseInt(localStorage.getItem("group_id"), 10);
    const formData = new FormData();

    formData.append("table", "bots_birthday");
    formData.append("group_id", group_id);
    formData.append("body", body);

    if (image) {
      formData.append("image", image); // Append the actual file
    }

    try {
      const response = await axios.post(
        API_ROUTES.DATA_SEND_BIRTHDAY_IMG_BODY,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // if (response.data.status == true) {
      //   toast.success("1111111111111"+response.data.message);
      // }else{
      //   toast.error("1222"+response.data.message);
      // }
      toast.success(translations.birthday_bot?.data_save);
    } catch (error) {
      console.error("Error saving message:", error);
      toast.error("Failed to save message");
    } finally {
      setIsSavingMessage(false);
    }
  };

  const handleSwitchChange = async (event) => {
    const newIsActive = event.target.checked;
    setFollowsMe1(newIsActive);

    const group_id = parseInt(localStorage.getItem("group_id"), 10);
    const postData = {
      is_active: newIsActive ? 1 : 0,
      group_id,
      table: "bots_birthday",
    };

    try {
      await axios.post(API_ROUTES.API_BOT_UPDATE_ACTIVE, postData);
      if (newIsActive) {
        toast.success(translations.birthday_bot?.activated_success);
      } else {
        toast.success("Deactivated successfully");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status");
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {noRecords && (
          <Grid item xs={12} sx={{ marginBottom: "1.5rem" }}>
            <Card>
              <MDBox p={2} display="flex" justifyContent="center">
                <MDTypography variant="body1" color="textSecondary">
                  No records found.
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        )}

        <MDBox display="flex" justifyContent="flex-end" p={2} mr={2}>
          <MDButton
            variant="gradient"
            color="black1"
            onClick={() => setIsModalOpen(true)}
            sx={{ ml: 1 }}
            style={{ color: "black" }}
          >
            {translations.bots_reports?.generate_message_date ||
              "GERAR MANUALMENTE"}
          </MDButton>
        </MDBox>

        {/* Modal for Generate Messages */}
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          closeAfterTransition
        >
          <Fade in={isModalOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: "12px", // Border radius for rounded corners
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow
                p: 4,
                outline: "none", // Removes the default modal outline
                transition: "all 0.3s ease", // Smooth transition
              }}
            >
              <MDBox mb={2}>
                <input
                  type="date"
                  id="date-picker"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "8px", // Rounded input
                    border: "1px solid #ccc", // Light border
                    fontSize: "16px",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Small shadow
                  }}
                />
              </MDBox>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleGenerateMessages}
                  sx={{
                    borderRadius: "8px", // Rounded button
                    padding: "8px 16px",
                    transition: "background-color 0.3s ease", // Smooth button hover transition
                  }}
                >
                  {translations.bots_reports?.generate_message_date ||
                    "GERAR MANUALMENTE"}
                </MDButton>
              </MDBox>
            </Box>
          </Fade>
        </Modal>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDBox
                p={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <MDTypography variant="h6">
                  {translations.birthday_bot?.birthday_bot}{" "}
                  {followsMe1 ? "ON" : "OFF"}
                </MDTypography>
                <Switch
                  checked={followsMe1}
                  onChange={handleSwitchChange} // Call the API on switch change
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card style={{ height: "100%" }}>
              <MDBox
                p={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <MDTypography variant="h6">
                  {translations.birthday_bot?.antecipation_in_days}
                </MDTypography>
                <TextField
                  type="number"
                  variant="outlined"
                  value={anticipate}
                  onChange={(e) => setAnticipate(e.target.value)}
                  sx={{ width: "5rem", marginLeft: "1rem" }}
                />
                {!noRecords && (
                  <MDBox display="flex" justifyContent="flex-end" p={2}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={handleSave}
                      disabled={isSaving}
                    >
                      {isSaving
                        ? "Saving..."
                        : translations.buttons_and_common_words?.save}
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox p={2}>
                <MDTypography
                  variant="h6"
                  sx={{
                    mt: 2,
                    mb: 1,
                    fontSize: isSmallScreen ? "1rem" : "1.25rem",
                  }}
                >
                  {translations.birthday_bot?.type_message_choose_image}
                </MDTypography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      multiline
                      rows={15}
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                      sx={{ width: "100%", overflow: "auto" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="image-upload"
                      type="file"
                      onChange={handleImageUpload}
                    />
                    <label
                      htmlFor="image-upload"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#f0f0f0",
                        cursor: "pointer",
                        height: image || imageUrl ? "auto" : "10rem",
                        border: "1px dashed gray",
                      }}
                    >
                      {image || imageUrl ? (
                        <img
                          src={imageUrl || URL.createObjectURL(image)}
                          alt="Uploaded"
                          style={{ maxHeight: "17rem", maxWidth: "100%" }}
                        />
                      ) : (
                        <MDTypography variant="body1" color="textSecondary">
                          {translations.birthday_bot?.upload_img}
                        </MDTypography>
                      )}
                    </label>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <MDTypography variant="body2">
                  {translations.birthday_bot?.placeholders}
                </MDTypography>
              </MDBox>
              {!noRecords && (
                <MDBox display="flex" justifyContent="flex-end" p={2}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={handleSaveMessage}
                    disabled={isSavingMessage}
                  >
                    {isSavingMessage
                      ? "Saving..."
                      : translations.buttons_and_common_words?.save}
                  </MDButton>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default BirthDay;
