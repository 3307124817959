import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

function BookingCard({ image, title, link }) {
  const handleCardClick = () => {
    window.open(link, "_blank");
  };

  return (
    <Card
      sx={{
        "&:hover .card-header": {
          transform: "translate3d(0, -20px, 0)",
        },
      }}
      onClick={handleCardClick}
      style={{ cursor: "pointer" }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        style={{ marginLeft: "22px", marginRight: "22px" }}
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <MDBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="0"
          sx={{
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox textAlign="center" px={3}>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 1, mb: 1 }} style={{ fontSize: "9px", marginTop: "0px" }}>
          {title}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

BookingCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default BookingCard;
