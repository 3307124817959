import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import axios from "axios";

const ConfirmationDialog = ({
  open,
  handleClose,
  handleConfirm,
  dialogText,
  confirmText,
  cancelText,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{dialogText}</DialogTitle>
    <DialogContent>
      <DialogContentText>{dialogText}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        {cancelText}
      </Button>
      <Button onClick={handleConfirm} color="error" autoFocus>
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
