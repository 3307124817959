import React, { useEffect, useState } from "react";
import MDBox from "../../components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import { Box, Card, Grid, Typography } from "@mui/material";
import MDButton from "../../components/MDButton";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";

const FormsSurvey = () => {
  const handleOpenFlowBuilder = () => {
    window.open("https://myforms.crmstation.net", "_blank");
  };

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={2}>
          <Grid container>
            <Card>
              <Box p={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" color="textSecondary">
                    {translations.forms_survey?.forms_survey}
                  </Typography>
                </Box>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleOpenFlowBuilder}
                  style={{ marginTop: "1.5rem" }}
                >
                  Open {translations.forms_survey?.forms_survey}
                </MDButton>
              </Box>
            </Card>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default FormsSurvey;
