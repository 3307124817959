import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Box, Typography } from "@mui/material";
import MDBox from "../../../components/MDBox";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";
import BookingCard from "../../../examples/Cards/BookingCard";

// Images
import thumbnail from "../../../assets/images/thumbnail.jpg";
import axios from "axios";

const SettingsAI = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      //console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const headings = [
    translations.settings?.set_instructions,
    translations.settings?.send_training_file,
    translations.settings?.test_ai_using_file,
    translations.settings?.greetings_message,
  ];


  
 const [videoData, setVideoData] = useState([]);

 useEffect(() => {
   axios
     .get(
       "https://crmstation.net/laravel/public/api/api_get_videos?page=ai_settings"
     )
     .then((response) => {
       setVideoData(response.data);
     })
     .catch((error) => {
       console.error("There was an error fetching the video data!", error);
     });
 }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={0}>
        <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description} // Assuming the description is part of the API response
                      price={video.price} // Assuming the price is part of the API response
                      location={video.location} // Assuming the location is part of the API response
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {/* <MDBox textAlign="center" mt={3}>
                  <h3>Video not found</h3>
                </MDBox> */}
              </Grid>
            )}
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {headings.map((heading, index) => (
              <Grid item xs={12} md={6} lg={6} key={index}>
                <Box>
                  <Card sx={{ marginTop: 3, paddingTop: 2 }}>
                    <Typography variant="h6" sx={{ mb: 1, ml: 2 }}>
                      {heading}
                    </Typography>
                    <CardContent>
                      {index === 0 ? (
                        <Card1 />
                      ) : index === 1 ? (
                        <Card2 />
                      ) : index === 2 ? (
                        <Card3 />
                      ) : index === 3 ? (
                        <Card4 />
                      ) : (
                        <Typography variant="body2">
                          Content for card {index + 1}.
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </MDBox>
      <div style={{ height: "100%" }}></div>
      <Footer />
    </DashboardLayout>
  );
};

export default SettingsAI;
