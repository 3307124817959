import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "../../examples/Tables/DataTable";
import {
  Card,
  CardHeader,
  Button,
  CardContent,
  MenuItem,
  Select,
  Modal,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MDButton from "../../components/MDButton";
import EditIcon from "../../assets/images/edit-icon.png";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import API_ROUTES from "../../config/apiConfig";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { toast } from "react-toastify";
import MDTypography from "../../components/MDTypography";
import Tooltip from "@mui/material/Tooltip";

const Column = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // Dialog state
  const [deleteDialogConfirmOpen, setDeleteDialogConfirmOpen] = useState(false); // Dialog state
  const [tags, setTags] = useState([]);
  const [previousTagId, setPreviousTagId] = useState(null);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [tagToDelete, setTagToDelete] = useState(null); // Tag to delete

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const fetchData = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.get(
        `${API_ROUTES.COLUMN_FETCH_DATA}?group_id=${group_id}`
      );

      const tagsData = response.data.data.map((tag) => ({
        id: tag.id,
        tag: tag.column,
        total_users: tag.total_users,
        created_at: formatDate(tag.created_at),
        updated_at: formatDate(tag.updated_at),
        actions: (
          <div style={{ display: "flex", gap: "5px" }}>
            {/* <Tooltip title={translations.column11?.migrate || "Migrar"}>
              <IconButton
                aria-label="move"
                style={{ color: "#f72585" }}
                onClick={() => handleOpenModal(tag.id)}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip title={translations.column11?.edit || "Editar"}>
              <NavLink to={`/edit_column/${tag.id}`} style={{ textDecoration: "none" }}>
                <IconButton color="primary" aria-label="edit" style={{color:"#f72585"}}>
                  <img src={EditIcon} alt="" style={{ width: "20px" }} />
                </IconButton>
              </NavLink>
            </Tooltip>
            <Tooltip title={translations.column11?.delete || "Apagar"}>
              <IconButton 
                aria-label="delete" 
                style={{ color: "#f72585" }} 
                onClick={() => handleDeleteClick(tag.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      }));

      setTags(response.data.data);

      setTableData({
        columns: [
          { Header: "ID", accessor: "id", width: "20%" },
          { Header: translations.create_column?.stage || "Etapa", accessor: "tag", width: "30%" },
          {
            Header: translations.tag?.total_subs || "Total de Assinantes",
            accessor: "total_users",
            width: "30%",
          },
          
          {
            Header: translations.buttons_and_common_words?.actions || "Ações",
            accessor: "actions",
            width: "15%",
          },
        ],
        rows: tagsData,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = (tagId) => {
    setPreviousTagId(tagId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTagId(null);
  };

  const handleMoveUsers = async () => {
    try {
      const group_id = localStorage.getItem("group_id");

      const response = await axios.post(API_ROUTES.API_MOVE_USER_COLUMN, {
        group_id,
        previous_column_id: previousTagId,
        selected_column_id: selectedTagId,
      });

      fetchData(); // Refresh the dataz
      handleCloseModal();

      toast.success(response.data.message || "Users moved successfully!");
    } catch (error) {
      console.error("Error moving users:", error);
      toast.error("Failed to move users. Please try again.");
    }
  };


  const handleDeleteClick = (tagId) => {
    console.log("Delete triggered");
    
    // Find the tag by its ID
    const tag = tags.find((t) => t.id === tagId);
  
    if (tag) {
      if (tag.total_users === 0) {
        // If total_users is 0, delete directly
        setTagToDelete(tagId);
        setDeleteDialogConfirmOpen(true);
        
      } else {
        // Otherwise, show the confirmation dialog
        setTagToDelete(tagId);
        setDeleteDialogOpen(true);
      }
    }
  };
  
  const handleDeleteConfirm = async () => {
    try {
      console.log(tagToDelete);
      
      await axios.post(`${API_ROUTES.COLUMN_DELETE}`, {
        id:tagToDelete
      });
      fetchData(); // Refresh the data
      setDeleteDialogConfirmOpen(false);
      toast.success(translations.column11?.delete_success || "Tag deleted successfully!");
    } catch (error) {
      console.error("Error deleting tag:", error);
      toast.error(translations.column11?.delete_failure || "Failed to delete tag. Please try again.");
    }
  };
  

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteDialogConfirmOpen(false)
    setTagToDelete(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={translations.leads?.manage_stages}
                action={
                  <NavLink
                    to={`/create_column`}
                    style={{ textDecoration: "none" }}
                  >
                    <MDButton
                      variant="gradient"
                      style={{
                        backgroundColor: "#480ca8",
                        color: "white",
                        marginLeft: "8px",
                      }}
                      startIcon={<AddIcon />}
                    >
                      {translations.create_column?.create_column}
                    </MDButton>
                  </NavLink>
                }
              />
              <DataTable table={tableData} canSearch />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <MDTypography
              variant="h6"
              id="message_container1"
            >
              {translations.column11?.destination_tag}
            </MDTypography>
          <Select
            fullWidth
            value={selectedTagId}
            onChange={(e) => setSelectedTagId(e.target.value)}
            displayEmpty
            sx={{ mt: 2, p:1 }}
          >
            <MenuItem value="" disabled>
              Select a {translations.edit_subscribers?.tags}
            </MenuItem>
            {tags
              .filter((tag) => tag.id !== previousTagId) // Exclude the previous tag
              .map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.column}
                </MenuItem>
              ))}
          </Select>
          <Box
            sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <MDButton
              onClick={handleMoveUsers}
              variant="gradient"
              color="dark"
              startIcon={<ArrowRightAltIcon />}
              disabled={!selectedTagId} // Disable button if no tag selected
            >
              {translations.column11?.migrate}
            </MDButton>
            <MDButton
              onClick={handleCloseModal}
              variant="outlined"
              color="secondary"
            >
              {translations.buttons_and_common_words?.cancel}
            </MDButton>
          </Box>
        </Box>
      </Modal>

      <Dialog
  open={deleteDialogConfirmOpen} // Should be connected to the correct state
  onClose={handleDeleteCancel}
  aria-labelledby="delete-dialog-title"
  aria-describedby="delete-dialog-description"
>
  <DialogContent>
    <DialogContentText id="delete-dialog-description">
      {translations.buttons_and_common_words?.are_you_sure}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <MDButton onClick={handleDeleteCancel} color="black1" variant="gradient" style={{color:"black"}}>
      {translations.buttons_and_common_words?.cancel}
    </MDButton>
    <MDButton onClick={handleDeleteConfirm} color="info" autoFocus variant="gradient">
      {translations.buttons_and_common_words?.ok}
    </MDButton>
  </DialogActions>
</Dialog>


      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {translations.column11?.delete_text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleDeleteCancel} color="black1" variant="gradient" style={{color:"black"}}>
            {translations.buttons_and_common_words?.cancel}
          </MDButton>
          <MDButton onClick={handleDeleteCancel} color="info" autoFocus variant="gradient">
            {translations.buttons_and_common_words?.ok}
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default Column;
