import React, { createContext, useContext, useReducer } from 'react';

const LanguageContext = createContext();

const languageReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload };
    default:
      return state;
  }
};

const LanguageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(languageReducer, {
    language: localStorage.getItem('language') || 'portuguese', // Default to English
  });

  return (
    <LanguageContext.Provider value={{ state, dispatch }}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguageState = () => useContext(LanguageContext).state;
const useLanguageDispatch = () => useContext(LanguageContext).dispatch;

export { LanguageProvider, useLanguageState, useLanguageDispatch };
