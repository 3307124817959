import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import API_ROUTES from "../../config/apiConfig";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";
import { toast } from "react-toastify";
import { default as Select1 } from "react-select";

const fetchImage = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true); // Image exists
    img.onerror = () => resolve(false); // Image does not exist
    img.src = url;
  });
};

const getAvatarUrl = async (item) => {
  let url;

  if (item.mobile) {
    url = `https://crmstation.net/avatar/${item.mobile}.jpg`;
    if (await fetchImage(url)) {
      console.log("Using mobile URL:", url);
      return url;
    }

    url = `https://crmstation.net/avatar/${item.mobile}.png`;
    if (await fetchImage(url)) {
      console.log("Using mobile URL:", url);
      return url;
    }

    if (item.mobile.substring(0, 4) > 5530) {
      const number = item.mobile.substring(0, 4) + item.mobile.substring(5);
      url = `https://crmstation.net/avatar/${number}.jpg`;
      if (await fetchImage(url)) {
        console.log("Using formatted mobile number URL:", url);
        return url;
      }

      const number1 = item.mobile.substring(0, 4) + item.mobile.substring(5);
      url = `https://crmstation.net/avatar/${number1}.png`;
      if (await fetchImage(url)) {
        console.log("Using formatted mobile number URL:", url);
        return url;
      }
    }
  }

  console.log("No valid image found, using default placeholder.");
  return "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png";
};

const OpenCards = () => {
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageInput, setPageInput] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    name: "",
    email: "",
    company: "",
    tags: [],
    message: "",
    avatarUrl: "",
    user_id: "",
    operator: "",
    birthday: "",
  });
  const [operatorId, setOperatorId] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const group_id = localStorage.getItem("group_id");
  const [operator, setOperator] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);
  const handleOpenModal = (card) => {
    setModalContent(card);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent({
      name: "",
      email: "",
      company: "",
      tags: [],
      message: "",
      avatarUrl: "",
      user_id: "",
      operator: "",
      birthday: "",
    });
  };

  const groupId = localStorage.getItem("group_id");
  const fetchDataTags = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.get(
        `${API_ROUTES.TAGS_FETCH_DATA}?group_id=${group_id}`
      );
      setTags(response.data.tags);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDataOperator = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.get(
        `${API_ROUTES.EDIT_SUBS_RESPONSABLE}?group_id=${group_id}`
      );
      setOperator(response.data.data);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    fetchDataOperator();
    fetchDataTags();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://crmstation.net/laravel/public/api/api_users_by_group_card`,
        {
          params: {
            group_id: groupId,
            limit: 20,
            offset: offset,
          },
        }
      );

      const fetchedCards = await Promise.all(
        response.data.data.map(async (item) => {
          const createdAt = item.updated_at
            ? new Date(item.updated_at).toLocaleDateString("pt-BR")
            : "Date not found";

          let name = item.name || `ID: ${item.user_id}`;
          let email = item.mobile ? `${item.mobile}` : `ID: ${item.user_id}`;
          let avatarUrl = await getAvatarUrl(item);
          let company = item.company || "";
          let birthday;
          if (item.dob_day && item.dob_month && item.dob_year) {
            const day = String(item.dob_day).padStart(2, "0");
            const month = String(item.dob_month).padStart(2, "0");
            const year = item.dob_year;
            birthday = `${year}-${month}-${day}`;
          }

          return {
            id: item.user_id.toString(),
            name,
            email,
            createdAt,
            avatarUrl,
            operatorName: "Unknown Operator", // Placeholder, replace with actual operator logic if needed
            company,
            birthday,
          };
        })
      );

      setCardsData(fetchedCards);
      setTotalItems(response.data.total); // Assuming `total` is the total count of items
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch data", error);
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchData();
  }, [offset, groupId]);

  const handleNextPage = () => {
    if ((offset + 1) * 30 < totalItems) {
      setOffset(offset + 1);
      setPageInput(pageInput + 1);
    }
  };

  const handlePrevPage = () => {
    if (offset > 0) {
      setOffset(offset - 1);
      setPageInput(pageInput - 1);
    }
  };

  const handlePageInputChange = (e) => {
    const page = parseInt(e.target.value);
    if (page > 0 && page <= Math.ceil(totalItems / 30)) {
      setPageInput(page);
      setOffset(page - 1);
    }
  };

  const fetchTagsAndCampaigns = async () => {
    try {
      // Fetch campaign data
      const campaignResponse = await axios.post(
        API_ROUTES.CAMPAING_REPORT_GET_DATA_COMPOSE,
        {
          group_id: localStorage.getItem("group_id"),
        }
      );

      // console.log("the data is ", campaignResponse.data.data);

      if (campaignResponse.data.status === "success") {
        const campaignData = campaignResponse.data.data.map((campaign) => ({
          id: campaign.id,
          name: campaign.campaign_name,
        }));
        setCampaignOptions(campaignData);
      } else {
        throw new Error("Failed to fetch campaign data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Fetch tags and campaign data
    fetchTagsAndCampaigns();
  }, []);

  useEffect(() => {
    // Function to fetch user tags data
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://crmstation.net/laravel/public/api/api_get_user_tags",
          { group_id: group_id, user_id: modalContent.id } // Update with actual group_id and user_id
        );

        if (response.data.status === "success") {
          const { tags: allTags, userTags } = response.data;

          setTags(allTags.map((tag) => ({ value: tag.id, label: tag.tag })));
          setSelectedTags(
            userTags.map((tag) => ({
              value: tag.tag_id,
              label: allTags.find((t) => t.id === tag.tag_id)?.tag || "",
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (modalContent?.id) {
      fetchData();
    }
  }, [modalContent?.id]); // Fetch data when modalContent changes

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field being updated is 'birthday', split the value into year, month, and day
    if (name === "birthday") {
      const [year, month, day] = value.split("-"); // Split the date into parts (YYYY-MM-DD)

      setModalContent((prevContent) => ({
        ...prevContent,
        dob_year: year,
        dob_month: month,
        dob_day: day,
      }));
    } else {
      // Handle other fields as usual
      setModalContent((prevContent) => ({
        ...prevContent,
        [name]: value,
      }));
    }
  };

  const handleSave = async (tags) => {
    const tags1 = tags.map((item) => item.value);
    console.log(tags1);

    try {
      await axios.post(
        "https://crmstation.net/laravel/public/api/api_update_user",
        {
          tags: JSON.stringify(tags1),
          user_id: modalContent.id,
          name: modalContent.name,
          email: modalContent.email,
          company: modalContent.company,
          birthday: modalContent.birthday,
          group_id: groupId,
        }
      );
      toast.success("Saved successfully");
      // setAllTagsSelected(tags1);
      // Optionally close the modal after saving
      handleCloseModal();
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Error updating user.");
    }
  };

  // Handle edit profile
  const handleEditProfile = (id) => {
    window.open(`/edit_subs/${id}`, "_blank");
  };

  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  const handleOperatorChangeSendApi = (event) => {
    setOperatorId(event.target.value);
  };

  const handleOperatorSend = async () => {
    console.log("triggered");

    if (!operatorId) {
      alert("Please select an operator first!");
      return;
    }

    try {
      const response = await axios.post(
        API_ROUTES.OPERATOR_SEND_API_EDIT_PROFILE,
        {
          user_id: modalContent.id,
          group_id: groupId,
          operator_id: operatorId,
        }
      );

      toast.success(response.data.message);
      // Handle success (e.g., show a success message)
    } catch (error) {
      toast.error(error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleEnviar = async () => {
    console.log("This is campaign:", selectedCampaign);

    const groupId = localStorage.getItem("group_id");

    try {
      const response = await axios.get(
        `https://321dbase.com/public/cronjobs/send_lead_sms.php?group_id=${groupId}&message_id=${selectedCampaign}&user_id=${modalContent.id}`
      );

      if (response.data.retorno === "true") {
        toast.success("Message sent successfully");
        // Handle success (e.g., show a success toast notification)
      } else {
        toast.error(response.data.info);
        // Handle error (e.g., show an error toast notification)
      }
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle error (e.g., show an error toast notification)
    }
  };

  const [image, setImage] = useState("");
  const [loading2, setLoading2] = useState(false);

  const handleProfilePicture = async (email) => {
    setLoading2(true); // Start loading2 when button is clicked
    console.log(email);
    const name = localStorage.getItem("name");
    let number;
    const instancia = `${groupId}_${name}`;

    // Check if the first 4 characters are a number greater than 5530
    if (parseInt(email.substring(0, 4), 10) > 5530) {
      number = email.substring(0, 4) + email.substring(5);
    } else {
      number = `${email}@c.us`;
    }

    try {
      const response = await axios.post(
        "https://crmstation.net/laravel/public/api/api_latest_profileimage",
        {
          group_id: groupId,
          number: number,
          instancia: instancia,
        }
      );
      console.log(response);

      // Handle success or failure based on response.data.success
      if (response.data.success) {
        setImage(response.data.file_path); // Set the file_path as image
      } else {
        toast.error("Image not found"); // Show toast for failed request
      }
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      toast.error("An error occurred while fetching the image.");
    } finally {
      setLoading2(false); // Stop loading2 once the request is done
    }
  };

  const handleDeleteProfile = async (avatarUrl) => {
    try {
      // Extract the file name from the URL
      const fileName = avatarUrl.split("/").pop();

      const response = await axios.post(
        "https://crmstation.net/laravel/public/api/api_delete_profileimage",
        {
          file_path: fileName, // Only send the file name
        }
      );

      if (response.data.status) {
        toast.success("Deleted Successfully!");
        fetchData()
      }
    } catch (error) {
      toast.error("Error occurred during deletion!");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {loading ? (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <CircularProgress />
            </Grid>
          ) : (
            cardsData.map((card) => (
              <Grid item xs={12} md={3} key={card.id}>
                <Card onDoubleClick={() => handleOpenModal(card)}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                      padding: 2,
                      borderRadius: "10px",
                      height: "100%",
                    }}
                  >
                    <Avatar
                      src={card.avatarUrl}
                      alt={card.name}
                      sx={{
                        width: 200,
                        height: 200,
                        mb: 2,
                        border: "2px solid #000",
                        mt: 3,
                      }}
                    />
                    <MDTypography variant="h6">{card.name}</MDTypography>
                    <MDTypography variant="subtitle2">
                      {card.email}
                    </MDTypography>
                    <MDTypography variant="caption" color="textSecondary">
                      {card.createdAt}
                    </MDTypography>
                  </Box>
                </Card>
              </Grid>
            ))
          )}
        </Grid>

        <MDBox
          mt={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <MDButton
            variant="gradient"
            color="dark"
            onClick={handlePrevPage}
            disabled={offset === 0}
          >
            {translations.open_cards?.previous}
          </MDButton>
          <input
            type="number"
            value={pageInput}
            onChange={handlePageInputChange}
            style={{
              width: "50px",
              textAlign: "center",
              margin: "0 10px",
              borderRadius: "5px",
              padding: "5px",
            }}
          />
          <MDButton
            variant="gradient"
            color="dark"
            onClick={handleNextPage}
            disabled={(offset + 1) * 30 >= totalItems}
          >
            {translations.open_cards?.next}
          </MDButton>
        </MDBox>

        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          closeAfterTransition
          sx={{ borderRadius: "10px", border: "1px solid white" }}
        >
          <Fade in={modalOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "90%", md: "80%" },
                maxWidth: "1200px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "10px",
                border: "1px solid white",
              }}
            >
              {modalContent && (
                <Grid container spacing={4}>
                  {/* Left Side - Avatar and Buttons */}
                  <Grid item xs={12} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        padding: 2,
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <Avatar
                        src={modalContent.avatarUrl}
                        alt={modalContent.name}
                        sx={{
                          width: 200,
                          height: 200,
                          mb: 2,
                          border: "2px solid #000",
                          mt: 3,
                        }}
                      />

                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        sx={{ mt: 1 }}
                      >
                        {translations.leads?.start_chat}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        fullWidth
                        onClick={() => handleEditProfile(modalContent.id)}
                        sx={{ mt: 1 }}
                      >
                        {translations.leads?.edit_profile}
                      </MDButton>
                      <MDBox sx={{ display: "flex", width: "100%" }}>
                        <Grid container spacing={2}>
                          {/* First Button (col-6) */}
                          <Grid item xs={12} md={6}>
                            <MDButton
                              variant="gradient"
                              color="black1"
                              fullWidth
                              sx={{ mt: 1 }}
                              style={{ color: "black" }}
                              onClick={() =>
                                handleProfilePicture(modalContent.email)
                              }
                              disabled={loading2} // Disable button while loading2
                            >
                              {loading2
                                ? "Loading..."
                                : translations.open_cards?.get_profile_pic}
                            </MDButton>
                          </Grid>

                          {/* Second Button (col-6) */}
                          <Grid item xs={12} md={6}>
                            <MDButton
                              variant="gradient"
                              color="error"
                              sx={{ mt: 1 }}
                              onClick={() =>
                                handleDeleteProfile(modalContent.avatarUrl)
                              }
                            >
                              {translations.open_cards?.delete_pic}
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Box>
                  </Grid>

                  {/* Right Side - Form Fields */}
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      {/* First Row: Name and Email */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={translations.page_subscribers?.name}
                          variant="outlined"
                          margin="normal"
                          name="name"
                          value={modalContent.name}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={translations.page_subscribers?.mobile}
                          variant="outlined"
                          margin="normal"
                          name="email"
                          value={modalContent.email}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* Second Row: Company */}
                      <Grid item xs={6} style={{ marginTop: "-16px" }}>
                        <TextField
                          fullWidth
                          label={translations.open_cards?.company}
                          variant="outlined"
                          margin="normal"
                          name={translations.open_cards?.company}
                          value={modalContent.company || ""}
                          onChange={handleChange}
                        />
                      </Grid>
                      {/* Second Row: Birthday */}
                      <Grid item xs={6} style={{ marginTop: "-16px" }}>
                        <TextField
                          fullWidth
                          label="Birthday"
                          variant="outlined"
                          margin="normal"
                          name="birthday"
                          type="date" // Use the 'date' type to show a date picker
                          InputLabelProps={{
                            shrink: true, // Keep the label floated above the input
                          }}
                          value={modalContent.birthday || ""}
                          onChange={handleChange}
                        />
                      </Grid>

                      {/* Third Row: Tags Dropdown */}
                      <Grid item xs={12} md={6} lg={10}>
                        <Select1
                          placeholder={translations.tags?.select}
                          value={selectedTags}
                          options={tags}
                          onChange={handleTagsChange}
                          isMulti
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#e0e0e0",
                              primary: "#333333",
                            },
                          })}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          // marginTop: "6px",
                        }}
                      >
                        <MDButton
                          variant="gradient"
                          color="dark"
                          fullWidth
                          onClick={() => handleSave(selectedTags)}
                          sx={{ height: "43px" }} // Match TextField height
                        >
                          {translations.buttons_and_common_words?.save}
                        </MDButton>
                      </Grid>

                      {/* Fourth Row: Operator Selection and Send Button */}
                      <Grid item xs={10} sx={{ mt: 4 }}>
                        <FormControl fullWidth>
                          <InputLabel id="operator-select-label">
                            {translations.leads?.responsable}
                          </InputLabel>
                          <Select
                            labelId="operator-select-label"
                            value={modalContent.operator || ""}
                            onChange={handleOperatorChangeSendApi}
                            sx={{ p: 1.5 }}
                          >
                            <MenuItem value={0} disabled>
                              {translations.leads?.responsable}
                            </MenuItem>
                            {operator.map((op) => (
                              <MenuItem key={op.id} value={op.id}>
                                {op.opr_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 4,
                        }}
                      >
                        <MDButton
                          variant="gradient"
                          color="dark"
                          fullWidth
                          onClick={handleOperatorSend}
                          sx={{ height: "43px" }} // Match TextField height
                        >
                          {translations.buttons_and_common_words?.save}
                        </MDButton>
                      </Grid>

                      {/* Fifth Row: Select Message and Enviar Button */}
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <InputLabel id="campaign-select-label">
                            {translations.generate_message?.select_message}
                          </InputLabel>
                          <Select
                            labelId="campaign-select-label"
                            value={selectedCampaign}
                            onChange={(e) =>
                              setSelectedCampaign(e.target.value)
                            }
                            disabled={loading}
                            style={{ height: "2.7rem" }}
                          >
                            {loading ? (
                              <MenuItem disabled>Loading...</MenuItem>
                            ) : error ? (
                              <MenuItem disabled>{error}</MenuItem>
                            ) : campaignOptions.length > 0 ? (
                              campaignOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>
                                No campaigns available
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          // marginTop: "6px",
                        }}
                      >
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          onClick={handleEnviar}
                          sx={{ height: "43px" }} // Match TextField height
                        >
                          {translations.buttons_and_common_words?.send}
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Fade>
        </Modal>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default OpenCards;
