/**
  =========================================================
  * Material Dashboard 2 PRO React - v2.2.0
  =========================================================

  * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
  * Copyright 2023 Creative Tim (https://www.creative-tim.com)

  Coded by www.creative-tim.com

  =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
  */

/** 
    All of the routes for the Material Dashboard 2 PRO React are added here,
    You can add a new route, customize the routes and delete the routes here.

    Once you add a new route on this file it will be visible automatically on
    the Sidenav.

    For adding a new route you can follow the existing routes in the routes array.
    1. The `type` key with the `collapse` value is used for a route.
    2. The `type` key with the `title` value is used for a title inside the Sidenav. 
    3. The `type` key with the `divider` value is used for a divider between Sidenav items.
    4. The `name` key is used for the name of the route on the Sidenav.
    5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
    6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
    7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
    inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
    8. The `route` key is used to store the route location which is used for the react router.
    9. The `href` key is used to store the external links location.
    10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
    10. The `component` key is used to store the component of its route.
  */

// Material Dashboard 2 PRO React layouts
import Home from "./layouts/Home/index";
import ProfileOverview from "./layouts/profile/index";
import Settings from "./layouts/settings/index";
// Material Dashboard 2 PRO React components
import MDAvatar from "./components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import AssistantIcon from "@mui/icons-material/Assistant";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import TryIcon from "@mui/icons-material/Try";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import HomeIcon from "@mui/icons-material/Home";
import InstagramIcon from "@mui/icons-material/Instagram";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";

// Images
import profilePicture from "./assets/images/team-3.jpg";
import Subscribers from "./layouts/subscribers";
import Automatizer from "./layouts/automatizer";
import Assistante from "./layouts/assistante";
import BotdeMensagens from "./layouts/botdemensagens";
import Campaigns from "./layouts/campaigns";
import Integrations from "./layouts/integrations";
import Instagram from "./layouts/instagram/Instagram";
import Cupons from "./layouts/cupons";
import Others from "./layouts/others";
import SignInBasic from "./layouts/authentication/sign-in/basic";
import SignUpCover from "./layouts/authentication/sign-up/cover";
import ResetCover from "./layouts/authentication/reset-password/cover";

import { Typography } from "@mui/material";
import Leads from "./layouts/Leads";
import Metrics from "./layouts/Metrics";
import SettingsAI from "./layouts/assistante/SettingsAI";
import FlowBuilder from "./layouts/FlowBuilder";
import UsersTable from "./layouts/Public/UsersTable";
// import Reports from "./layouts/Reports";
import BotsReports from "./layouts/Reports/BotsReports";
import CampaignReports from "./layouts/Reports/CampaignReports";

import ComposeMessage from "./layouts/campaigns/ComposeMessage";
import GenerateCampaign from "./layouts/campaigns/GenerateCampaign";
import OurApis from "./layouts/integrations/OurApis";
import BirthDay from "./layouts/botdemensagens/BirthDay";
import Appointment from "./layouts/botdemensagens/Appointment";
import Welcome from "./layouts/botdemensagens/Welcome";
import FirstPurchase from "./layouts/botdemensagens/FirstPurchase";
import ExpiryDate from "./layouts/botdemensagens/ExpiryDate";
// import avatar from "./assets/images/dummy.jpg"
import avatar from "./assets/images/icon.png";
import multichannel from "./assets/images/people.png";
import avatar1 from "./assets/images/icon1.png";
import EditSubscriber from "./layouts/EditSubscriber";
import getTranslation from "./getTranslation";
import CreateNew from "./layouts/campaigns/CreateNew";
import NewPromotions from "./layouts/NewPromotions";
import Tags from "./layouts/Tags";
import EditTag from "./layouts/Tags/EditTag";
import CreateTag from "./layouts/Tags/CreateTag";
import NewUser from "./layouts/campaigns/new-user";
import NewUser1 from "./layouts/campaigns/new-user copy";
import NewUser2 from "./layouts/campaigns/new-user copy-1";
import SellIcon from "@mui/icons-material/Sell";
import Redirection from "./layouts/Redirection";
import RedirectionC from "./layouts/RedirectionC";
import RedirectionE from "./layouts/RedirectionE";
import DeatilReport from "./layouts/DeatilReport";
// import ComposeReport from "./layouts/Reports/CampaignReports/ComposeReportDetail";
import ComposeReportDetail from "./layouts/Reports/CampaignReports/ComposeReportDetail";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatsappOfficial from "./layouts/WhatsappOfficial";
import YourData from "./layouts/assistante/YourData";
import FormsSurvey from "./layouts/FormsSurvey";
import BotsReportsDetails from "./layouts/Reports/BotsReports/BotsReportsDetails";
import WhatsappNewPage from "./layouts/Home/WhatsappNewPage";
import MultiChanel from "./layouts/MultiChanel";
import { disableCursor } from "@fullcalendar/core/internal";
import TicketsQr from "./layouts/TicketsQr";
import Column from "./layouts/Column";
import CreateColumn from "./layouts/Column/CreateColumn";
import EditColumn from "./layouts/Column/EditColumn";
import OpenCards from "./layouts/OpenCards";
import CsvUploader from "./layouts/CsvUploader";
import ImportedData from "./layouts/ImportedData";
import Import from "./layouts/Import";
import AppointmentImportedEvents from "./layouts/botdemensagens/AppointmentImportedEvents";

const language = localStorage.getItem("language") || "portuguese";

const routes = [
  {
    type: "collapse",
    name: getTranslation("home", language),
    key: getTranslation("home", language),
    route: "/home",
    icon: <HomeIcon />,
    component: <Home />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: getTranslation("subscribers", language),
    key: getTranslation("subscribers", language),
    route: "/yourPublic",
    icon: (
      <img src={avatar1} alt="Subscribers" style={{ width: 24, height: 24 }} />
    ),
    component: <UsersTable />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: getTranslation("leads", language),
    key: "leads",
    route: "/leads",
    icon: (
      <img src={avatar} alt="Subscribers" style={{ width: 20, height: 24 }} />
    ),
    component: <Leads />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: getTranslation("multichannel", language),
    key: "multichannel",
    icon: (
      <img src={multichannel} alt="Subscribers" style={{ width: 20, height: 24 }} />
    ),
    route:"/multichannel",
    component: <MultiChanel />,
    noCollapse: true,
    
  },
  {
    type: "collapse",
    name: getTranslation("automatize", language),
    key: "automatize",
    icon: <AutoModeIcon />,
    collapse: [
      {
        name: getTranslation("flowbuilder", language),
        key: "flowbuilder",
        route: "/flowbuilder",
        component: <Automatizer />,
      },
      {
        name: getTranslation("your_data", language),
        key: "your-data",
        route: "/your_data",
        component: <YourData />,
      },
      {
        name: getTranslation("ai_assistant", language),
        key: "AI Assistant",
        route: "/settings",
        component: <SettingsAI />,
      },
      {
        name: getTranslation("upload_image", language),
        key: "upload-image",
        route: "/upload_image",
        component: <Assistante />,
      },
    ],
  },
  {
    type: "collapse",
    name: getTranslation("messages_bots", language),
    key: "messagebots",
    icon: <TryIcon />,
    collapse: [
      {
        name: getTranslation("bot_reports", language),
        key: "botreports",
        route: "/bot-reports",
        component: <BotsReports />,
      },
      {
        name: getTranslation("bot_birthday", language),
        key: "birthday",
        route: "/bot_birthday",
        component: <BirthDay />,
      },
      {
        name: getTranslation("bot_welcome", language),
        key: "welcome",
        route: "/bot_welcome",
        component: <Welcome />,
      },
      {
        name: getTranslation("bot_appointment", language),
        key: "appointment",
        route: "/bot_appointment",
        component: <Appointment />,
      },
      {
        name: getTranslation("bot_firstpurchase", language),
        key: "firstPurchase",
        route: "/bot_firstpurchase",
        component: <FirstPurchase />,
      },
      {
        name: getTranslation("bot_expiring", language),
        key: "expiringDate",
        route: "/bot_expiring",
        component: <ExpiryDate />,
      },
    ],
  },
  {
    type: "collapse",
    name: getTranslation("campaigns", language),
    key: "campaigns",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: getTranslation("campaigns_reports", language),
        key: "campaign-reports",
        route: "/campaigns_reports",
        component: <CampaignReports />,
      },
      {
        name: getTranslation("compose_message", language),
        key: "Compose Message",
        route: "/compose_message",
        component: <ComposeMessage />,
      },
      {
        name: getTranslation("generate_message", language),
        key: "generate-campaign",
        route: "/generate_message",
        component: <GenerateCampaign />,
      },
    ],
  },
  {
    type: "collapse",
    name: getTranslation("integrations", language),
    key: "integrations",
    icon: <IntegrationInstructionsIcon />,
    collapse: [
      {
        name: getTranslation("our_api", language),
        key: "ourapis",
        route: "/our_api",
        component: <OurApis />,
      },
      {
        name: getTranslation("hotmart", language),
        key: "hotmart",
        route: "/hotmart",
        component: <Integrations />,
      },
      {
        name: getTranslation("google_sheets", language),
        key: "google-sheets",
        route: "/google_sheets",
        component: <Integrations />,
      },
      {
        name: getTranslation("woo_commerce", language),
        key: "woo-commerce",
        route: "/woo_commerce",
        component: <Integrations />,
      },
    ],
  },
  {
    type: "collapse",
    name: getTranslation("instagram_bot", language),
    key: "instagram",
    route: "/instagram_bot",
    icon: <InstagramIcon />,
    component: <Instagram />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: getTranslation("whatsapp", language),
    key: "whatsapp",
    route: "/whatsapp",
    icon: <WhatsAppIcon />,
    component: <WhatsappOfficial />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: getTranslation("cupons", language),
  //   key: "cupons",
  //   icon: <MonetizationOnIcon />,
  //   collapse: [
  //     {
  //       name: getTranslation("new_promotion", language),
  //       key: "new-promotion",
  //       route: "/new_promotion",
  //       component: <NewPromotions />,
  //     },
  //     {
  //       name: getTranslation("cupons_list", language),
  //       key: "cupons-list",
  //       route: "/cupons_list",
  //       component: <Cupons />,
  //     },
  //     {
  //       name: getTranslation("alert_messages", language),
  //       key: "messages",
  //       route: "/alert_messages",
  //       component: <Cupons />,
  //     },
  //     {
  //       name: getTranslation("whatsapp_cloudapi", language),
  //       key: "operators",
  //       route: "/whatsapp_cloudapi",
  //       component: <Cupons />,
  //     },
  //     {
  //       name: getTranslation("lottery", language),
  //       key: "lotery",
  //       route: "/lotery",
  //       component: <Cupons />,
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: getTranslation("metrics", language),
    key: "metrics",
    route: "/metrics",
    icon: <SignalCellularAltIcon />,
    component: <Metrics />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: getTranslation("others", language),
    key: "others",
    icon: <IntegrationInstructionsIcon />,
    collapse: [
      {
        name: getTranslation("cupons", language),
        key: "Coupons",
        route: "/cupons",
        component: <Redirection />,
      },
      {
        name: getTranslation("linktudo", language),
        key: "Link-tudo",
        route: "/link-tudo",
        component: <RedirectionC />,
      },
      {
        name: getTranslation("ecommerce", language),
        key: "ecommerce",
        route: "/ecommerce",
        component: <RedirectionE />,
      },
      {
        name: getTranslation("forms_survey", language),
        key: "forms_survey",
        route: "/forms_survey",
        component: <FormsSurvey />,
      },
    ],
  },

  {
    name: getTranslation("editSubscriber", language),
    route: "/edit_subs/:user_id",
    key: "edit_subs",
    component: <EditSubscriber />,
  },
  {
    name: getTranslation("tags", language),
    route: "/tags",
    key: "tags",
    component: <Tags />,
  },
  {
    name: getTranslation("column", language),
    route: "/column",
    key: "column",
    component: <Column />,
  },
  {
    name: getTranslation("open_cards", language),
    route: "/open_cards",
    key: "open_cards",
    component: <OpenCards />,
  },
  {
    name: getTranslation("campaign_report_detail", language),
    route: "/campaign_report_detail/:campaign_id",
    key: "campaign_report_detail",
    component: <ComposeReportDetail />,
  },
  {
    name: getTranslation("bots_report_detail", language),
    route: "/bots_report_detail/:bots_id",
    key: "bots_report_detail",
    component: <BotsReportsDetails />,
  },
  {
    name: "Detail Report",
    route: "/detail-report/:id",
    key: "detailReport",
    component: <DeatilReport />,
  },
  {
    name: "Imported Data",
    route: "/imported_data/:id",
    key: "imported_data",
    component: <ImportedData />,
  },
  {
    name: "Imported",
    route: "/import",
    key: "import",
    component: <Import />,
  },
  {
    name: "New QR",
    route: "/newqr/type/:type",
    key: "newqr",
    component: <WhatsappNewPage />,
  },
  {
    name: "Create Tags",
    route: "/create-tags",
    key: "create-tags",
    component: <CreateTag />,
  },
  {
    name: "create_column",
    route: "/create_column",
    key: "create_column",
    component: <CreateColumn />,
  },
  {
    name: "Edit Column",
    route: "/edit_column/:id",
    key: "edit_column",
    component: <EditColumn />,
  },
  {
    name: "Create New ",
    route: "/filters",
    key: "filters",
    component: <NewUser1 />,
  },
  {
    name: "Create New ",
    route: "/tag-categories",
    key: "tag-categories",
    component: <NewUser2 />,
  },
  {
    name: "Edit Tags",
    route: "/edit_tag/:id",
    key: "edit_tag",
    component: <EditTag />,
  },
  {
    route: "/",
    component: <SignInBasic />,
  },
  {
    route: "/authentication/sign-up",
    component: <SignUpCover />,
  },
  {
    route: "/authentication/reset-password",
    component: <ResetCover />,
  },
  {
    route: "/ticket-qr",
    component: <TicketsQr />,
  },
  {
    route: "/csv",
    component: <CsvUploader />,
  },
  {
    name: "appointment_imported_events",
    key: "appointment_imported_events",
    route: "/appointment_imported_events",
    component: <AppointmentImportedEvents />,
  },
];

export default routes;
