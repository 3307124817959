import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import loadTranslations from "../../utils/loadTranslations";
import { useLanguageState } from "../../context/LanguageContext";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, IconButton } from "@mui/material";
import { pink } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";

const WhatsappNewPage = () => { 
  // Get current language from context
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const type = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch translations based on the current language
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  let apiUrl;
  let imageUrl;
  if (type.type === "1") {
    apiUrl = "https://app.321dbase.com/wp"; 
    imageUrl = "https://app.321dbase.com/";
  } else if (type.type === "4") {
    apiUrl = "https://srv10.crmstation.net/wp";
    imageUrl = "https://srv10.crmstation.net/";
  }else if (type.type === "2") {
    apiUrl = "https://srv2.crmstation.net/wp";
    imageUrl = "https://srv2.crmstation.net/";
  } else {
    // console.log(type.type === "1");

    navigate("/");
  }

  const apiUrl_b = "https://appdev.321dbase.com/wp";
  const imageUrl_b = "https://appdev.321dbase.com/";

  const authUserId = localStorage.getItem("group_id");
  const groupName = localStorage.getItem("name");
  const grp = `${authUserId}_${groupName}`;
  const grp_b = `${authUserId}_${groupName}_b`;
  const checkInterval = 12000;
  var intervalId;
  var qrIntervalId;
  const check = async () => { 
    try {
      const response = await axios.post(`${apiUrl}/get_instancias`);
      const instancias = response.data.retorno.instancias;
      instancias.forEach((instancia) => {
        const groupid = instancia.nome.split("_")[0];
        if (groupid === authUserId) {
          console.log("IDSSSSS MATCHEDDDD!!!!!!!!!!");
          if (instancia.status === true) {
            updateSyncContainer();
          }
        }
      }); 
    } catch (error) {
      console.error("Erro na requisição AJAX:", error);
    }
  };
  // Update the sync container with a connected message
  const updateSyncContainer = () => {
    document.getElementById("message_container").innerHTML =      
      translations.page_home?.send_test_message || "ENVIAR MENSAGEM TESTE"; 
    document.getElementById("logoff").textContent =   
      translations.page_home?.update_connection_status || "Atualizar Conexão";
    clearInterval(intervalId);
  };

  // Update the sync container for group B
  const updateSyncContainer_b = () => {
    document.getElementById("message_container_b").innerHTML =
      translations.page_home?.send_test_message; 
    document.getElementById("logoff_b").textContent =
      translations.page_home?.update_connection_status || "Atualizar Conexão";
  };

  // Update the QR container with the provided QR code
  const updateQRContainer = (qrcode) => {
    const timestamp = new Date().getTime();
    const qrElement = qrcode
      ? `<img id="qr_id" style="width:150px;height:150px;" src="${imageUrl}/${qrcode}?t=${timestamp}" data-name="${grp}"/>`
      : `<img id="qr_id" style="width:30px;height:30px;" src="https://i.gifer.com/ZZ5H.gif" data-name="Loading..."/>`;
    document.getElementById("message_container").innerHTML = qrElement;
    if (qrcode) {
      console.log("get QR code and clearInterval");
      intervalId = setInterval(check, checkInterval);
      clearInterval(qrIntervalId);
    }else{
      console.log("calling getqr setInterval");
      if (!qrIntervalId) {
        qrIntervalId = setInterval(getqr, 30000);
      }
    }
  };

  // Update the QR container for group B with the provided QR code
  const updateQRContainer_b = (qrcode) => {
    const timestamp = new Date().getTime();
    const qrElement = qrcode
      ? `<img id="qr_id_b" style="width:150px;height:150px;" src="${imageUrl_b}/${qrcode}?t=${timestamp}" data-name="${grp_b}"/>`
      : `<img id="qr_id_b" style="width:30px;height:30px;" src="https://i.gifer.com/ZZ5H.gif" data-name="Loading..."/>`;
    document.getElementById("message_container_b").innerHTML = qrElement;
  };

  // Log off the user from the WhatsApp instance
  const logoff = async (data) => {
    return axios.post(`${apiUrl}/logoff`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const logoff_b = async (data) => {
    return axios.post(`${apiUrl_b}/logoff`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const sendTestMessage = async () => {
    const data_ = {
      instancia: grp,
      number: "5521992983857@c.us",
      msg: translations.page_home?.send_test_message_reply,
    };
    console.log(data_);
    try {
      const response = await axios.post(`${apiUrl}/sendMsg`, data_, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = response.data;
      console.log(responseData);
      const message = responseData.retorno.id
        ? "Parece que a mensagem foi enviada sem nenhum problema. Por favor confirme no Whatsapp do seu celular."
        : "Parece que falhou. Confirme no Whatsapp do seu celular e se realmente não foi enviada, clique no botão NOVO QRCODE e leia novamente o qrcode.";
      document.getElementById("testResponse").innerHTML = message;
    } catch (error) {
      console.error("Erro na requisição AJAX:", error);
      document.getElementById("testResponse").innerHTML =
        "Erro ao enviar a mensagem de teste.";
    }
  };
  const sendTestMessage_b = async () => {
    const data_ = {
      instancia: grp_b,
      number: "5521992983857@c.us",
      msg: "Teste",
    };
    console.log(data_);
    try {
      const response = await axios.post(`${apiUrl_b}/sendMsg`, data_, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = response.data;
      console.log(responseData);
      const message = responseData.retorno.id
        ? "Parece que a mensagem foi enviada sem nenhum problema. Por favor confirme no Whatsapp do seu celular."
        : "Parece que falhou. Confirme no Whatsapp do seu celular e se realmente não foi enviada, clique no botão NOVO QRCODE e leia novamente o qrcode.";
      document.getElementById("testResponse_b").innerHTML = message;
    } catch (error) {
      console.error("Erro na requisição AJAX:", error);
      document.getElementById("testResponse_b").innerHTML =
        "Erro ao enviar a mensagem de teste.";
    }
  };
  // Utility function to pause execution for a given time
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Fetch the QR code for the WhatsApp connection
  const getqr = async () => {
    const qrElement =`<img id="qr_id" style="width:30px;height:30px;" src="https://i.gifer.com/ZZ5H.gif" data-name="Loading..."/>`;
    document.getElementById("message_container").innerHTML = qrElement;

    const data_ = { instancia: grp, remover_cache: false };
    try {
      const { data } = await axios.post(`${apiUrl}/qrcode`, data_, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("fist call =======");
      console.log(data);
      if (data.status === "DISCONECTED" || data.qrcode === "DISCONECTED") {
        console.log("DISCONECTED");
        await logoff(data_);
        getqr();
      } else if (data.qrcode === "syncronized") {
        console.log("syncronized");
        updateSyncContainer();
      } else {
        if (data.status === "UNPAIRED" && !data.qrcode) {
          await sleep(5000); // Wait for 5 seconds before retrying
          getqr();
        }
        console.log("QR");
        console.log("qrcode ------> "+data.qrcode);
        updateQRContainer(data.qrcode);
      }
    } catch (error) {
      console.error("Erro na requisição AJAX:", error);
    }
  };

  // Fetch the QR code for group B WhatsApp connection
  const getqr_b = async () => {
    const data_ = { instancia: grp_b, remover_cache: false };
    try {
      const { data } = await axios.post(`${apiUrl_b}/qrcode`, data_, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("second call =======" + data);
      if (data.status === "DISCONECTED" || data.qrcode === "DISCONECTED") {
        console.log("DISCONECTED");
        await logoff_b(data_);
        getqr_b();
      } else if (data.qrcode === "syncronized") {
        console.log("syncronized");
        updateSyncContainer_b();
      } else {
        if (data.status === "UNPAIRED" && !data.qrcode) {
          await sleep(5000); // Wait for 5 seconds before retrying
          getqr_b();
        }
        console.log("nothing");
        updateQRContainer_b(data.qrcode);
      }
    } catch (error) {
      console.error("Erro na requisição AJAX:", error);
    }
  };

  // Handle click events for logoff buttons and fetch QR codes accordingly
  useEffect(() => {
    const handleClick = async (event) => {
      if (event.target && event.target.id === "logoff") {
        event.preventDefault();
        await logoff({ instancia: grp });
        getqr();
      } else if (event.target && event.target.id === "logoff_true") {
        event.preventDefault();
        await logoff({ instancia: grp,  remover_cache:true});
        //getqr();
        const button = document.getElementById("logoff-btn-true");
        button.style.display = "block";
      } else if (event.target && event.target.id === "logoff-btn-false") {
        event.preventDefault();
        await logoff({ instancia: grp, remover_cache: false });
        getqr();
        
        // window.location.reload();
      } else if (event.target && event.target.id === "logoff-btn-true") {
        event.preventDefault();

      //   let countdown = 30; // Countdown starts at 30 seconds
      //   // Disable the button for 30 seconds
      const button = document.getElementById("logoff-btn-true");
      button.style.display = "none";
      //   button.disabled = true; // Disable the button
      //   button.textContent = `${translations.page_home?.new_qrcode || "Gerar Novo QRCode"} (${countdown}s)`; 
      //   console.log("Button disabled for 30 seconds");
      //   const countdownInterval = setInterval(() => {
      //     countdown--;
      //     button.textContent = `${translations.page_home?.new_qrcode || "Gerar Novo QRCode" } (${countdown}s)`;
      //     if (countdown <= 0) {
      //         clearInterval(countdownInterval); // Stop the countdown when it reaches 0
      //         button.disabled = false; // Re-enable the button after 30 seconds
      //         button.textContent = `${translations.page_home?.new_qrcode || "Gerar Novo QRCode" }`; // Reset the button text
      //         console.log("Button re-enabled after 30 seconds");
      //     }
      // }, 1000); // Update every second

        const groupId = localStorage.getItem("group_id");
        const name = localStorage.getItem("name");

        const qrCodeUrl = `${apiUrl}/deleteQrcode/${groupId}_${name}.png`;
        console.log(qrCodeUrl);

        try {
          await axios.get(qrCodeUrl);
          console.log("QR code request successful");
        } catch (error) {
          console.error("Error:", error);
        } finally {
          await logoff({ instancia: grp, remover_cache: true });

          getqr();



        }
      }

      if (event.target && event.target.id === "logoff_b") {
        event.preventDefault();
        await logoff_b({ instancia: grp_b });
        getqr_b();
      } else if (event.target && event.target.id === "logoff-btn-false_b") {
        event.preventDefault();
        await logoff_b({ instancia: grp_b, remover_cache: false });
        getqr_b();
        // window.location.reload();
      } else if (event.target && event.target.id === "logoff-btn-true_b") {
        event.preventDefault();
        await logoff_b({ instancia: grp_b, remover_cache: true });
        getqr_b();
        // window.location.reload();
      }
    };

    document.addEventListener("click", handleClick);
    //check();
    // Clean up listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [authUserId, groupName, grp, grp_b]);
  const [open, setOpen] = useState(true);

  // Render the layout and buttons for WhatsApp connections
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {open && (
          <Alert
            style={{  color: "white" , background: "#e91e63" }}
            severity="error"
            sx={{ mt: 2, mb: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {translations.whatsapp?.alert}
          </Alert>
        )}

        <Grid container>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDTypography variant="h6" sx={{ mt: 1, mb: 0, ml: 2 }}>
                {type.type === "1"
                  ? translations.whatsapp?.read_qr_1
                  : type.type === "4"
                    ? translations.whatsapp?.read_qr_4 
                    : type.type === "2"
                    ? translations.whatsapp?.read_qr_2
                    : ""}
                     ({translations.whatsapp?.read_qr_counter})
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <div id="message_container"></div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <MDBox
                    gap={2}
                    mb={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >

                    <MDButton
                      type="button"
                      variant="gradient"
                      color="black1"
                      fullWidth 
                      style={{  color: "white" , background: "#197802" }}
                      id="logoff-btn-true"
                    >
                    {translations.page_home?.new_qrcode}
                    </MDButton>

                    <MDButton
                      type="button"
                      variant="gradient"
                      color="black1"
                      fullWidth
                      style={{  color: "white" , background: "#f72587" }}
                      onClick={sendTestMessage}
                    >
                      {translations.page_home?.send_test_message}
                    </MDButton>
                    
                    

                    <MDButton
                      type="button"
                      variant="gradient"
                      color="black1"
                      fullWidth
                      style={{  color: "white" , background: "#8122d0" }}
                      id="logoff_true"
                    >
                      {translations.page_home?.logoff_true ||
                        "Desconectar / Limpar"}
                    </MDButton>


                  </MDBox>
                </Grid>
                <div
                  id="testResponse"
                  style={{ textAlign: "center", marginTop: "10px" }}
                ></div>
              </Grid>
            </MDBox>
          </Card>
          <Card sx={{ width: "100%", mt: 5 }}>
            {/* <MDBox display="flex">
              <MDTypography variant="h6" sx={{ mt: 1, mb: 0, ml: 2 }}>
                {translations.whatsapp?.read_qr_2}
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <div id="message_container_b"></div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <MDBox
                    gap={2}
                    mb={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <MDButton
                      type="button"
                      variant="gradient"
                      color="black1"
                      fullWidth
                      style={{ color: "black" }}
                      onClick={sendTestMessage_b}
                    >
                      {translations.page_home?.send_test_message}
                    </MDButton>
                    <MDButton
                      type="button"
                      variant="gradient"
                      color="black1"
                      fullWidth
                      style={{ color: "black" }}
                      id="logoff_b"
                    >
                      {translations.page_home?.update_connection_status ||
                        "Atualizar Conexão"}
                    </MDButton>
                    <MDButton
                      type="button"
                      variant="gradient"
                      color="black1"
                      fullWidth
                      style={{ marginBottom: "0px", color: "black" }}
                      id="logoff-btn-true_b"
                    >
                      {translations.page_home?.new_qrcode}
                    </MDButton>
                  </MDBox>
                </Grid>
                <div
                  id="testResponse_b"
                  style={{ textAlign: "center", marginTop: "10px" }}
                ></div>
              </Grid>
            </MDBox> */}
          </Card>
        </Grid>
      </MDBox>
      {/* <div style={{ height: "100%" }}></div> */}
      <Footer />
    </DashboardLayout>
  );
};

export default WhatsappNewPage;
