import React, { useEffect, useState } from "react";
import { Box, TextField, CircularProgress } from "@mui/material";
import MDButton from "../../../components/MDButton";
import { toast } from "react-toastify";
import API_ROUTES from "../../../config/apiConfig";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";

const Card4 = () => {
  const [instructions, setInstructions] = useState("");
  const [loading, setLoading] = useState(false);

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      //console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const handleSend = async () => {
    const group_id = localStorage.getItem("group_id");

    if (!group_id) {
      toast.error("Group ID not found in localStorage");
      return;
    }

    if (!instructions) {
      toast.error("Please enter a description");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(API_ROUTES.SETTING_AI_SEND_DATA_4RD_CARD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ group_id, instructions }),
      });

      const result = await response.json();
      if (result.success) {
        toast.success("Description sent successfully!");
      } else {
        toast.error("Failed to send description.");
      }
    } catch (error) {
      toast.error("Error sending description.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TextField
        fullWidth
        multiline
        rows={4}
        label="Description"
        variant="outlined"
        margin="normal"
        value={instructions}
        onChange={(e) => setInstructions(e.target.value)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2, // Space between elements
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Align button to the right
          }}
        >
          <MDButton
            type="submit"
            variant="gradient"
            color="info"
            onClick={handleSend}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="info" />
            ) : (
              translations.buttons_and_common_words?.send
            )}
          </MDButton>
        </Box>
      </Box>
    </>
  );
};

export default Card4;
