import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Card,
  TextField,
  CircularProgress,
} from "@mui/material";
import MDButton from "../../../components/MDButton";
import { toast } from "react-toastify";
import API_ROUTES from "../../../config/apiConfig";
import loadTranslations from "../../../utils/loadTranslations";
import { useLanguageState } from "../../../context/LanguageContext";

const Card3 = () => {
  const [description, setDescription] = useState("");
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [responseText, setResponseText] = useState("");
  const userID = localStorage.getItem("group_id");

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      //console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  useEffect(() => {
    const fetchAssistantDescription = async () => {
      try {
        const response = await fetch(API_ROUTES.SETTING_AI_GET_DATA_API_1ST, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ group_id: userID }),
        });
        const result = await response.json();
        if (result.success && result.data.assistant) {
          setDescription(result.data.assistant.description || "");
          setShowCard(!!result.data.assistant.description);
        }
      } catch (error) {
        console.error("Error fetching assistant description:", error);
      }
    };

    fetchAssistantDescription();
  }, []);

  const handleSend = async () => {
    const group_id = localStorage.getItem("group_id");

    if (!group_id) {
      toast.error("Group ID not found in localStorage");
      return;
    }

    if (!question) {
      toast.error("Please enter a question");
      return;
    }

    setLoading(true);
    setShowCard(true); // Show card while loading
    setResponseText("Loading..."); // Show loading text

    try {
      const response = await fetch(API_ROUTES.SETTING_AI_SEND_DATA_3RD_CARD, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ group_id, question }),
      });

      const result = await response.json();
      if (result.success) {
        toast.success(translations.setting_AI?.data_sent);
        setResponseText(result.answer);
      } else {
        toast.error("Failed to send question.");
        setResponseText("Something went wrong.");
      }
    } catch (error) {
      toast.error("Error sending question.");
      setResponseText("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 2,
        }}
      >
        {showCard && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Card
              sx={{
                backgroundColor: "#e0e0e0",
                padding: 1,
                wordBreak: "break-word",
              }}
            >
              <Typography variant="body2">{responseText}</Typography>
            </Card>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 2,
        }}
      >
        <TextField
          fullWidth
          label={"Type a question to see what AI will answer"}
          variant="outlined"
          margin="normal"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end", // Align button to the right
        }}
      >
        <MDButton
          type="submit"
          variant="gradient"
          color="info"
          onClick={handleSend}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="info" />
          ) : (
            translations.buttons_and_common_words?.send
          )}
        </MDButton>
      </Box>
    </>
  );
};

export default Card3;
