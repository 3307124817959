import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";
import MDInput from "../../components/MDInput";
import API_ROUTES from "../../config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";

const MultiChanel = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const name = localStorage.getItem("name");
  const group_id = localStorage.getItem("group_id");
  
  const [userValue, setUserValue] = useState(`${name}@crmstation.net`);
  const [passwordValue, setPasswordValue] = useState(`${group_id}${group_id}`);
  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  // Function to open the URL when the button is clicked
  const handleButtonClick = () => {
    window.open("https://srv1.crmstation.net", "_blank");
  };

  const [inputValue2, setInputValue2] = useState("");
  useEffect(() => {
    const groupId = localStorage.getItem("group_id");
    if (groupId) {
      axios
        .post(API_ROUTES.HOME_API_STATS, {
          group_id: groupId,
        })
        .then((response) => {
          const data = response.data;
          console.log("this is the data", data);
          setInputValue2(data.multichannel_token);
        }).catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }, []);

  const [isSaving, setIsSaving] = useState(false);

  const handleSave = () => {
    setIsSaving(true);

    // Replace this with your actual API call
    axios
      .post(API_ROUTES.API_MULTICHANEL_TOKEN, {
        group_id,
        token: inputValue2,
      })
      .then((response) => {
        // Show success toast notification
        toast.success(translations.toast_messages?.save_success);
      })
      .catch((error) => {
        // Show error toast notification
        toast.error(translations.toast_messages?.save_error);
        console.error("Error saving token:", error);
      })
      .finally(() => {
        setIsSaving(false); // Re-enable the button after the request is complete
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
      <MDBox mt={4}>
          {/* Card with 2 Parts */}
          <MDBox py={5} my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} >
                <MDBox mb={1.5}>
                  <Card  sx={{ p: "20px"}}>
                      <MDTypography variant="h4" color="textSecondary" mb={3}>
                          {translations.multichanel?.chanel_admin}
                      </MDTypography>

                      {/* Input Field 1 */}
                      <TextField
                        type="text"
                        fullWidth
                        label={translations.signin?.user || "Usuário"}
                        value={userValue}
                        onChange={(e) => setInputValue2(e.target.value)}
                        mb={2}
                        sx={{ mt: 2 }}
                      />

                      {/* Input Field 2 */}
                      <TextField
                        type="text"
                        fullWidth
                        label={translations.signin?.password || "Senha"}
                        value={passwordValue}
                        onChange={(e) => setInputValue2(e.target.value)}
                        mb={4}
                        sx={{ mt: 2 }}
                      />
                   

                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={handleButtonClick}
                        sx={{ mt: 2, width: "200px" }} // Smaller width
                      >
                        {translations.multichanel?.chanel_admin}
                      </MDButton>
                    {/* Second Row */}
                    {/* <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ height: "50px", mt: 3 }}
                    >
                      <MDBox width="33%">
                        <MDTypography
                          component="p"
                          variant="button"
                          color="text"
                          display="flex"
                        >
                          
                        </MDTypography>
                      </MDBox>
                      
                    </MDBox> */}

                    {/* Admin Information */}
                  </Card>
                </MDBox>




                <Card  sx={{ p: "20px", pb: "50px"}}>
                    <MDBox  mb={1.5} >
                        <MDTypography variant="h4" color="textSecondary" mb={3}>
                            {translations.page_home?.multichanel_token}
                        </MDTypography>
                        <MDInput
                          type="text"
                          fullWidth
                          placeholder={translations.page_home?.input_placeholder_2}
                          value={inputValue2}
                          onChange={(e) => setInputValue2(e.target.value)}
                        />
                        <MDButton
                          variant="gradient"
                          color="dark"
                          sx={{ mt: 2, width: "200px" }} 
                          onClick={handleSave}
                          disabled={isSaving}
                        >
                            {isSaving
                            ? translations.buttons_and_common_words?.saving ||
                            "Saving..."
                            : translations.buttons_and_common_words?.save}
                        </MDButton>
                    </MDBox>
                </Card>



              </Grid>

              {/* Right Part */}
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {/* Add the image */}
                <MDBox
                  component="img"
                  src="https://321dbase.com/0/crmstation/multi.jpg"
                  alt="Multi Chanel Image"
                  width="100%"
                  maxWidth="400px"
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default MultiChanel;
