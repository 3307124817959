import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box"; // Import Box from MUI
import MDBox from "../../../components/MDBox";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import BASE_URL from "../../../config/apiConfig"; // Ensure BASE_URL is defined in your apiConfig
import MDButton from "../../../components/MDButton";
import { toast } from "react-toastify";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";

const EditTag = () => {
  const { id } = useParams();
  const [tagName, setTagName] = useState("");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const group_id = localStorage.getItem("group_id");

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  useEffect(() => {
    const fetchTagData = async () => {
      try {
        const response = await axios.get(
          `https://crmstation.net/laravel/public/api/api_users_by_group?group_id=${group_id}`
        );

        const tag = response.data.tags.find((tag) => tag.id === parseInt(id));

        if (tag) {
          setTagName(tag.tag);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching tag data:", error);
        setLoading(false);
      }
    };

    fetchTagData();
  }, [id, group_id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaving(true);

    try {
      await axios.post(`${BASE_URL.DATA_SEND_EDIT_TAG}`, {
        tag_id: parseInt(id),
        name: tagName,
        group_id: group_id,
      });

      toast.success("Tag updated successfully!");
      navigate("/tags"); // Navigate back to tags page after successful update
    } catch (error) {
      console.error("Error updating tag:", error);
      toast.error("Error updating tag. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} style={{ height: "80vh" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title={`Edit ${translations.edit_subscribers?.tags}`} />
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label={translations.edit_subscribers?.tags}
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                  />
                  <Box display="flex" justifyContent="flex-end" mt={2}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      disabled={saving}
                    >
                      {saving ? "Saving..." : translations.buttons_and_common_words?.save}
                    </MDButton>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditTag;
