import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "../../examples/Tables/DataTable";
import {
  Card,
  CardHeader,
  Button,
  CardContent,
  MenuItem,
  Select,
  Modal,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MDButton from "../../components/MDButton";
import EditIcon from "../../assets/images/edit-icon.png";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import API_ROUTES from "../../config/apiConfig";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { toast } from "react-toastify";
import MDTypography from "../../components/MDTypography";
import Tooltip from "@mui/material/Tooltip";

const Tags = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // Dialog state
  const [deleteDialogConfirmOpen, setDeleteDialogConfirmOpen] = useState(false); // Dialog state
  const [tags, setTags] = useState([]);
  const [previousTagId, setPreviousTagId] = useState(null);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [tagToDelete, setTagToDelete] = useState(null); // Tag to delete

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.get(
        `${API_ROUTES.TAGS_FETCH_DATA}?group_id=${group_id}` 
      ); 
      // setTags(response.data.tags);
      const tagsData = response.data.tags.map((tag) => ({
        id: tag.id,
        tag: tag.tag,
        total_users: tag.total_users,
        created_at: formatDate(tag.created_at),
        updated_at: formatDate(tag.updated_at),
        actions: (
          <div style={{ display: "flex", gap: "5px" }}>
            <Tooltip title={translations.tags11?.migrate || "Migrar"}>
              <IconButton
                aria-label="move"
                style={{ color: "#f72585" }}
                onClick={() => handleOpenModal(tag.id)}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={translations.tags11?.edit || "Editar"}>
              <NavLink
                to={`/edit_tag/${tag.id}`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  color="primary"
                  aria-label="edit"
                  style={{ color: "#f72585" }}
                >
                  <img src={EditIcon} alt="" style={{ width: "20px" }} />
                </IconButton>
              </NavLink>
            </Tooltip>
            <Tooltip title={translations.tags11?.delete || "Apagar"}>
              <IconButton
                aria-label="delete"
                style={{ color: "#f72585" }}
                onClick={() => handleDeleteClick(tag.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      }));

      setTags(response.data.tags);
      console.log(response.data.tags);
      console.log("these are tags", tags);
      
      setTableData({
        columns: [
          { Header: "ID", accessor: "id", width: "20%" },
          { Header: "Tag", accessor: "tag", width: "30%" },
          {
            Header: translations.tag?.total_subs || "Total de Assinantes",
            accessor: "total_users",
            width: "30%",
          },
          {
            Header: translations.page_subscribers?.created_at || "Criado",
            accessor: "created_at",
            width: "25%",
          },
          {
            Header: translations.page_subscribers?.updated_at || "Atualizado",
            accessor: "updated_at",
            width: "25%",
          },
          {
            Header: translations.buttons_and_common_words?.actions || "Ações",
            accessor: "actions",
            width: "15%",
          },
        ],
        rows: tagsData,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = (tagId) => {
    setPreviousTagId(tagId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTagId(null);
  };

  const handleMoveUsers = async () => {
    try {
      const group_id = localStorage.getItem("group_id");

      const response = await axios.post(API_ROUTES.API_MOVE_USER_TAG, {
        group_id,
        previous_tag_id: previousTagId,
        selected_tag_id: selectedTagId,
      });

      fetchData(); // Refresh the data
      handleCloseModal();

      toast.success(response.data.message || "Users moved successfully!");
    } catch (error) {
      console.error("Error moving users:", error);
      toast.error("Failed to move users. Please try again.");
    }
  };

  const handleDeleteClick = async (tagId) => {
    const group_id = localStorage.getItem("group_id")
    const response = await axios.get(
      `${API_ROUTES.TAGS_FETCH_DATA}?group_id=${group_id}` 
    );
    const tag = response.data.tags.find((t) => t.id === tagId);

    if (!tag) {
      console.error(`Tag with ID ${tagId} not found.`);
      return;
    }

    if (tag.total_users === 0) {
      setTagToDelete(tagId);
      setDeleteDialogConfirmOpen(true);
    } else {
      setTagToDelete(tagId);
      setDeleteDialogOpen(true);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      console.log(tagToDelete);
      const group_id = localStorage.getItem("group_id");
      await axios.post(`${API_ROUTES.TAGS_DELETE}`, {
        id: tagToDelete,
      });
      fetchData(); // Refresh the data
      setDeleteDialogConfirmOpen(false);
      toast.success(
        translations.tags11?.delete_success || "Tag deleted successfully!"
      );
    } catch (error) {
      console.error("Error deleting tag:", error);
      toast.error(
        translations.tags11?.delete_failure ||
          "Failed to delete tag. Please try again."
      );
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteDialogConfirmOpen(false);
    setTagToDelete(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <Card>
                <CardHeader
                  title={translations.buttons_and_common_words?.tags}
                  action={
                    <NavLink
                      to={`/create-tags`}
                      style={{ textDecoration: "none" }}
                    >
                      <MDButton
                        variant="gradient"
                        style={{
                          backgroundColor: "#480ca8",
                          color: "white",
                          marginLeft: "8px",
                        }}
                        startIcon={<AddIcon />}
                      >
                        {translations.create_tag?.create_tag}
                      </MDButton>
                    </NavLink>
                  }
                />
                <DataTable table={tableData} canSearch />
              </Card>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <MDTypography variant="h6" id="message_container1">
            {translations.tags11?.destination_tag}
          </MDTypography>
          <Select
            fullWidth
            value={selectedTagId}
            onChange={(e) => setSelectedTagId(e.target.value)}
            displayEmpty
            sx={{ mt: 2, p: 1 }}
          >
            <MenuItem value="" disabled>
              {translations.column11?.destination_tag}
            </MenuItem>
            {tags
              .filter((tag) => tag.id !== previousTagId) // Exclude the previous tag
              .map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.tag}
                </MenuItem>
              ))}
          </Select>
          <Box
            sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <MDButton
              onClick={handleMoveUsers}
              variant="gradient"
              color="dark"
              startIcon={<ArrowRightAltIcon />}
              disabled={!selectedTagId} // Disable MDbutton if no tag selected
            >
              {translations.tags11?.migrate}
            </MDButton>
            <MDButton
              onClick={handleCloseModal}
              variant="gradient"
              color="black1"
              style={{ color: "black" }}
            >
              {translations.buttons_and_common_words?.cancel}
            </MDButton>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={deleteDialogConfirmOpen} // Should be connected to the correct state
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {translations.buttons_and_common_words?.are_you_sure}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={handleDeleteCancel}
            color="black1"
            variant="gradient"
            style={{ color: "black" }}
          >
            {translations.buttons_and_common_words?.cancel}
          </MDButton>
          <MDButton
            onClick={handleDeleteConfirm}
            color="info"
            autoFocus
            variant="gradient"
          >
            {translations.buttons_and_common_words?.ok}
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {translations.tags11?.delete_text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={handleDeleteCancel}
            color="black1"
            variant="gradient"
            style={{ color: "black" }}
          >
            {translations.buttons_and_common_words?.cancel}
          </MDButton>
          <MDButton
            onClick={handleDeleteCancel}
            color="info"
            autoFocus
            variant="gradient"
          >
            {translations.buttons_and_common_words?.ok}
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default Tags;
