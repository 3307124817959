import english from '../config/language'; // Updated path to JS file
import portuguese from '../config/languagePR'; // Updated path to JS file

const loadTranslations = async (language) => {
  switch (language) {
    case 'portuguese':
      return portuguese;
    case 'english':
    default:
      return english;
  }
};

export default loadTranslations;
