import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import axios from "axios";
import API_ROUTES from "../../../config/apiConfig";
import { toast } from "react-toastify";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";

const ExpiryDate = () => {
  
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  
  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);


  const [followsMe1, setFollowsMe1] = useState(false);
  const [anticipate, setAnticipate] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingMessage, setIsSavingMessage] = useState(false);
  const [noRecords, setNoRecords] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const group_id = parseInt(localStorage.getItem("group_id"), 10);
      try {
        const response = await axios.post(API_ROUTES.APPOINTMENT_API_GET_DATA, {
          group_id,
          table: "bots_expiring",
        });
        const data = response.data;
        setIsActive(data.is_active);
        setFollowsMe1(data.is_active === 1);
        setAnticipate(data.anticipate);
        setBody(data.body);
        setImageUrl(data.image);
        setImage(data.image); // Set initial image
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setNoRecords(true);
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file); // Store the file object
      setImageUrl(URL.createObjectURL(file)); // Optionally, still display the image preview
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    const group_id = parseInt(localStorage.getItem("group_id"), 10);
    const anticipateInt = parseInt(anticipate, 10);

    const postData = {
      table: "bots_expiring",
      group_id,
      // is_active: followsMe1 ? 1 : 0,
      anticipate:anticipateInt
    };

    try {
      await axios.post(API_ROUTES.DATA_SEND_BIRTHDAY, postData);
      toast.success("Data saved successfully");
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Failed to save data");
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveMessage = async () => {
    setIsSavingMessage(true);
    const group_id = parseInt(localStorage.getItem("group_id"), 10);
    const formData = new FormData();

    formData.append("table", "bots_expiring");
    formData.append("group_id", group_id);
    formData.append("body", body);

    if (image) {
      formData.append("image", image); // Append the actual file
    }

    try {
      await axios.post(API_ROUTES.DATA_SEND_BIRTHDAY_IMG_BODY, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Message saved successfully");
    } catch (error) {
      console.error("Error saving message:", error);
      toast.error("Failed to save message");
    } finally {
      setIsSavingMessage(false);
    }
  };

  const handleSwitchChange = async (event) => {
    const newIsActive = event.target.checked;
    setFollowsMe1(newIsActive);


    const group_id = parseInt(localStorage.getItem("group_id"), 10);
    const postData = {
      is_active: newIsActive ? 1 : 0,
      group_id,
      table: "bots_expiring",
    };

    try {
      await axios.post(API_ROUTES.API_BOT_UPDATE_ACTIVE, postData);
      if (newIsActive) {
        toast.success("Activated successfully");
      } else {
        toast.success("Deactivated successfully");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status");
    }
  };


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
      {noRecords && (
            <Grid item xs={12} sx={{marginBottom:"1.5rem"}}>
              <Card>
                <MDBox p={2} display="flex" justifyContent="center">
                  <MDTypography variant="body1" color="textSecondary">
                    No records found.
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
          )}
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
          <Card
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDBox
                p={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <MDTypography variant="h6">
                  {translations.expiring_bot?.expiring_bot}{" "}
                  {followsMe1 ? "ON" : "OFF"}
                </MDTypography>
                <Switch
                  checked={followsMe1}
                  onChange={handleSwitchChange} // Call the API on switch change
                />
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card style={{ height: "100%" }}>
              <MDBox
                p={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <MDTypography variant="h6">
                {translations.expiring_bot?.antecipation_in_days}
                </MDTypography>
                <TextField
                  type="number"
                  variant="outlined"
                  value={anticipate}
                  onChange={(e) => setAnticipate(e.target.value)}
                  sx={{ width: "5rem", marginLeft: "1rem" }}
                />
                {!noRecords && (
                  <MDBox display="flex" justifyContent="flex-end" p={2}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={handleSave}
                      disabled={isSaving}
                    >
                      {isSaving ? "Saving..." : translations.buttons_and_common_words?.save}
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        
          <Grid item xs={12}>
            <Card>
              <MDBox p={2}>
                <MDTypography
                  variant="h6"
                  sx={{
                    mt: 2,
                    mb: 1,
                    fontSize: isSmallScreen ? "1rem" : "1.25rem",
                  }}
                >
                  {translations.birthday_bot?.type_message_choose_image}
                </MDTypography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      multiline
                      rows={15}
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                      sx={{ width: "100%", overflow: "auto" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <MDTypography variant="body1" mb={1}>
                      Upload Image
                    </MDTypography>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="image-upload"
                      type="file"
                      onChange={handleImageUpload}
                    />
                    <label
                      htmlFor="image-upload"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#f0f0f0",
                        cursor: "pointer",
                        height: image || imageUrl ? "auto" : "10rem",
                        border: "1px dashed gray",
                      }}
                    >
                      {image || imageUrl ? (
                        <img
                          src={imageUrl || URL.createObjectURL(image)}
                          alt="Uploaded"
                          style={{ maxHeight: "17rem", maxWidth: "100%" }}
                        />
                      ) : (
                        <MDTypography variant="body1" color="textSecondary">
                          Click to upload image
                        </MDTypography>
                      )}
                    </label>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <MDTypography variant="body2">
                {translations.expiring_bot?.placeholders}
                </MDTypography>
              </MDBox>
              {!noRecords && (
                <MDBox display="flex" justifyContent="flex-end" p={2}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={handleSaveMessage}
                    disabled={isSavingMessage}
                  >
                    {isSavingMessage ? "Saving..." : translations.buttons_and_common_words?.save}
                  </MDButton>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ExpiryDate;
