import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "./context";
import { AuthProvider } from "./context/authContext";
import { LogoProvider } from "./context/LogoContext";
import { LanguageProvider } from "./context/LanguageContext";
import { TagsProvider } from "./context/TagsContext";
import { FormDataProvider } from "./context/FormDataContext";
import { AreaCodeProvider } from "./context/AreaCodeContext";
import { GlobalDataProvider } from "./context/GlobalDataContext";
import { CampaignProvider } from "./layouts/campaigns/GenerateCampaign/CampaignContext";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <AuthProvider>
        <LogoProvider>
          <LanguageProvider>
            <GlobalDataProvider>
              <TagsProvider>
                <FormDataProvider>
                  <AreaCodeProvider>
                    <CampaignProvider>
                      <App />
                    </CampaignProvider>
                  </AreaCodeProvider>
                </FormDataProvider>
              </TagsProvider>
            </GlobalDataProvider>
          </LanguageProvider>
        </LogoProvider>
      </AuthProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
