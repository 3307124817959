import React, { useEffect, useState } from "react";
import {
  Modal,
  Fade,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  IconButton,
} from "@mui/material";
import axios from "axios";
import MDButton from "../../components/MDButton";
import DataTable from "../../examples/Tables/DataTable";
import CloseIcon from "@mui/icons-material/Close";
import API_ROUTES from "../../config/apiConfig";
import { toast } from "react-toastify";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";

const ImportModal = ({
  open,
  onClose,
  group_id,
  runSpecificCode,
  hideParentComponent,
  refreshTags,
  makeTrue,
  reloadData,
}) => {
  const fileTypes = ["File Type 1", "File Type 2", "File Type 3"];
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [importing, setImporting] = useState(false);
  const [importedData, setImportedData] = useState(null);
  const [error, setError] = useState(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const navigate = useNavigate();
  const handleFileTypeChange = (type) => {
    setSelectedFileType(type);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Helper function to update a specific cell in the array
  const handleInputChange = (index, key, value) => {
    const updatedData = [...importedData];
    updatedData[index][key] = value;
    setImportedData(updatedData);
  };

  // Helper function to delete a row from the array
  const handleDeleteRow = (index) => {
    const updatedData = importedData.filter((_, i) => i !== index);
    setImportedData(updatedData);
  };

  const handleImport = async () => {
    handleButtonClick1();
    if (!selectedFile) {
      toast.error("Please select a file.");
      return;
    }

    setImporting(true);
    setError(null);

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("group_id", localStorage.getItem("group_id"));
    try {
      const response = await axios.post(API_ROUTES.XLSX_FILE_IMPORT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.success) {
        setImportedData(response.data.data);
        onClose(); // Close the original modal
        navigate(`/imported_data/${response.data.file_id}`);
        // setShowNewModal(true); // Show the new modal
      } else {
        toast.error("Failed to import data.");
      }
    } catch (error) {
      toast.error("An error occurred while importing the file.");
    } finally {
      setImporting(false);
    }
  };

  const handleFinalImport = async () => {
    if (!importedData) {
      toast.error("No data available to import.");
      return;
    }
    try {
      const group_id = localStorage.getItem("group_id");
      const response = await axios.post(API_ROUTES.API_STORE_IMPORT, {
        group_id,
        data: importedData,
      });

      if (response.data.success) {
        toast.success("Data imported successfully!");
        setShowNewModal(false); // Close the modal after successful import
        refreshTags(); // Call the refreshTags function to update the tags in UsersTable
      } else {
        toast.error("Failed to store imported data.");
      }
    } catch (error) {
      toast.error("An error occurred while storing the imported data.");
    }
  };

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const getTableData = () => {
    const columnsMapping = {
      name: translations.your_public_import?.name,
      mobile: translations.your_public_import?.mobile,
      email: translations.your_public_import?.email || "Email",
      dob: translations.your_public_import?.dob || "Aniversário",
      tagid: translations.your_public_import?.tagid || "Tag ID",
      company: translations.your_public_import?.company || "Empresa",
      address: translations.your_public_import?.address || "Endereço",
      number: translations.your_public_import?.number || "Número",
      complement: translations.your_public_import?.complement || "Complemento",
      district: translations.your_public_import?.district || "Bairro",
      city: translations.your_public_import?.city || "Cidade",
      state: translations.your_public_import?.state || "Estado",
      zipcode: translations.your_public_import?.zipcode || "CEP",
      cpf: translations.your_public_import?.cpf,
      firstorder_date:
        translations.your_public_import?.datepurchase || "Data da Compra",
      expiring_date:
        translations.your_public_import?.dateexpiring || "Data de Vencimento",
      operator: translations.your_public_import?.responsable || "Responsável",
      product: translations.your_public_import?.product || "Produto",
      value: translations.your_public_import?.value || "Valor",
      extra1: translations.your_public_import?.extra1 || "Extra 1",
      extra2: translations.your_public_import?.extra2 || "Extra 2",
      delete: "Delete",
    };

    const columns = Object.keys(columnsMapping).map((key) => ({
      Header: columnsMapping[key],
      accessor: key,
    }));

    const rows = importedData.map((row, index) =>
      Object.keys(columnsMapping).reduce((acc, key) => {
        // If the key is 'delete', we will render the delete button
        if (key === "delete") {
          acc[key] = (
            <IconButton color="error" onClick={() => handleDeleteRow(index)}>
              <ClearIcon />
            </IconButton>
          );
        } else {
          // Render input field for each key, allowing updates
          acc[key] = (
            <input
              type="text"
              value={row[key] || ""}
              placeholder={row[key] || ""}
              onChange={(e) => handleInputChange(index, key, e.target.value)}
              style={{
                padding: "5px",
                border: "1.5px solid #0000004a",
                borderRadius: "5px",
              }}
            />
          );
        }
        return acc;
      }, {})
    );

    return { columns, rows };
  };

  const handleButtonClick1 = () => {
    makeTrue();
  };

  const handleButtonClick = () => {
    console.log(hideParentComponent);

    hideParentComponent();
  };

  return (
    <>
      <Modal open={open} onClose={onClose} closeAfterTransition>
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: 600,
              maxHeight: "80vh", // Set a maximum height for the modal
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
              overflowY: "auto", // Allow vertical scrolling when content exceeds max height
            }}
          >
            <Typography variant="h6" mb={2}>
              <span style={{ color: "green", cursor: "pointer" }}>
                Click here
              </span>{" "}
              to download a basic import file template
            </Typography>
            <Typography variant="h6" mb={2}>
              <span style={{ color: "green", cursor: "pointer" }}>
                Click here
              </span>{" "}
              to download zig import file template
            </Typography>
            <Typography variant="h6" mb={2}>
              Choose file type
            </Typography>
            <input
  type="file"
  accept=".xlsx, .xlt"
  onChange={handleFileChange}
  style={{
    display: "block",
    width: "100%",
    padding: "12px 20px",
    margin: "16px 0",
    fontSize: "16px",
    fontFamily: "Arial, sans-serif",
    border: "2px solid #3f37c9",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    cursor: "pointer",
    transition: "background-color 0.3s ease, border-color 0.3s ease",
  }}
  onMouseEnter={(e) => {
    e.target.style.backgroundColor = "#e6e6e6";
    e.target.style.borderColor = "#3f37c9";
  }}
  onMouseLeave={(e) => {
    e.target.style.backgroundColor = "#f9f9f9";
    e.target.style.borderColor = "#3f37c9";
  }}
/>


            <Typography variant="h6" mt={2}>
              Column names that will be imported:
            </Typography>
            <Typography variant="body2">
              name, mobile, email, birthday, tagid, company, address, number,
              complement, district, city, state, zip code, purchasedate,
              duedate, operator, product, value, extra1, extra2, extra3.
            </Typography>
            <FormControlLabel
              control={<Checkbox />}
              label="Do not generate welcome message"
              sx={{ mt: 2 }}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <MDButton
                onClick={onClose}
                variant="outlined"
                color="secondary"
                sx={{ mr: 2 }}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="contained"
                style={{ backgroundColor: "#3f37c9", color: "white" }}
                onClick={handleImport}
                disabled={importing}
              >
                {importing ? <CircularProgress size={24} /> : "Import"}
              </MDButton>
            </Box>
            <IconButton
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "#3f37c9",
                m: 1,
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Fade>
      </Modal>
      {runSpecificCode &&
        (importedData ? (
          <DataTable table={getTableData()} canSearch entriesPerPage={false} />
        ) : null)}
      {runSpecificCode && (
        <MDButton onClick={handleButtonClick}>
          Click me to hide the parent component logic!
        </MDButton>
      )}
    </>
  );
};

export default ImportModal;
