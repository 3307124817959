import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import axios from "axios";
import API_ROUTES from "../../../../config/apiConfig";
import { useLanguageState } from "../../../../context/LanguageContext";
import loadTranslations from "../../../../utils/loadTranslations";
import { toast } from "react-toastify";

const ThirdCard = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [limit, setLimit] = useState("");
  const [open, setOpen] = useState(false);
  const [dailyLimit, setDailyLimit] = useState(""); // State to manage input
  const [loading, setLoading] = useState(false); // State to manage loading state

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const fetchData = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.post(API_ROUTES.CAMPAING_REPORT_GET_DATA, {
        group_id,
      });
      setLimit(response.data.sent_count.daily_limit || "0");
    } catch (error) {
      console.error("Error fetching data:", error);
      setLimit("Error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle input change
  const handleInputChange = (e) => {
    setDailyLimit(e.target.value);
  };

  // Handle form submission
  const handleSaveLimit = async () => {
    setLoading(true); // Set loading to true when the request starts
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.post(API_ROUTES.API_BOT_UPDATE_DAILY_LIMIT, {
        group_id,
        daily_limit: dailyLimit,
      });

      toast.success(translations.generate_message?.limit_message);
      await fetchData(); // Refresh the limit after saving
      handleClose(); // Close the modal after successful submission
    } catch (error) {
      console.error("Error updating daily limit:", error);
      toast.error("Failed to update daily limit");
    } finally {
      setLoading(false); // Set loading to false when the request ends
    }
  };

  return (
    <>
      <Card sx={{ width: "100%", cursor: "pointer" }} onClick={handleOpen}>
        <MDBox p={2}>
          <Grid container>
            <Grid item xs={12} md={7} lg={12}>
              <MDTypography variant="h2" sx={{ mt: 2, mb: 1, ml: 2 }}>
                {limit}
              </MDTypography>
            </Grid>
            <MDTypography variant="h6" sx={{ mb: 1, ml: 2 }}>
              {translations.campaign_reports?.daily_limit}
            </MDTypography>
          </Grid>
        </MDBox>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card
            sx={{ width: 400, margin: "auto", mt: 5, p: 2, outline: "none" }}
          >
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5">
                {translations.campaign_reports?.daily_limit}
              </MDTypography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </MDBox>
            <MDBox mt={3}>
              <TextField
                fullWidth
                type="number"
                name="daily_limit"
                value={dailyLimit}
                onChange={handleInputChange}
                label={translations.campaign_reports?.enter_limit}
                variant="outlined"
                sx={{ mb: 3 }}
              />
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton
                  variant="gradient"
                  color="dark"
                  sx={{ mb: 2 }}
                  onClick={handleSaveLimit}
                  disabled={loading} // Disable the button while loading
                >
                  {loading
                    ? translations.buttons_and_common_words?.saving // Show "Saving..." when loading
                    : translations.buttons_and_common_words?.save}
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default ThirdCard;
