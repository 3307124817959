import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import Switch from "@mui/material/Switch";
import { useLanguageState } from "../../../../context/LanguageContext";
import loadTranslations from "../../../../utils/loadTranslations";
import API_ROUTES from "../../../../config/apiConfig";


const FourthCard = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [followsMe, setFollowsMe] = useState(true);
  const [ship, setShip] = useState("0"); // Assuming `ship` is required for displaying sent_count.ready

  // Fetch translations based on selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  // Fetch campaign data
  const fetchData = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.post(API_ROUTES.CAMPAING_REPORT_GET_DATA, {
        group_id,
      });

      const { sent_count, is_webcampaigns } = response.data;

      setShip(sent_count.ready || "0"); // Update ship state with sent_count.ready
      setFollowsMe(is_webcampaigns === 1); // Set followsMe state based on is_webcampaigns value
    } catch (error) {
      console.error("Error fetching data:", error);
      setShip("Error");
    }
  };

  // Update campaign status when switch is toggled
  const handleSwitchChange = async () => {
    const group_id = localStorage.getItem("group_id");
    const newStatus = followsMe ? 0 : 1; // Toggle the status

    try {
      await axios.post(API_ROUTES.API_STATUS_WEB_CAMPAIGN, {
        is_webcampaigns: newStatus,
        group_id,
      });

      setFollowsMe(!followsMe); // Update switch state
    } catch (error) {
      console.error("Error updating campaign status:", error);
    }
  };

  // Fetch campaign data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={12} display="flex" alignItems="center">
          <Switch checked={followsMe} onChange={handleSwitchChange} />
          <MDTypography variant="h5">
            {translations.campaign_reports?.web_delivery}{" "}
            {followsMe ? "On" : "Off"}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <MDTypography variant="body1" style={{ fontSize: "11px" }}>
            {translations.campaign_reports?.web_integrator_message}
          </MDTypography>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default FourthCard;
