import React from "react";
import MDBox from "../../components/MDBox";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import { Box, Card, Grid, Typography } from "@mui/material";
import MDButton from "../../components/MDButton";

const RedirectionE = () => {
  const handleOpenFlowBuilder = () => {
    window.open("http://ecommerce.crmstation.net", "_blank");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={2}>
          <Grid container >
            <Card>
              <Box p={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" color="textSecondary">
                    Ecommerce
                  </Typography>
                </Box>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleOpenFlowBuilder}
                  style={{ marginTop: "1.5rem" }}
                >
                  Open Ecommerce
                </MDButton>
              </Box>
            </Card>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default RedirectionE;
