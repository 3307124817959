import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "../../../../components/MDButton";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import API_ROUTES from "../../../../config/apiConfig";
import { useLanguageState } from "../../../../context/LanguageContext";
import loadTranslations from "../../../../utils/loadTranslations";
import { toast } from "react-toastify";

const FirstCard = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [ship, setShip] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const fetchData = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.post(API_ROUTES.CAMPAING_REPORT_GET_DATA, {
        group_id,
      });

      setShip(response.data.sent_count.ready || "0");
    } catch (error) {
      console.error("Error fetching data:", error);
      setShip("Error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleGenerateMessages = async () => {
    setLoading(true);
    const group_id = localStorage.getItem("group_id");
    try {
      const response = await axios.post(
        API_ROUTES.API_BOT_UPDATE_READY_SENT_50,
        {
          group_id,
        }
      );

      const message = `Updated Records: ${response.data.updated_records}`;
      toast.success(message);

      // Fetch updated data and close the modal
      await fetchData();
      handleClose();
    } catch (error) {
      toast.error("Error generating messages");
      console.error("Error generating messages:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <Card sx={{ width: "100%", cursor: "pointer" }} onClick={handleOpen}>
        <MDBox p={2}>
          <Grid container>
            <Grid item xs={12} md={7} lg={12}>
              <MDTypography variant="h2" sx={{ mt: 2, mb: 1, ml: 2 }}>
                {ship}
              </MDTypography>
            </Grid>
            <MDTypography variant="h6" sx={{ mb: 1, ml: 2 }}>
              {translations.campaign_reports?.ready_tosend}
            </MDTypography>
          </Grid>
        </MDBox>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: 0,
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <MDTypography variant="h5">
                {translations.campaign_reports?.ready_tosend}
              </MDTypography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <MDBox
              mt={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <MDButton
                variant="gradient"
                color="black1"
                sx={{ mb: 2 }}
                style={{ color: "black" }}
                onClick={handleGenerateMessages}
                disabled={loading}
              >
                {loading
                  ? "Generating..."
                  : `Generate ${translations.campaign_reports?.mesages30} to send now`}
              </MDButton>
            </MDBox>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default FirstCard;
