import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

// @mui/material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import DataTable from "../../../examples/Tables/DataTable";
import API_ROUTES from "../../../config/apiConfig";
import MDButton from "../../../components/MDButton";
import { useLanguageState } from "../../../context/LanguageContext";
import { useNavigate } from "react-router-dom";
import loadTranslations from "../../../utils/loadTranslations";

const AppointmentImportedEvents = () => {
  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
  });
  const group_id = localStorage.getItem("group_id"); // Retrieve group_id from localStorage
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      //console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);
  // Function to fetch appointment events
  // Function to fetch appointment events
  const fetchAppointmentEvents = async () => {
    try {
      const response = await axios.get(API_ROUTES.APPIONTMENT_EVENTS, {
        params: { group_id },
      });
      const fetchedData = response.data.map((item) => {
        const formatDate = (dateStr) => {
          if (!dateStr) return "";
          const [year, month, day] = dateStr.split('-');
          return `${day}-${month}-${year}`;
        };
  
        const formattedDate = formatDate(item.imported_date);
        const formattedEventDate = formatDate(item.event_date);
  
        return {
          date: formattedDate,
          name: item.name,
          mobile: item.mobile,
          event_date: formattedEventDate,
          event_hour: item.event_hour,
        };
      });

      setTableData((prevState) => ({ ...prevState, rows: fetchedData }));
    } catch (error) {
      toast.error("Error fetching imported events!");
    }
  };

  // Function to delete all imported events
  const deleteImportedEvents = async () => {
    try {
      const response = await axios.post(API_ROUTES.DELETE_APPOINTMENT_EVENTS, {
        group_id,
      });
      if (response.status === 200) {
        setTableData((prevState) => ({ ...prevState, rows: [] })); // Clear the events from state
        toast.success("All imported events deleted successfully!");
      }
    } catch (error) {
      toast.error("Error deleting imported events!");
    }
  };

  useEffect(() => {
    fetchAppointmentEvents(); // Fetch events on component mount

    // Set table columns
    setTableData((prevState) => ({
      ...prevState,
      columns: [
        {
          Header: translations.bots_reports?.table_date || "Data da Importação",
          accessor: "date",
          width: "15%",
        },
        {
          Header: translations.bots_reports?.table_name || "Nome",
          accessor: "name",
          width: "20%",
        },
        {
          Header: translations.bots_reports?.table_mobile || "Celular",
          accessor: "mobile",
          width: "20%",
        },
        {
          Header: translations.bots_reports?.table_event || "Data Evento",
          accessor: "event_date",
          width: "15%",
        },
        {
          Header: translations.bots_reports?.event_hour || "Hora Evento",
          accessor: "event_hour",
          width: "15%",
        },
      ],
    }));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {/* Delete Button */}
        <MDBox display="flex" justifyContent="flex-end" mt={3}>
          <MDButton
            variant="gradient"
            color="black1"
            onClick={deleteImportedEvents}
            style={{ color: "black" }}
          >
            {translations.bots_reports?.delete_imported_events}
          </MDButton>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {" "}
            {/* Make this column span full width */}
            <MDBox mt={6}>
              <DataTable table={tableData} canSearch />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AppointmentImportedEvents;
