import React from "react";
import { Card, CardContent, Typography, Avatar, Grid, Chip } from "@mui/material";

const SimpleCard = ({ title, email, createdAt, avatarUrl, operatorName }) => {
  const operatorDisplayName = operatorName === "Unknown Operator" ? "Not Found" : operatorName;

  return (
    <Card variant="outlined">
      <CardContent
        style={{
          padding: "12px",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar
              alt={title}
              src={avatarUrl}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                border: "2px solid #4cc9f0",
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" component="div" style={{ fontSize: "12px" }}>
              {title}
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ fontSize: "12px" }}
            >
              {email}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          color="textSecondary"
          style={{
            fontSize: "11px",
            color: "#8080809e",
            position: "absolute",
            top: "8px",
            right: "12px",
          }}
        >
          {createdAt}
        </Typography>
        {operatorDisplayName !== "Not Found" && operatorDisplayName !== "" && (
          <Chip
            label={operatorDisplayName}
            style={{
              fontSize: "9px",
              backgroundColor: "#4caf50", // Change color based on requirement
              color: "#fff",
              position: "absolute",
              bottom: "8px",
              right: "12px",
              height: "15px",
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default SimpleCard;
